import { listToDict } from '@og-pro/shared-config/helpers';
import { requisitionFiltersNames } from '@og-pro/shared-config/requisitions';

export const qaTagPageName = 'requisitionsDashboard';

const { ON, AFTER, BEFORE, END, START, LAST_ACTION, CREATORS, SOURCING_STATUS } =
    requisitionFiltersNames;

const fields = [
    'sorting',
    'oneDateType',
    ON,
    BEFORE,
    AFTER,
    END,
    START,
    'search',
    LAST_ACTION,
    CREATORS,
    'quickFilter',
    'dateType',
    SOURCING_STATUS,
];

export const fieldNames = listToDict(fields);

export const dateFormat = 'MM/DD/YYYY';
