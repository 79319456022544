import {
    requisitionQuickFiltersNames,
    REQUISITIONS_LIMIT,
    requisitionSortingOptionsNames,
    requisitionTabsNames,
} from '@og-pro/shared-config/requisitions';

import { chartInteractionParamsDict } from './Visualizations/helpers/chartInteractions';
import { qaTagPageName } from './constants';

const { OLDER_THAN, STATUS, TYPE } = chartInteractionParamsDict;

const colors = {
    error: '#B23E24', // $color-error-700
    warning: '#C77110', // $color-fg-warning-large
    disabled: '#616365', // $color-fg-secondary
};
export const getRequestsColorByThresholds = (key, value) => {
    if (key === 'rejected' || key === 'overBudget') {
        if (value > 0) {
            return colors.error;
        }
    }

    if (key === 'onHold') {
        if (value > 0) {
            return colors.warning;
        }
    }

    return colors.disabled;
};

export const getQuickFilters = (currentTab) => {
    const initialFilters = [
        {
            label: 'In Process',
            value: requisitionQuickFiltersNames.IN_PROCESS,
            qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.IN_PROCESS}`,
        },
        {
            label: 'Recently Closed',
            value: requisitionQuickFiltersNames.RECENTLY_CLOSED,
            qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.RECENTLY_CLOSED}`,
        },
    ];

    switch (currentTab) {
        case requisitionTabsNames.MY_APPROVALS:
            initialFilters.unshift({
                label: 'Action Needed',
                value: requisitionQuickFiltersNames.ACTION_NEEDED,
                qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.ACTION_NEEDED}`,
            });
            break;
        case requisitionTabsNames.MY_REQUESTS:
            initialFilters.unshift({
                label: 'Drafts',
                value: requisitionQuickFiltersNames.DRAFTS,
                qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.DRAFTS}`,
            });
            break;
        case requisitionTabsNames.ALL_REQUESTS:
            // REQ TODO: Implement 'This Fiscal Year' filter
            // initialFilters.push({
            //     label: 'This Fiscal Year',
            //     value: requisitionQuickFiltersNames.THIS_FISCAL_YEAR,
            //     qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.THIS_FISCAL_YEAR}`,
            // });
            break;
        default:
            break;
    }

    initialFilters.push({
        label: 'All',
        value: requisitionQuickFiltersNames.ALL,
        qaTag: `${qaTagPageName}-${requisitionQuickFiltersNames.ALL}`,
    });

    return initialFilters;
};

function buildTabRoute(location, value) {
    const currentParams = new URLSearchParams(location.search);
    const defaultQuickFilter = getQuickFilters(value)[0].value;

    // set default radio filter in tabs url to prevent duplicated API calls
    currentParams.set('tab', value);
    currentParams.set('quickFilter', defaultQuickFilter);
    currentParams.set('page', 1);
    currentParams.set('limit', REQUISITIONS_LIMIT);

    const newSearch = currentParams.toString();

    const updatedRoute = `${location.pathname}?${newSearch}`;

    return updatedRoute;
}

export function getInitialTabs(isAdminOrLeader, location, requisitionTabsData = {}) {
    const initialTabs = [
        {
            label: 'My Approvals',
            qaTag: `${qaTagPageName}-${requisitionTabsNames.MY_APPROVALS}`,
            route: buildTabRoute(location, requisitionTabsNames.MY_APPROVALS),
            count: requisitionTabsData?.[requisitionTabsNames.MY_APPROVALS],
        },
        {
            label: 'My Requests',
            qaTag: `${qaTagPageName}-${requisitionTabsNames.MY_REQUESTS}`,
            route: buildTabRoute(location, requisitionTabsNames.MY_REQUESTS),
            count: requisitionTabsData?.[requisitionTabsNames.MY_REQUESTS],
        },
    ];

    if (isAdminOrLeader) {
        initialTabs.unshift({
            label: 'All Requests',
            qaTag: `${qaTagPageName}-${requisitionTabsNames.ALL_REQUESTS}`,
            route: buildTabRoute(location, requisitionTabsNames.ALL_REQUESTS),
            count: requisitionTabsData?.[requisitionTabsNames.ALL_REQUESTS],
        });
    }

    return initialTabs;
}

export function getInitialParams(isAdminOrLeader) {
    const defaultTab = isAdminOrLeader
        ? requisitionTabsNames.ALL_REQUESTS
        : requisitionTabsNames.MY_APPROVALS;

    const initialParams = {
        page: 1,
        quickFilter: requisitionQuickFiltersNames.ALL,
        tab: defaultTab,
        filters: null,
        search: null,
        sort: requisitionSortingOptionsNames.ACTIVE_DATE,
        sortDirection: 'DESC',
        [OLDER_THAN]: null,
        [STATUS]: null,
        [TYPE]: null,
    };

    return initialParams;
}

/**
 * Extracts unique creator options from an array of requisition groups.
 *
 * @param {Array} requisitionGroups - An array of requisition group objects.
 * @returns {Array} An array of unique creator options with labels and values.
 */
export const getUniqueCreatorsFromRequisitionGroups = (requisitionGroups) => {
    const uniqueIds = new Set(); // Set to track unique ids

    return requisitionGroups.flatMap((requisitionGroup) => {
        return requisitionGroup.creators.reduce((filteredOptions, creator) => {
            if (!uniqueIds.has(creator.id)) {
                uniqueIds.add(creator.id); // Add id to the set
                filteredOptions.push({
                    label: creator.displayName,
                    value: creator.id.toString(),
                });
            }
            return filteredOptions;
        }, []);
    });
};
