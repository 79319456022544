import { listToDict } from '@og-pro/shared-config/helpers';

export const fields = [
    'attachments',
    'background',
    'criteria',
    'evaluationPhases',
    'hideContact',
    'hideContactAddress',
    'hideProcurementContact',
    'hideTimeline',
    'priceTables',
    'questionnaires',
    'sectionDescriptions',
    'signatures',
    // virtual field that holds the array of signatures that don't belong to a project section
    'standaloneSignatures',
    'summary',
    'timelineConfig',
    'timelines',
];
export const fieldNames = listToDict(fields);

export const criteriaFields = [
    'description',
    'instructionData',
    'instructionType',
    'instructions',
    'manualNumber',
    'needsReview',
    'nestLevel',
    'orderById',
    'title',
];
export const criteriaFieldNames = listToDict(criteriaFields);

export const evaluationPhaseFields = ['orderById', 'scoringCriteria', 'title'];
export const evaluationPhaseFieldNames = listToDict(evaluationPhaseFields);

export const sectionDescriptionFields = ['description'];
export const sectionDescriptionFieldNames = listToDict(sectionDescriptionFields);
