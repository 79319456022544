import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { fieldTypes } from '@og-pro/shared-config/signatures';

import { ProjectDetailTitle } from '../GovApp';
import { SignatureDocx } from './SignatureDocx';
import { signaturesBlocksToColumns } from '../../helpers/signatures';

const FieldValue = ({ field }) => {
    if (!field.value) {
        return null;
    }

    switch (field.type) {
        case fieldTypes.DATE:
            return new Date(field.value).toLocaleDateString();
        case fieldTypes.STAMP:
            return <Box height={field.value}>&nbsp;</Box>;
        default:
            return field.value;
    }
};

FieldValue.propTypes = {
    field: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType(PropTypes.string, PropTypes.number, PropTypes.bool),
    }).isRequired,
};

const SignatureBlock = ({ block }) => {
    if (block.styling === 'header') {
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    return (
        <Box>
            {block.fields?.map((field) => {
                return (
                    <Box key={field.label} mt={2}>
                        <Box borderBottom="1px solid #333" flex={4} fontStyle="italic" pb={0.5}>
                            <FieldValue field={field} />
                        </Box>
                        <Box flex={1} fontSize="12px" mr={1}>
                            <b>{field.label?.toUpperCase() || ''}</b>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
};

export const Signature = (props) => {
    if (props.isDocx) {
        return <SignatureDocx {...props} />;
    }

    const {
        isDocx,
        projectSection,
        showComments,
        signatures,
        title,
        useManualNumbering,
        useSectionDividers,
    } = props;

    const sectionSignatures = signatures.filter(
        (signature) => signature.project_section_id === projectSection.id
    );

    return (
        <div className="col-xs-12 col-md-offset-1 col-md-10">
            <ProjectDetailTitle
                isDocx={isDocx}
                projectSection={projectSection}
                projectSubsectionId={null}
                showComments={showComments}
                title={title}
                useManualNumbering={useManualNumbering}
                useSectionDividers={useSectionDividers}
            />
            <Box px={2}>
                {sectionSignatures.map((rawSignature) => {
                    const signature = signaturesBlocksToColumns(rawSignature);

                    const left = signature.signaturesBlocks[0];
                    const right = signature.signaturesBlocks[1];

                    return (
                        <>
                            <Box dangerouslySetInnerHTML={{ __html: signature.introduction }} />
                            <Box>
                                <Box display="flex" pb={6}>
                                    <Box flex={1} pr={4}>
                                        {(left || []).map((block, i) => {
                                            return (
                                                <Box key={i} mb={4}>
                                                    <SignatureBlock block={block} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box flex={1} pl={4}>
                                        {(right || []).map((block, i) => {
                                            return (
                                                <Box key={i} mb={4}>
                                                    <SignatureBlock block={block} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            <Box dangerouslySetInnerHTML={{ __html: signature.footer }} />
                        </>
                    );
                })}
            </Box>
        </div>
    );
};

Signature.propTypes = {
    isDocx: PropTypes.bool,
    projectSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        manualNumber: PropTypes.string,
        sectionNumber: PropTypes.number,
        title: PropTypes.string.isRequired,
    }).isRequired,
    showComments: PropTypes.bool,
    signatures: PropTypes.array.isRequired,
    title: PropTypes.string,
    useManualNumbering: PropTypes.bool,
    useSectionDividers: PropTypes.bool,
};
