import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Container } from '@og-pro/ui';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { SummaryInfoHeader } from './SummaryInfoHeader';
import { getProposalJS } from '../selectors';
import connectData from '../../ConnectData';
import { getPublicProjectJS, getShowConnectionAlert } from '../../selectors';
import { loadVendorReverseAuctionPriceItems } from '../../../actions/reverseAuctions';
import { joinReverseAuction, leaveReverseAuction } from '../../../actions/reverseAuctionSocket';
import { loadProposal } from '../../../actions/vendProposals';
import {
    LoadingError,
    LoadingSpinner,
    PageTitle,
    ReverseAuctionNotStarted,
    UnderlineNav,
} from '../../../components';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../../actions/notification';
import { reverseAuctionSocket } from '../../../lib/sockets';
import { navItems } from './constants';
import { getSelectedRoute } from './selectors';

const { AUCTION_PENDING, REVERSE_AUCTION, PENDING, EVALUATION, AWARD_PENDING, CLOSED } =
    projectStatusesDict;

const auctionNotStartedOrComplete = (status) => {
    return ![REVERSE_AUCTION, PENDING, EVALUATION, AWARD_PENDING, CLOSED].includes(status);
};

function fetchData(getState, dispatch, location, params) {
    const proposalId = Number.parseInt(params.proposalId, 10);
    return Promise.all([
        dispatch(loadProposal(proposalId)),
        dispatch(loadVendorReverseAuctionPriceItems(proposalId)),
    ]);
}

const ConnectedReverseAuction = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const loading = useSelector((state) =>
        state.reverseAuctions.get('loadingReverseAuctionPriceItems')
    );
    const loadError = useSelector((state) =>
        state.reverseAuctions.get('loadReverseAuctionPriceItemsError')
    );
    const shouldShowConnectionAlert = useSelector((state) => getShowConnectionAlert(state));
    const proposal = useSelector((state) => getProposalJS(state));
    const project = useSelector((state) => getPublicProjectJS(state));
    const selectedRoute = useSelector((state) => getSelectedRoute(state, { location }));
    const { auctionEndDate, auctionStartDate, id: projectId, status } = project;

    const shouldShowConnectionAlertRef = useRef(shouldShowConnectionAlert);
    useEffect(() => {
        shouldShowConnectionAlertRef.current = shouldShowConnectionAlert;
    }, [shouldShowConnectionAlert]);

    useEffect(() => {
        reverseAuctionSocket.connect();
        dispatch(joinReverseAuction(projectId));

        const showDisconnectedFromAuction = () => {
            dispatch(
                showConnectionAlert({
                    text: 'You are disconnected from the Reverse Auction. Please check your internet connection and refresh the page.',
                    alertType: 'danger',
                })
            );
        };

        const connectHandler = () => {
            // `connect` is fired on connection and reconnection so this hides the alert in both instances
            if (shouldShowConnectionAlertRef.current) {
                dispatch(reconnectionAlert());
            }
        };

        const reconnectHandler = () => {
            dispatch(joinReverseAuction(projectId));
            dispatch(loadVendorReverseAuctionPriceItems(proposal.id));
        };

        reverseAuctionSocket.on('connect', connectHandler);
        reverseAuctionSocket.on('error', showDisconnectedFromAuction);
        reverseAuctionSocket.on('disconnect', showDisconnectedFromAuction);
        reverseAuctionSocket.io.on('reconnect', reconnectHandler);
        reverseAuctionSocket.io.on('reconnect_error', showDisconnectedFromAuction);
        reverseAuctionSocket.io.on('reconnect_failed', showDisconnectedFromAuction);
        return () => {
            if (shouldShowConnectionAlertRef.current) {
                dispatch(hideConnectionAlert());
            }

            // Listeners have to be removed intentionally to prevent unintended effects.
            // Using `.off()` to remove all also removes the `.on('action')` listener attached by the middleware.
            // This prevents subsequent connections by this client from updating the Redux store.
            reverseAuctionSocket.off('connect', connectHandler);
            reverseAuctionSocket.off('error', showDisconnectedFromAuction);
            reverseAuctionSocket.off('disconnect', showDisconnectedFromAuction);
            reverseAuctionSocket.io.off('reconnect', reconnectHandler);
            reverseAuctionSocket.io.off('reconnect_error', showDisconnectedFromAuction);
            reverseAuctionSocket.io.off('reconnect_failed', showDisconnectedFromAuction);

            dispatch(leaveReverseAuction(projectId));
            reverseAuctionSocket.disconnect();
            window?.embeddedservice_bootstrap?.utilAPI.showChatButton();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const prevProjectStatusRef = useRef(status);

    useEffect(() => {
        // Vendors have to use the Q&A feature during a live auction to get support from the agency
        if (status === REVERSE_AUCTION) {
            window?.embeddedservice_bootstrap?.utilAPI.hideChatButton();
        }

        // Make sure we get the latest data on auction start and end
        const prevStatus = prevProjectStatusRef.current;
        if (
            (prevStatus !== REVERSE_AUCTION && status === REVERSE_AUCTION) ||
            (prevStatus === REVERSE_AUCTION && status !== REVERSE_AUCTION)
        ) {
            dispatch(loadVendorReverseAuctionPriceItems(proposal.id));
            dispatch(loadProposal(proposal.id)); // Updates the project as well
        }

        prevProjectStatusRef.current = status;
    }, [dispatch, proposal, status]);

    // Backup timer in case the something goes wrong with the sync broadcast to start the auction
    useEffect(() => {
        const now = new Date();
        const startDate = new Date(auctionStartDate);
        const timeoutMilliseconds = startDate.getTime() - now.getTime();
        let timeoutId;

        if (timeoutMilliseconds > 0) {
            timeoutId = setTimeout(() => {
                if (prevProjectStatusRef.current === AUCTION_PENDING) {
                    dispatch(loadProposal(proposal.id)); // Updates the project as well
                    dispatch(loadVendorReverseAuctionPriceItems(proposal.id));
                }
            }, timeoutMilliseconds + 750); // Add a little time so if it auto-started we don't fetch again

            return () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        }
    }, [auctionStartDate, dispatch, prevProjectStatusRef, proposal.id]);

    if (loading) return <LoadingSpinner />;
    if (loadError) return <LoadingError error={loadError} />;
    if (auctionNotStartedOrComplete(status)) {
        return (
            <div className="row">
                <PageTitle title="Reverse Auction" />
                <div className="col-xs-offset-1 col-xs-10">
                    <ReverseAuctionNotStarted
                        auctionEndDate={auctionEndDate}
                        auctionStartDate={auctionStartDate}
                        timezone={project.government.organization.timezone}
                    />
                </div>
            </div>
        );
    }

    const urlPathname = `/vendors/${proposal.vendor_id}/proposals/${proposal.id}/projects/${projectId}`;

    return (
        <Container maxWidth="md">
            <PageTitle title="Reverse Auction" />
            <SummaryInfoHeader project={project} />
            <Box mb={3} mt={3}>
                <UnderlineNav>
                    {navItems.map(({ name, route }) => (
                        <UnderlineNav.NavItem
                            className={classnames({ active: route === selectedRoute })}
                            key={route}
                            qaTag={`reverseAuction-${route}`}
                            to={`${urlPathname}/${route}`}
                        >
                            {name}
                        </UnderlineNav.NavItem>
                    ))}
                </UnderlineNav>
            </Box>
            <Outlet />
        </Container>
    );
};

export const ReverseAuction = connectData(fetchData)(ConnectedReverseAuction);
