import { discountTypes } from '@og-pro/shared-config/priceTables';

export const CUSTOM_FIELD_CHARACTER_LIMIT = 120;

export const DESCRIPTION_OF_PRICE_ITEM_MAX_LENGTH = 240;

export const UNIT_OF_MEASURE_MAX_LENGTH = 3;

export const ACCOUNTS_SUM = 'accountsSum';
export const MAX_ACCOUNT_SPLIT_FIELDS = 5;

export const DECIMAL_PLACES = 2;

export const discountOptions = [
    {
        value: discountTypes.DOLLAR_AMOUNT,
        label: 'Amt',
    },
    {
        value: discountTypes.PERCENTAGE,
        label: '%',
    },
];
