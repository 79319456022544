import Rollbar from 'rollbar';

import { generateRollbarConfig } from '@og-pro/shared-config/rollbar';

let codeBranch;
if (__PROD__) {
    codeBranch = 'envs/prod';
} else if (__SANDBOX__) {
    codeBranch = 'envs/sandbox';
} else if (__STAGING__) {
    codeBranch = 'envs/staging';
} else if (__INT__) {
    codeBranch = 'envs/integration';
} else if (__RDEV__) {
    codeBranch = 'envs/development';
} else if (__DEMO__) {
    codeBranch = 'envs/demo';
} else if (__PERF__) {
    codeBranch = 'envs/performance';
}

export default new Rollbar(
    generateRollbarConfig({
        accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
        client: true,
        codeBranch,
        codeVersion: process.env.DIST_VERSION,
        enabled: !__DEV__ && !__TEST__ && !__PERF__ && !__RDEV__,
        environment: process.env.REPORTING_ENV,
    })
);
