import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Typography } from '@og-pro/ui';

import {
    isRequisitionClosedCancelledOrRejected,
    isRequisitionDraft,
    isRequisitionSubmitted,
} from '@og-pro/shared-config/requisitions';

import { BudgetCheckVisualization } from './Visualization';
import { Card, CDSButton } from '../../../components';
import { currencyFormatter } from '../../../helpers';
import { visualizationItemsDict } from './Visualization/constants';
import { getGovernmentReqSetting, getRequisitionJS } from '../../../selectors/govApp';
import { hasNoBudgetCheckData } from '../helpers/budgetCheck';

const ACCOUNT_MODE = 'account';
const GROUP_MODE = 'group';

const { SPENT, COMMITTED, IN_PROCESS, AVAILABLE, TOTAL } = visualizationItemsDict;

export const BudgetCheckDetails = ({
    budgetCheck: {
        fiscalYear,
        accountNumber,
        budgetCheckPass,
        requestedAmount = 0,
        accountLevel,
        groupLevel,
        isExpenseAccount,
        accountNumberCompressedFormatted,
    },
    onClose,
    fullScreen = false,
}) => {
    const { useBudgetGroup, usePreEncumbrance } = useSelector(getGovernmentReqSetting);
    const requisition = useSelector(getRequisitionJS);
    const requisitionStatus = requisition?.status;
    const [mode, setMode] = useState(useBudgetGroup && groupLevel.name ? GROUP_MODE : ACCOUNT_MODE);
    const fallingBackToAccountLevel = useBudgetGroup && !groupLevel.name;
    const styles = require('./index.scss');

    const isClosedCancelledOrRejected = isRequisitionClosedCancelledOrRejected(requisitionStatus);
    const isSubmitted = isRequisitionSubmitted(requisitionStatus);
    const isDraft = isRequisitionDraft(requisitionStatus);

    const handleViewMode = () => {
        if (mode === ACCOUNT_MODE) {
            setMode(GROUP_MODE);
        } else {
            setMode(ACCOUNT_MODE);
        }
    };

    const getBudgetValues = (budgetData) => {
        const budgetValues = {
            [SPENT]: budgetData.spentBudgetAmount || 0,
            [COMMITTED]: budgetData.committedBudgetAmount || 0,
            [IN_PROCESS]: budgetData.inProcessBudgetAmount || 0,
            [AVAILABLE]: budgetData.availableAmountUsedForBudgetCheck || 0,
            [TOTAL]: budgetData.totalBudgetAmount || 0,
        };

        // If the government is not including pre-encumbrances in the budget check AND the request is submitted, the
        // `requestedAmount` is added to the `IN_PROCESS` budget and subtracted from the `AVAILABLE` budget
        if (!usePreEncumbrance && isSubmitted) {
            budgetValues[IN_PROCESS] = requestedAmount;
            budgetValues[AVAILABLE] -= requestedAmount;
        }

        return budgetValues;
    };

    const budgetCheckData = {
        [ACCOUNT_MODE]: getBudgetValues(accountLevel),
        [GROUP_MODE]: getBudgetValues(groupLevel),
    };

    const getBudgetData = () => {
        let budgetAmount = budgetCheckData[mode][AVAILABLE];

        if (isDraft) {
            budgetAmount -= requestedAmount;
        }

        return {
            budgetAmount,
            isOverBudget: budgetAmount < 0,
        };
    };

    const { budgetAmount, isOverBudget } = getBudgetData();

    const displayBudgetCheckStatus = !isClosedCancelledOrRejected && budgetCheckPass !== undefined;
    const accountLabelByType = isExpenseAccount ? 'Account' : 'Non-Expense Account';

    const noBudgetCheckData = useMemo(() => {
        return hasNoBudgetCheckData(budgetCheckData[mode]);
    }, [budgetCheckData, mode]);

    if (noBudgetCheckData) {
        return (
            <Card
                cardClassName={styles.card}
                className={styles.container}
                style={{
                    width: fullScreen ? '100%' : '640px',
                }}
            >
                <Typography align="center" pt={2} px={2}>
                    Couldn&apos;t load budget data for #{accountNumberCompressedFormatted} in{' '}
                    {fiscalYear}. Please try again later or contact support.
                </Typography>
                {onClose && (
                    <div className={styles.footer} onClick={onClose}>
                        <CDSButton variant="secondary">Close</CDSButton>
                    </div>
                )}
            </Card>
        );
    }

    return (
        <Card
            cardClassName={styles.card}
            className={styles.container}
            style={{
                width: fullScreen ? '100%' : '640px',
            }}
        >
            <div className={styles.header}>
                <div className={styles.inlineSeparate}>
                    <h3 className={styles.title}>Details for {fiscalYear}</h3>
                    {onClose && (
                        <CDSButton
                            className={styles.closeIcon}
                            onClick={onClose}
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-close" />
                        </CDSButton>
                    )}
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.inlineSeparate}>
                    <div>
                        <span className={styles.itemProperty}>
                            {mode === ACCOUNT_MODE ? `${accountLabelByType}: ` : 'Group: '}{' '}
                        </span>{' '}
                        <span>
                            {mode === ACCOUNT_MODE
                                ? `#${accountNumberCompressedFormatted || accountNumber}`
                                : groupLevel.name}
                        </span>
                    </div>
                    {displayBudgetCheckStatus && (
                        <div>
                            <span className={styles.itemProperty}>Budget Check: </span>{' '}
                            {budgetCheckPass ? (
                                <span className={styles.budgetCheckPass}>Pass</span>
                            ) : (
                                <span className={styles.budgetCheckFail}>Fail</span>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.detailsContainer}>
                    {groupLevel.name && (
                        <CDSButton
                            className={styles.viewButton}
                            onClick={handleViewMode}
                            size="small"
                            variant="text"
                        >
                            View {mode === ACCOUNT_MODE ? 'Group' : 'Account'}
                        </CDSButton>
                    )}
                    {fallingBackToAccountLevel && (
                        <span className={styles.fallingBackToAccountLevel}>
                            No Group for This Account
                        </span>
                    )}
                    {isExpenseAccount && (
                        <span
                            className={classNames(styles.amountRemaining, {
                                [styles.overBudget]: isOverBudget,
                            })}
                            style={{
                                width: groupLevel.name ? '50%' : '100%',
                            }}
                        >
                            {isOverBudget
                                ? `Over by ${currencyFormatter({
                                      value: Math.abs(budgetAmount),
                                  })}`
                                : `${currencyFormatter({
                                      value: budgetAmount,
                                  })} Remaining`}
                        </span>
                    )}
                </div>
                <div className={styles.chartContainer}>
                    <BudgetCheckVisualization
                        data={{
                            availableAmount: budgetCheckData[mode][AVAILABLE],
                            budgetCheckPass,
                            requestedAmount,
                            ...budgetCheckData[mode],
                            usePreEncumbrance,
                            isExpenseAccount,
                        }}
                        fullScreen={fullScreen}
                    />
                </div>
                {onClose && (
                    <div className={styles.footer} onClick={onClose}>
                        <CDSButton variant="secondary">Close</CDSButton>
                    </div>
                )}
            </div>
        </Card>
    );
};

BudgetCheckDetails.propTypes = {
    budgetCheck: PropTypes.shape({
        fiscalYear: PropTypes.string,
        accountNumber: PropTypes.string,
        groupName: PropTypes.string,
        budgetCheckPass: PropTypes.bool,
        requestedAmount: PropTypes.number,
        accountLevel: PropTypes.object,
        groupLevel: PropTypes.object,
        isExpenseAccount: PropTypes.bool,
        accountNumberCompressedFormatted: PropTypes.string,
    }),
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
};
