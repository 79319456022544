import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    CDSButton,
    CDSButtonGroup,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { SharedTextareaToolbar } from '../../../../../../../components/SDv2/SharedTextareaToolbar';
import { TemplateEditV2FunctionsContext } from '../../../context';
import { SIGNATURES_TITLES } from '../../../constants';
import { form } from '../../../../forms/constants';
import { SignatureForm } from '../../../../../../../components';
import { fieldNames } from '../../../../forms/ProjectContentForm/constants';
import { showConfirmationSimpleModal } from '../../../../../../../actions/confirmation';

const { STANDALONE_SIGNATURES } = fieldNames;

export const TemplateEditSignatures = () => {
    const dispatch = useDispatch();
    const {
        buildRouteFunction,
        change,
        disabled,
        updateTemplateIfDirty,
        router,
        showFormValidation,
        tagOptions,
        templateVariableOptions,
        updateTemplate,
    } = useContext(TemplateEditV2FunctionsContext);
    const signatures = useSelector((state) =>
        formValueSelector(form)(state, STANDALONE_SIGNATURES)
    );

    const styles = require('./index.scss');

    const addSignature = () => {
        dispatch(
            arrayPush(form, STANDALONE_SIGNATURES, {
                introduction: '',
                footer: '',
                signaturesBlocks: [],
            })
        );
        setTimeout(() => {
            updateTemplate();
        }, 1);
    };

    const removeSignature = (index) => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    dispatch(arrayRemove(form, STANDALONE_SIGNATURES, index));
                    setTimeout(() => {
                        updateTemplate();
                    }, 1);
                },
                {
                    btnText: 'Delete Signature',
                    text:
                        'This will delete the Signature and all the content defined within. ' +
                        'Are you sure you want to delete this Signature?',
                }
            )
        );
    };

    const signatureIndex = signatures.length ? 0 : -1;
    const signature = signatureIndex > -1 ? signatures[signatureIndex] : null;

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description={SIGNATURES_TITLES[0].description}
                    title={
                        <Box alignItems="center" display="flex" flex={1}>
                            <Box flex={3}>{SIGNATURES_TITLES[0].title}</Box>
                            <Box flex={1}>
                                {!!signature && (
                                    <Box textAlign="right">
                                        <CDSButton
                                            className={styles.destroy}
                                            onClick={() => removeSignature(signatureIndex)}
                                            qaTag="templateEditor-removeSignature"
                                            size="small"
                                            variant="text"
                                        >
                                            <i className="fa fa-trash" /> Delete Signature
                                        </CDSButton>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    }
                />
            </Box>
            <SharedTextareaToolbar />
            <Box className={styles.content}>
                {!signature && (
                    <CDSButtonGroup>
                        <CDSButton
                            aria-label="Insert Button"
                            disabled={disabled}
                            onClick={() => addSignature()}
                            qaTag="signatures-insert"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add a Signature
                        </CDSButton>
                    </CDSButtonGroup>
                )}
                {!!signature && (
                    <Box>
                        <SignatureForm
                            {...{
                                change,
                                disabled,
                                form,
                                tagOptions,
                                templateVariableOptions,
                            }}
                            formKey={`${STANDALONE_SIGNATURES}.${signatureIndex}`}
                            showValidation={showFormValidation}
                        />
                    </Box>
                )}
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('attachments'));
                            }}
                        >
                            Attachments
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('questions-and-automations'));
                            }}
                        >
                            Questions & Automations
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
