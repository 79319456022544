import { createSelector } from 'reselect';

import { buildMap } from '@og-pro/shared-config/helpers';
import { actionTypes } from '@og-pro/shared-config/requisitionLogs';

import { getAllRequisitionLogs } from '../../selectors';

const excludedActionTypes = [actionTypes.STATUS_CHANGE];

export const getIsLoadingRequisitionLogs = (state) =>
    state.requisitionsApprovals.get('loadingRequisitionLogs');
export const getLoadRequisitionLogsError = (state) =>
    state.requisitionsApprovals.get('loadRequisitionLogsError');

const getAllReviewSequenceSteps = createSelector(
    [(state) => state.requisitions.get('reviewSequenceSteps')],
    (rawReviewSequenceSteps) => {
        return rawReviewSequenceSteps.toJS();
    }
);

const getReviewSequenceStepsMap = createSelector(
    [getAllReviewSequenceSteps],
    (reviewSequenceSteps) => {
        return buildMap(reviewSequenceSteps, 'id');
    }
);

const getStepPositionApproversMap = createSelector(
    [getAllReviewSequenceSteps],
    (reviewSequenceSteps) => {
        const stepPositionApprovers = [];
        reviewSequenceSteps.forEach(({ stepApproval }) => {
            stepApproval.stepPositionApprovals.forEach((stepPositionApproval) => {
                stepPositionApproval.stepPositionApprovers.forEach((stepPositionApprover) => {
                    stepPositionApprovers.push({
                        id: stepPositionApprover.id,
                        stepApproval,
                        stepPositionApproval,
                        stepPositionApprover,
                    });
                });
            });
        });

        return buildMap(stepPositionApprovers, 'id');
    }
);

export const getRequisitionLogs = createSelector(
    [getAllRequisitionLogs, getReviewSequenceStepsMap, getStepPositionApproversMap],
    (rawRequisitionLogs, reviewSequenceStepsMap, stepPositionApproversMap) => {
        return rawRequisitionLogs
            .filter(({ actionType }) => !excludedActionTypes.includes(actionType))
            .map((requisitionLog) => {
                const reviewSequenceStepId = requisitionLog.review_sequence_step_id;
                const stepPositionApproverId = requisitionLog.step_position_approver_id;

                return {
                    ...requisitionLog,
                    reviewSequenceStep: reviewSequenceStepsMap[reviewSequenceStepId],
                    stepPositionApprover: stepPositionApproversMap[stepPositionApproverId],
                };
            });
    }
);
