import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NoSsr } from '@mui/material';

import { isRequisitionCancellable } from '@og-pro/shared-config/requisitions';

import { qaTagPageName } from '../constants';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    cancelRequisition,
    copyRequisition,
    deleteRequisition,
    downloadRequisitionAuditCSVFile,
} from '../../../../actions/requisitions';
import { CDSDropdownButton, MenuItem } from '../../../../components';
import { getRequisitionJS } from '../../../../selectors/govApp';
import { getUserJS, isOpenGovAdmin } from '../../../selectors';

export const ActionButtons = ({ disabled }) => {
    const dispatch = useDispatch();
    const requisition = useSelector(getRequisitionJS);
    const { organization } = useSelector(getUserJS);
    // Special escape hatch delete for internal employees
    const isDeletable = useSelector(isOpenGovAdmin);
    const isCancellable = isRequisitionCancellable(requisition.status);

    const [isExportingToCSV, setIsExportingToCSV] = useState(false);

    const copyHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(copyRequisition(requisition.id)), {
                bsStyle: 'primary',
                btnText: 'Copy Request',
                icon: 'copy',
                text: 'Are you sure you want to create a copy this request?',
            })
        );

    const cancelHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(cancelRequisition(requisition.id)), {
                btnText: 'Cancel Request',
                icon: 'ban',
                text: 'Are you sure you want to cancel this request?\nThis action cannot be undone.',
            })
        );

    const deleteHandler = () =>
        dispatch(
            showConfirmationSimpleModal(() => dispatch(deleteRequisition(requisition.id)), {
                btnText: 'Delete Request',
                icon: 'delete',
                text: 'Are you sure you want to delete this request?\nThis action cannot be undone.',
            })
        );

    const exportToCSVHandler = () => {
        setIsExportingToCSV(true);

        dispatch(
            downloadRequisitionAuditCSVFile(
                requisition.id,
                requisition.identifier,
                organization.timezone
            )
        ).finally(() => setIsExportingToCSV(false));
    };

    return (
        <NoSsr>
            <div>
                <CDSDropdownButton
                    aria-label="More Actions"
                    disabled={disabled}
                    id={`${qaTagPageName}-more-button`}
                    pullRight
                    qaTag={`${qaTagPageName}-moreActions`}
                    title="Actions"
                    variant="secondary-alt"
                >
                    <MenuItem onClick={copyHandler} qaTag={`${qaTagPageName}-copy`}>
                        <i className="fa fa-fw fa-copy" /> Copy Request
                    </MenuItem>
                    <MenuItem
                        disabled={isExportingToCSV}
                        onClick={exportToCSVHandler}
                        qaTag={`${qaTagPageName}-exportAudit`}
                    >
                        <i className="fa fa-fw fa-download" /> Export Audit History to CSV
                    </MenuItem>
                    {isCancellable && (
                        <MenuItem onClick={cancelHandler} qaTag={`${qaTagPageName}-cancel`}>
                            <i className="fa fa-fw fa-ban" /> Cancel Request
                        </MenuItem>
                    )}
                    {isDeletable && (
                        <MenuItem onClick={deleteHandler} qaTag={`${qaTagPageName}-delete`}>
                            <i className="fa fa-fw fa-ban" /> Delete Request
                        </MenuItem>
                    )}
                </CDSDropdownButton>
            </div>
        </NoSsr>
    );
};

ActionButtons.propTypes = {
    disabled: PropTypes.bool,
};
