import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import { reviewTypes } from '@og-pro/shared-config/reviewSequences';

import {
    ConditionalLogicSection,
    RejectionPolicySection,
    ReviewerConfigSection,
    ReviewerPositionsSection,
    StepHeading,
    StepNameSection,
} from './components';
import { reviewSequenceStepFieldNames } from '../../constants';

const {
    ALLOW_GENERAL_EDIT,
    ALLOW_OVER_BUDGET,
    ALLOW_PRICE_EDIT,
    ALLOW_VENDOR_EDIT,
    REQUIRE_OVER_BUDGET_NOTE,
    REQUIRE_VENDOR,
} = reviewSequenceStepFieldNames;

export const ReviewSequenceStepField = ({
    change,
    closeHandler,
    disabled,
    fieldName,
    index,
    onDelete,
    onInsert,
    reviewSequenceStep,
    showFormValidation,
    stepNumber,
}) => {
    const styles = require('./index.scss');

    const {
        allowOverBudget,
        allowVendorEdit,
        id,
        minPositionReviews,
        operator,
        questionLogic,
        reviewSequenceStepPositions,
        reviewType,
    } = reviewSequenceStep;

    const sharedSectionProps = {
        change,
        disabled,
        fieldName,
        showFormValidation,
    };

    const isConfirmReviewType = reviewType === reviewTypes.CONFIRM;
    const isApproveReviewType = reviewType === reviewTypes.APPROVE;

    useEffect(() => {
        if (isConfirmReviewType) {
            change(`${fieldName}.${ALLOW_GENERAL_EDIT}`, false);
            change(`${fieldName}.${ALLOW_OVER_BUDGET}`, true);
            change(`${fieldName}.${ALLOW_PRICE_EDIT}`, false);
            change(`${fieldName}.${ALLOW_VENDOR_EDIT}`, false);
            change(`${fieldName}.${REQUIRE_OVER_BUDGET_NOTE}`, false);
            change(`${fieldName}.${REQUIRE_VENDOR}`, false);
        }
    }, [change, fieldName, isConfirmReviewType]);

    return (
        <Panel bsStyle="primary" className={styles.stepPanel}>
            <Panel.Heading className={styles.stepPanelHeading} onClick={closeHandler}>
                <StepHeading
                    disabled={disabled}
                    onDelete={onDelete}
                    onInsert={onInsert}
                    stepNumber={stepNumber}
                />
            </Panel.Heading>
            <Panel.Body className={styles.stepPanelBody}>
                <StepNameSection {...sharedSectionProps} />
                <ConditionalLogicSection
                    {...sharedSectionProps}
                    operator={operator}
                    questionLogic={questionLogic}
                    reviewSequenceStepId={id}
                />
                <ReviewerPositionsSection
                    {...sharedSectionProps}
                    minPositionReviews={minPositionReviews}
                    reviewSequenceStepPositions={reviewSequenceStepPositions}
                />
                {isApproveReviewType && (
                    <ReviewerConfigSection
                        {...sharedSectionProps}
                        allowOverBudget={allowOverBudget}
                        allowVendorEdit={allowVendorEdit}
                    />
                )}
                {isApproveReviewType && (
                    <RejectionPolicySection {...sharedSectionProps} index={index} />
                )}
            </Panel.Body>
        </Panel>
    );
};

ReviewSequenceStepField.propTypes = {
    change: PropTypes.func.isRequired,
    closeHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    onInsert: PropTypes.func.isRequired,
    reviewSequenceStep: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
    stepNumber: PropTypes.number.isRequired,
};
