import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { isRequisitionDraft } from '@og-pro/shared-config/requisitions';

import {
    FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
    PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH,
} from './constants';
import { ExceptionSequenceSelect } from './ExceptionSequenceSelect';
import { getExceptionSequenceOptions } from './selectors';
import { fieldNames } from '../../constants';
import { formConfig } from '../../form';
import { getInvitedUsersSelectOptions } from '../../../../../selectors';
import {
    DateTimePicker,
    ExpectedPurchaseOrderDateField,
    InputText,
    SearchSelect,
    SearchSelectUserOption,
    UserProfilePicture,
} from '../../../../../../components';
import { limitTextLength } from '../../../../../../Forms/normalizers';
import { getGovernmentReqSetting, getRequisitionJS } from '../../../../../../selectors/govApp';
import { useFiscalPeriodFormDisplay } from '../../../../../../lib/ogFinancials';
import { CategorySelectInput } from '../../../../../CategorySelect';
import { CategoryCodesChipList } from './CategoryCodesChipList';
import { CategoryCodesHeader } from './CategoryCodesHeader';
import { getRequisitionFormValues } from '../../../RequisitionsCreate/selectors';

export const GeneralInformation = ({ disabled, isApprovalView, showFormValidation }) => {
    const styles = require('./index.scss');
    const sharedStyles = require('../../shared.scss');

    const userSelectOptions = useSelector(getInvitedUsersSelectOptions);
    const { creator, reviewGroup, fiscalPeriodObject, status, background } =
        useSelector(getRequisitionJS);
    const reqSetting = useSelector(getGovernmentReqSetting);
    const exceptionSequenceOptions = useSelector(getExceptionSequenceOptions);

    const requestorOptions = useMemo(() => {
        const creatorIdsSet = new Set(reviewGroup.creators.map(({ id }) => id));
        return userSelectOptions.filter(({ value }) => creatorIdsSet.has(value));
    }, [reviewGroup.creators]);

    const {
        hasFMS,
        openFiscalPeriodsRange,
        fmsFiscalPeriod,
        handleExpectedPurchaseOrderDateChange,
        isError,
        isLoadingFiscalPeriods,
        isLoadingFiscalPeriodsRange,
        fiscalYearSelectOptions,
    } = useFiscalPeriodFormDisplay(
        formConfig.form,
        fieldNames.FISCAL_PERIOD_OBJECT,
        fieldNames.FISCAL_PERIOD,
        fiscalPeriodObject
    );

    const isLoading = isLoadingFiscalPeriodsRange || isLoadingFiscalPeriods;
    const isDraft = isRequisitionDraft(status);
    const [showBackground, setShowBackground] = useState(background?.length > 0);
    const normalizeDescription = limitTextLength(
        hasFMS ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH : PRO_DESCRIPTION_OF_REQUEST_MAX_LENGTH
    );
    const dispatch = useDispatch();

    const categories = useSelector(getRequisitionFormValues).categories || [];

    useEffect(() => {
        if (!showBackground) {
            dispatch(change(formConfig.form, fieldNames.BACKGROUND, null));
        }
    }, [showBackground, dispatch]);

    return (
        <div
            className={classNames(styles.generalInformation, {
                [styles.approvalGeneralInformation]: isApprovalView,
            })}
        >
            <div>
                <h4 className={styles.heading}>What is needed?</h4>
                <Field
                    characterLimit={hasFMS ? FIN_DESCRIPTION_OF_REQUEST_MAX_LENGTH : undefined}
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={
                        <h5>
                            Name of Request
                            {reqSetting?.isDescriptionRequired && ' *'}
                        </h5>
                    }
                    name={fieldNames.DESCRIPTION_OF_REQUEST}
                    normalize={normalizeDescription}
                    qaTag={`${formConfig.form}-${fieldNames.DESCRIPTION_OF_REQUEST}`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    label={<h5>Summary of Request</h5>}
                    name={fieldNames.SUMMARY}
                    showValidation={showFormValidation}
                    type="textarea"
                    useOpenGovStyle
                />
                <Box display="flex" flexDirection={categories.length > 0 ? 'column' : 'row'}>
                    {!showBackground && (
                        <Box mb={2}>
                            <Button
                                color="secondary"
                                onClick={() => setShowBackground(true)}
                                qaTag={`${formConfig.form}-showBackground`}
                                startIcon={<AddIcon />}
                                variant="text"
                            >
                                Add Background Information
                            </Button>
                        </Box>
                    )}
                    <Field
                        component={CategorySelectInput}
                        components={{
                            CategoryList: CategoryCodesChipList,
                            Header: CategoryCodesHeader,
                        }}
                        disabled={disabled}
                        label="Category Codes"
                        maxLength={110}
                        name={fieldNames.CATEGORIES}
                        onChange={() => {}}
                        useSingleCodeSet
                    />
                </Box>

                {showBackground && (
                    <div className={styles.backgroundContainer}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            label={
                                <h5>
                                    Background &nbsp;
                                    <IconButton
                                        onClick={() => setShowBackground(false)}
                                        qaTag={`${formConfig.form}-hideBackground`}
                                        size="small"
                                        variant="text"
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </h5>
                            }
                            name={fieldNames.BACKGROUND}
                            qaTag={`${formConfig.form}-add-background`}
                            showValidation={showFormValidation}
                            type="textarea"
                            useOpenGovStyle
                        />
                    </div>
                )}
            </div>
            <div>
                <h4 className={styles.heading}>
                    Who is making the request, and who needs to approve?
                </h4>
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <Field
                            component={SearchSelect}
                            components={{ Option: SearchSelectUserOption }}
                            disabled={disabled}
                            label={<h5>Requestor *</h5>}
                            name={fieldNames.REQUESTOR_ID}
                            options={requestorOptions}
                            showValidation={showFormValidation}
                            useOpenGovStyle
                        />
                    </div>
                    <div className={`col-xs-12 col-md-4 ${sharedStyles.formField}`}>
                        <h5>Approval Group *</h5>
                        <div>{reviewGroup.name}</div>
                    </div>
                    <div className={`col-xs-12 col-md-4 ${sharedStyles.formField}`}>
                        <h5>Creator</h5>
                        <div className={styles.creator}>
                            <UserProfilePicture user={creator} />
                            <span>{creator.displayName}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h4 className={styles.heading}>When is the purchase needed?</h4>
                <div className={classNames('row', styles.datesContainer)}>
                    {hasFMS && (
                        <div className="col-xs-12 col-md-4">
                            <ExpectedPurchaseOrderDateField
                                className={styles.datePicker}
                                disabled={disabled || !isDraft}
                                hasLoadingError={isError}
                                isLoading={isLoading}
                                label="Expected Purchase Order Date *"
                                name={fieldNames.EXPECTED_PURCHASE_ORDER_DATE}
                                onChange={handleExpectedPurchaseOrderDateChange}
                                openFiscalPeriodsRange={openFiscalPeriodsRange}
                                showErrorIcon
                            />
                        </div>
                    )}
                    <div className="col-xs-12 col-md-4">
                        <Field
                            allowEmpty
                            className={styles.datePicker}
                            component={DateTimePicker}
                            disabled={disabled}
                            label="Desired Delivery Date *"
                            name={fieldNames.DESIRED_DELIVERY_DATE}
                            showValidation={showFormValidation}
                            time={false}
                            useOpenGovStyle
                        />
                    </div>
                    {hasFMS ? (
                        <div className={classNames('col-xs-12', 'col-md-4', styles.budgetYearText)}>
                            Fiscal Year:{' '}
                            <span className={styles.budgetYearLightText}>{fmsFiscalPeriod}</span>
                        </div>
                    ) : (
                        <div className={classNames('col-xs-12', 'col-md-4')}>
                            <Field
                                component={SearchSelect}
                                disabled={disabled}
                                hasFeedback={false}
                                label={<h5>Fiscal Year *</h5>}
                                name={fieldNames.FISCAL_PERIOD_TAG_ID}
                                options={fiscalYearSelectOptions}
                                placeholder="Choose a Fiscal Year"
                                qaTag={`${formConfig.form}-${fieldNames.FISCAL_PERIOD_TAG_ID}`}
                                showValidation={showFormValidation}
                                useOpenGovStyle
                            />
                        </div>
                    )}
                </div>
            </div>
            {exceptionSequenceOptions.length > 0 && (
                <Box
                    borderTop={`1px solid ${capitalDesignTokens.semanticColors.border.primary}`}
                    paddingTop={2.5}
                >
                    <ExceptionSequenceSelect
                        disabled={disabled}
                        options={exceptionSequenceOptions}
                        showFormValidation={showFormValidation}
                    />
                </Box>
            )}
        </div>
    );
};

GeneralInformation.propTypes = {
    disabled: PropTypes.bool,
    isApprovalView: PropTypes.bool,
    showFormValidation: PropTypes.bool,
};
