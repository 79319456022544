import { buildMap } from '@og-pro/shared-config/helpers';

export const GENERAL_INFORMATION = 'generalInformation';
export const PURCHASE_DETAILS = 'purchaseDetails';
export const VENDOR_SELECTION = 'vendorSelection';
export const ADDITIONAL_INFORMATION = 'additionalInformation';
export const ATTACHMENTS = 'attachments';
export const REVIEW_AND_SUBMIT = 'reviewAndSubmit';

export const sections = [
    {
        name: 'General Information',
        type: GENERAL_INFORMATION,
        disabled: false,
    },
    {
        name: 'Purchase Details',
        type: PURCHASE_DETAILS,
        disabled: false,
    },
    {
        name: 'Vendor Selection',
        type: VENDOR_SELECTION,
        disabled: false,
    },
    {
        name: 'Additional Information',
        type: ADDITIONAL_INFORMATION,
        disabled: false,
    },
    {
        name: 'Attachments',
        type: ATTACHMENTS,
        disabled: false,
        internal: true,
        optional: true,
        description: 'These documents are for internal use only.',
    },
    {
        name: 'Review & Submit',
        type: REVIEW_AND_SUBMIT,
        disabled: false,
    },
].map((section) => ({
    completed: false,
    ...section,
}));

export const sectionsMap = buildMap(sections, 'type');
export const sectionsTypes = sections.map((section) => section.type);
export const enabledSectionsTypes = sections
    .filter((section) => !section.disabled)
    .map((section) => section.type);

export const NO_ADDRESS_FOUND_TEXT = 'No Address Found';
export const NO_EMAIL_FOUND_TEXT = 'No Email Found';

export const formSections = [
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
];

export const EDIT_MODE = 'edit';
export const REVIEW_MODE = 'review';
