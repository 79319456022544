import {
    AssignmentLateOutlined as AssignmentLateOutlinedIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';

import { ExceptionSequenceSelectOption } from './ExceptionSequenceSelectOption';
import { fieldNames } from '../../constants';
import { formConfig } from '../../form';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { qaTagPageName } from '../../../constants';
import { InputText, SearchSelect } from '../../../../../../components';

const { EXCEPTION_NOTE, EXCEPTION_SEQUENCE_ID } = fieldNames;

export const ExceptionSequenceSelect = ({ disabled, options, showFormValidation }) => {
    const exceptionSequenceId = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, EXCEPTION_SEQUENCE_ID)
    );
    const hasSelectedExceptionSequence = !!exceptionSequenceId;
    const [showInput, setShowInput] = useState(hasSelectedExceptionSequence);
    const dispatch = useDispatch();

    // Hides input and removes any selected exception
    const closeInput = () => {
        dispatch(change(formConfig.form, EXCEPTION_SEQUENCE_ID, null));
        setShowInput(false);
    };

    // Clear EXCEPTION_NOTE every time exception sequence is cleared/removed
    useEffect(() => {
        if (!hasSelectedExceptionSequence) {
            dispatch(change(formConfig.form, EXCEPTION_NOTE, null));
        }
    }, [hasSelectedExceptionSequence, dispatch]);

    if (!showInput) {
        return (
            <Button
                color="secondary"
                disabled={disabled}
                onClick={() => setShowInput(true)}
                qaTag={`${qaTagPageName}-showRequestException`}
                startIcon={<AssignmentLateOutlinedIcon />}
                variant="outlined"
            >
                Request an Exception
            </Button>
        );
    }

    const selectedExceptionSequenceDescription = options.find(
        ({ value }) => exceptionSequenceId === value
    )?.reviewSequence?.description;

    return (
        <Box className="row">
            <Box className="col-xs-12" paddingBottom={2}>
                <Typography component="h3">
                    Request an Exception&nbsp;
                    <IconButton
                        aria-label="Close"
                        disabled={disabled}
                        onClick={closeInput}
                        qaTag={`${qaTagPageName}-hideRequestException`}
                        size="small"
                        variant="text"
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Typography>
            </Box>
            <Box className="col-xs-12 col-md-4">
                <Field
                    component={SearchSelect}
                    components={{
                        Option: ExceptionSequenceSelectOption,
                    }}
                    disabled={disabled}
                    isClearable
                    label="Type of Exception *"
                    name={EXCEPTION_SEQUENCE_ID}
                    options={options}
                    qaTag={`${qaTagPageName}-${EXCEPTION_SEQUENCE_ID}`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </Box>
            <Box className="hidden-xs hidden-sm col-md-8">
                {selectedExceptionSequenceDescription && (
                    <Typography color="secondary" paddingTop={3} variant="bodySmall">
                        {selectedExceptionSequenceDescription}
                    </Typography>
                )}
            </Box>
            {hasSelectedExceptionSequence && (
                <Box className="col-xs-12" paddingTop={2}>
                    <Field
                        component={InputText}
                        hasFeedback={false}
                        label="Justification for Exception *"
                        name={EXCEPTION_NOTE}
                        qaTag={`${qaTagPageName}-${EXCEPTION_NOTE}`}
                        showValidation={showFormValidation}
                        type="textarea"
                        useOpenGovStyle
                    />
                </Box>
            )}
        </Box>
    );
};

ExceptionSequenceSelect.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    showFormValidation: PropTypes.bool,
};
