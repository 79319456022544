import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequisitionsList } from './List/RequisitionsList';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { dashboardRequisitionsSearch } from '../../../../actions/requisitionsDashboard';
import { useIsMounted, useQueryParam } from '../../../../hooks';
import { RequisitionsListHeader } from './List/RequisitionsListHeader';
import { RequisitionListFilters } from './List/Filters';
import { getRequisitionsSearchResults, getSearchError } from './selectors';
import { RequisitionsListPagination } from './List/RequisitionsListPagination';

export const RequisitionsDashboardBody = () => {
    const dispatch = useDispatch();

    const styles = require('./index.scss');

    const isMounted = useIsMounted();
    const searchError = useSelector(getSearchError);
    const requisitions = useSelector(getRequisitionsSearchResults);

    const [params] = useQueryParam();

    useEffect(() => {
        if (Object.keys(params).length === 0) {
            return;
        }

        dispatch(dashboardRequisitionsSearch(params));
    }, [JSON.stringify(params)]);

    if (!isMounted()) {
        return <LoadingSpinner useCDS />;
    }

    if (searchError || !requisitions) {
        return <LoadingError error={searchError} />;
    }

    return (
        <>
            <RequisitionsListHeader />
            <div className={styles.filters}>
                <RequisitionListFilters />
                <RequisitionsList requisitions={requisitions} />
                <RequisitionsListPagination shownItemsCount={requisitions.length} />
            </div>
        </>
    );
};
