import classnames from 'classnames';
import { debounce, get, throttle } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Highcharts from 'highcharts';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet } from 'react-router-dom';
import { Box } from '@og-pro/ui';
import { formatUserForLd, withLDConsumer } from '@og-pro/launch-darkly/client';

import {
    getShowConnectionAlert,
    getUserJS,
    getUsersRequestingAccessJS,
    isInitialClientLoaded,
    isSystemAdminUser,
} from './selectors';
import {
    ConfirmationModal,
    ConfirmationSimpleModal,
    DownloadModal,
    LoginModal,
    RefreshModal,
    VendorAccountModal,
} from '..';
import { initialClientRenderComplete, setAppVersion, showRefreshModal } from '../../actions/app';
import { checkSession } from '../../actions/auth';
import { loadHasNewInboxNotification } from '../../actions/inboxNotifications';
import {
    hideConnectionAlert,
    hideNotification,
    showNotification,
} from '../../actions/notification';
import {
    subscribeToUserSocketNamespace,
    unsubscribeToUserSocketNamespace,
} from '../../actions/userSocket';
import {
    BostonBanner,
    Button,
    InsertTagModal,
    InsertTemplateVariableModal,
    MasterNavbar,
    Notification,
    Snackbar,
} from '../../components';
import { isEmbedded } from '../../helpers';
import Rollbar from '../../lib/rollbar';
import { globalSocket, userSocket } from '../../lib/sockets';
import { addSessionActivityListeners, removeSessionActivityListeners } from './helpers';
import { getOutlineNumberingJS } from '../../selectors/app';

if (!process.env.SERVER) {
    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/modules/offline-exporting')(Highcharts);
}

const mapStateToProps = (state) => {
    return {
        connectionAlert: state.notification.get('connectionAlertText'),
        connectionAlertType: state.notification.get('connectionAlertType'),
        appVersion: state.app.get('appVersion'),
        isClientLoaded: isInitialClientLoaded(state),
        isSystemAdmin: isSystemAdminUser(state),
        notificationText: state.notification.get('text'),
        notificationType: state.notification.get('notificationType'),
        outlineNumbering: getOutlineNumberingJS(state),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        shouldShowNotification: state.notification.get('showNotification'),
        showDownloadModal: state.publicProject.get('showDownloadModal'),
        showInsertTagModal: state.app.get('showInsertTagModal'),
        showInsertTemplateVariableModal: state.app.get('showInsertTemplateVariableModal'),
        user: getUserJS(state),
        usersRequestingAccess: getUsersRequestingAccessJS(state),
    };
};

const mapDispatchToProps = {
    checkSession,
    hideConnectionAlert,
    hideNotification,
    initialClientRenderComplete,
    loadHasNewInboxNotification,
    setAppVersion,
    showRefreshModal,
    showNotification,
    subscribeToUserSocketNamespace,
    unsubscribeToUserSocketNamespace,
};

// @withRouter
// @connectData
// @connect
// @withLDConsumer
class ConnectedApp extends Component {
    static propTypes = {
        appVersion: PropTypes.string,
        checkSession: PropTypes.func.isRequired,
        connectionAlert: PropTypes.string,
        connectionAlertType: PropTypes.string,
        hideConnectionAlert: PropTypes.func.isRequired,
        hideNotification: PropTypes.func.isRequired,
        initialClientRenderComplete: PropTypes.func.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        isSystemAdmin: PropTypes.bool.isRequired,
        ldClient: PropTypes.shape({
            identify: PropTypes.func,
        }),
        loadHasNewInboxNotification: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        notificationText: PropTypes.string,
        notificationType: PropTypes.oneOf(['success', 'danger', 'warning', 'info']),
        outlineNumbering: PropTypes.object,
        router: PropTypes.object.isRequired,
        shouldShowConnectionAlert: PropTypes.bool.isRequired,
        shouldShowNotification: PropTypes.bool.isRequired,
        showNotification: PropTypes.func.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            firstName: PropTypes.string,
            government_id: PropTypes.number,
            id: PropTypes.number.isRequired,
            lastName: PropTypes.string,
            organization: PropTypes.shape({
                city: PropTypes.string,
                name: PropTypes.string,
            }),
            organization_id: PropTypes.number,
            vendor_id: PropTypes.number,
        }),
        setAppVersion: PropTypes.func.isRequired,
        showDownloadModal: PropTypes.bool.isRequired,
        showInsertTagModal: PropTypes.bool,
        showInsertTemplateVariableModal: PropTypes.bool,
        showRefreshModal: PropTypes.func.isRequired,
        subscribeToUserSocketNamespace: PropTypes.func.isRequired,
        unsubscribeToUserSocketNamespace: PropTypes.func.isRequired,
        usersRequestingAccess: PropTypes.arrayOf(
            PropTypes.shape({
                email: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    static defaultProps = {
        showInsertTagModal: false,
        showInsertTemplateVariableModal: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            sessionIntervalId: null,
            sessionLastUpdated: new Date(),
        };
    }

    setSessionExpirationCheckTimer = () => {
        const { user } = this.props;

        if (process.env.SERVER || !user) {
            return;
        }

        // Prevent duplicate timers from being created
        if (this.state.sessionIntervalId) {
            clearInterval(this.state.sessionIntervalId);
        }

        const sessionIntervalId = setInterval(
            () => {
                const now = moment(new Date());
                const lastUpdate = moment(this.state.sessionLastUpdated);
                const msSinceLastUpdate = now.diff(lastUpdate, 'milliseconds');
                const sessionExpiration = moment(lastUpdate).add(24, 'hours');
                const sessionHasExpired = moment(now).isSameOrAfter(sessionExpiration);
                const text = sessionHasExpired
                    ? 'Your session has expired.'
                    : 'Your session has been inactive for at least 24 hours and will expire on ' +
                      `${moment(sessionExpiration).format('lll')}. ` +
                      'Click anywhere on the screen or press any key to re-activate your session.';

                if (sessionHasExpired) {
                    clearInterval(this.state.sessionIntervalId);
                    this.sessionCheck();
                }

                if (msSinceLastUpdate >= 60 * 60 * 20 * 1000) {
                    this.props.showNotification(text, {
                        type: msSinceLastUpdate >= 60 * 60 * 22 * 1000 ? 'danger' : 'warning',
                        duration: sessionExpiration.diff(now, 'milliseconds'),
                    });
                }
            },
            // Extra ms added to avoid potential race condition with `debouncedSessionCheck`
            60 * 60 * 1000 + 1
        );

        this.setState({ sessionIntervalId });
    };

    resetSessionDate = () => {
        if (this.props.shouldShowNotification) {
            this.props.hideNotification();
        }
        this.setState({ sessionLastUpdated: new Date() });
        this.setSessionExpirationCheckTimer();
    };

    sessionCheck = () => {
        const onLoginSuccess = () => {
            addSessionActivityListeners(this.debouncedSessionCheck);
        };
        const onSessionExpiration = () => {
            removeSessionActivityListeners(this.debouncedSessionCheck);
            clearInterval(this.state.sessionIntervalId);
        };
        this.props.checkSession(onLoginSuccess, onSessionExpiration, this.resetSessionDate);
    };

    debouncedSessionCheck = debounce(this.sessionCheck, 60 * 60 * 1000, {
        leading: true,
        trailing: false,
    });

    // Implemented throttling on these checks to avoid inundating the API server with requests
    // when there are multiple reconnects to the sync server in close succession
    throttledSessionCheck = throttle(this.sessionCheck, 60 * 1000, { leading: true });

    throttledLoadHasNewInboxNotification = throttle(
        this.props.loadHasNewInboxNotification,
        60 * 10 * 1000,
        { leading: true }
    );

    registerUser = (user, ldClient) => {
        Rollbar.configure({
            payload: {
                person: {
                    email: user.email,
                    id: user.id,
                },
            },
        });

        if (ldClient) {
            const ldUser = formatUserForLd(user);
            ldClient.identify(ldUser);
        }

        // Subscribe to user socket namespace
        this.subscribeToUserSocket(user);
        userSocket.io.on('reconnect', this.userSocketReconnectHandler);

        // Setup session timeout activity listeners
        addSessionActivityListeners(this.debouncedSessionCheck);

        // Reset session date and setup session expiration timer
        this.resetSessionDate();
    };

    setOutlineNumbering() {
        const { outlineNumbering } = this.props;

        if (!outlineNumbering) {
            return;
        }

        document.body.style.setProperty('--olFirstLevel', outlineNumbering.firstLevel);
        document.body.style.setProperty('--olSecondLevel', outlineNumbering.secondLevel);
        document.body.style.setProperty('--olThirdLevel', outlineNumbering.thirdLevel);
        document.body.style.setProperty('--olFourthLevel', outlineNumbering.fourthLevel);
        document.body.style.setProperty('--olFifthLevel', outlineNumbering.fifthLevel);
    }

    userSocketReconnectHandler = () => {
        const { user } = this.props;

        if (user) {
            this.subscribeToUserSocket(user);
            this.throttledSessionCheck();
        }

        this.props.hideConnectionAlert();
    };

    componentDidMount() {
        const { ldClient, user } = this.props;

        /**
         * Due to server side rendering there are times that we want to wait until the client has
         * been mounted before rendering components (some components cannot be server rendered).
         *
         * This action gets dispatched once the initial client load has completed. It sets
         * the `initialClientRenderComplete` prop which can be used throughout the app to prevent
         * rendering of components on the server.
         *
         * Previously, we had used `process.env.SERVER` to prevent SSR, however that causes the
         * server rendered HTML to be out of sync with the initial client rendered HTML, which can
         * cause errors for React 16. Doing an initial render and then re-rendering is the
         * recommended approach for dealing with server/client HTML differences:
         * https://reactjs.org/docs/react-dom.html#hydrate
         */
        this.props.initialClientRenderComplete();

        /**
         * Compares the app version received from socket server to the current version of the app.
         * If there is a discrepancy, prompts user to refresh the app.
         */
        globalSocket.on('appVersion', (currentAppVersion) => {
            const { appVersion } = this.props;

            // Store received version if one has not previously been stored (client has just loaded)
            if (!appVersion) {
                this.props.setAppVersion(currentAppVersion);
                // When stored version is different than received version, prompt client to refresh
            } else if (appVersion !== currentAppVersion) {
                this.props.showRefreshModal();
            }
        });

        if (user) {
            this.registerUser(user, ldClient);
            userSocket.io.on('reconnect', this.userSocketReconnectHandler);
        }

        /**
         * Sets global options for all Highcharts instances
         */
        Highcharts.setOptions({
            lang: {
                thousandsSep: ',',
            },
        });

        // set outline numbering on the body element to ensure it is available in modals
        document.body.classList.add('orderedLists');
    }

    componentDidUpdate(prevProps) {
        const { ldClient, outlineNumbering, user } = this.props;

        if (!prevProps.user && user) {
            this.registerUser(user, ldClient);
        } else if (prevProps.user && !user) {
            // Remove user data from Rollbar when user has logged out
            Rollbar.configure({
                payload: {
                    person: {
                        email: null,
                        id: null,
                    },
                },
            });

            // Unsubscribe from user socket and hide alert
            userSocket.io.off('reconnect', this.userSocketReconnectHandler);
            this.unsubscribeToUserSocket(prevProps.user);
            this.props.hideConnectionAlert();

            // Remove throttled checks
            this.throttledSessionCheck.cancel();
            this.throttledLoadHasNewInboxNotification.cancel();

            // Remove session timeout activity listeners
            removeSessionActivityListeners(this.debouncedSessionCheck);

            // Remove timer for session age check
            clearInterval(this.state.sessionIntervalId);
        }

        if (prevProps.outlineNumbering !== outlineNumbering) {
            this.setOutlineNumbering();
        }
    }

    get styles() {
        return require('./App.scss');
    }

    logout = () => {
        this.props.router.push('/logout');
    };

    userSocketSubscriptionHandler = (shouldSubscribe) => (user) => {
        if (!user || !user.government_id) {
            return null;
        }

        if (shouldSubscribe) {
            this.throttledLoadHasNewInboxNotification();
            return this.props.subscribeToUserSocketNamespace(user.id);
        }
        return this.props.unsubscribeToUserSocketNamespace(user.id);
    };

    subscribeToUserSocket = this.userSocketSubscriptionHandler(true);

    unsubscribeToUserSocket = this.userSocketSubscriptionHandler(false);

    renderEmbeddedApp = () => {
        const { isClientLoaded, isSystemAdmin, location } = this.props;

        return (
            <div>
                <MasterNavbar
                    fluid
                    hideItems
                    isClientLoaded={isClientLoaded}
                    isSystemAdmin={isSystemAdmin}
                    location={location}
                />
                <Outlet />
            </div>
        );
    };

    renderConnectionStatus() {
        const { connectionAlert, connectionAlertType, shouldShowConnectionAlert } = this.props;

        if (!shouldShowConnectionAlert) return null;

        return (
            <Alert
                bsStyle={connectionAlertType}
                className={this.styles.connectionAlert}
                onDismiss={this.props.hideConnectionAlert}
            >
                {connectionAlert}
            </Alert>
        );
    }

    renderUsersRequestingAccessAlert() {
        const { isSystemAdmin, user, usersRequestingAccess } = this.props;

        if (!isSystemAdmin || usersRequestingAccess.length === 0) {
            return null;
        }
        const adminUrl = user.government_id
            ? `/governments/${user.government_id}/admin`
            : `/vendors/${user.vendor_id}/admin`;

        const isMulti = usersRequestingAccess.length > 1;
        const users = usersRequestingAccess.map((requestingUser) => requestingUser.email);
        const lastUser = users[users.length - 1];
        const usersText = isMulti ? `${users.slice(0, -1).join(', ')} and ${lastUser}` : lastUser;

        return (
            <div className={classnames(this.styles.notification, this.styles.userRequestAlert)}>
                <Alert bsStyle="warning" className={this.styles.alert}>
                    <p>
                        <strong>
                            <i className="fa fa-lg fa-user-plus" /> New team member
                            {isMulti ? 's' : ''} waiting for approval
                        </strong>
                    </p>
                    <p>
                        {usersText} requested to join {user.organization.name} and&nbsp;
                        {isMulti ? 'are' : 'is'} waiting your approval.
                    </p>
                    <p>
                        <Button bsStyle="warning" to={adminUrl}>
                            Review & Approve
                        </Button>
                    </p>
                </Alert>
            </div>
        );
    }

    render() {
        const {
            isClientLoaded,
            isSystemAdmin,
            location,
            notificationText,
            notificationType,
            shouldShowNotification,
            showDownloadModal,
            showInsertTagModal,
            showInsertTemplateVariableModal,
            user,
        } = this.props;

        // Short term hack for Boston. Should probably store a prop on the
        // organization in the future containing the website content to display.
        let orgBanner = null;
        if (user && get(user, 'government.code') === 'boston-ma') {
            orgBanner = <BostonBanner />;
        }

        // If we detect we are on an embed route, only render the children.
        // This saves us a bunch of work since we don't need the other features.
        // TODO: we'd really want to reorganize our routes to better support this
        if (isEmbedded(location)) {
            return this.renderEmbeddedApp();
        }

        return (
            <Box display="flex" flexDirection="column" height="100%" id="App">
                <MasterNavbar
                    fluid
                    isClientLoaded={isClientLoaded}
                    isSystemAdmin={isSystemAdmin}
                    location={location}
                    logout={this.logout}
                    user={user}
                />
                <Box height="100%" id="AppContent">
                    {this.renderUsersRequestingAccessAlert()}
                    {orgBanner}
                    <Notification
                        hide={this.props.hideNotification}
                        show={shouldShowNotification}
                        text={notificationText}
                        type={notificationType}
                    />
                    {this.renderConnectionStatus()}
                    <Snackbar />
                    <Outlet />
                    <ConfirmationModal />
                    <ConfirmationSimpleModal />
                    <LoginModal />
                    <VendorAccountModal />
                    {showDownloadModal && <DownloadModal />}
                    {showInsertTagModal && <InsertTagModal />}
                    {showInsertTemplateVariableModal && <InsertTemplateVariableModal />}
                    <RefreshModal />
                </Box>
            </Box>
        );
    }
}

export const App = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withLDConsumer()
)(ConnectedApp);
