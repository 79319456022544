import { listToDict } from '@og-pro/shared-config/helpers';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { projectTypesDict } from '@og-pro/shared-config/projects';

export const PROJECT_PROPERTIES_NAV = [
    {
        title: 'Project Information',
        description:
            'General information about this project. Some of these details can be used inside the document, others are for internal use only.',
        errorsKey: 'projectInformation',
    },
    {
        title: 'Set Up Questions',
        description:
            'The information you provide here will determine the language and options to include in your document.',
        errorsKey: 'setupQuestions',
        shouldHide: (project) => !project.upfrontQuestions?.length,
    },
    {
        title: 'Summary & Background',
        description:
            'Write a summary and background about this project. These will be variables that can be put wherever needed in the document.',
        errorsKey: 'summaryAndBackground',
        shouldHide: (project) => project.type === projectTypesDict.CONTRACT,
    },
    {
        title: 'Timeline',
        description: 'Outline the dates that make up the project’s timeline.',
        errorsKey: 'timeline',
        shouldHide: (project) => project.type === projectTypesDict.CONTRACT || project.isIntake,
    },
    {
        title: 'Posting Options',
        description: 'The settings you choose here will override template settings.',
        errorsKey: 'postingOptions', // Not currently used as there are no validations for section
        shouldHide: (project) => project.type === projectTypesDict.CONTRACT || project.isIntake,
    },
];

export const projectCreateDrawerForm = 'projectCreateDrawerForm';
export const editSectionForm = 'projectEditSectionForm';

const fields = [
    'projectSections',
    'section_type',
    'isDisplayed',
    'isHidden',
    'orderById',
    'parentDivider',
];

export const fieldNames = listToDict(fields);
export const SECTIONS_WITH_TINYMCE_TOOLBAR = [
    sectionTypeNames.SCOPE,
    sectionTypeNames.TERMS,
    sectionTypeNames.TEXT_AREA,
    sectionTypeNames.EVALUATION_PHASE,
    sectionTypeNames.EVALUATION_CRITERIA,
    sectionTypeNames.SIGNATURE,
];
