import { map } from 'lodash';

import { listToDict } from '@og-pro/shared-config/helpers';

export const timezoneMapping = {
    '(GMT-10:00) Hawaii Time': 'Pacific/Honolulu',
    '(GMT-09:00) Alaska Time': 'America/Anchorage',
    '(GMT-08:00) Pacific Time': 'America/Los_Angeles',
    '(GMT-07:00) Mountain Time': 'America/Denver',
    '(GMT-07:00) Mountain Time - Arizona': 'America/Phoenix',
    '(GMT-06:00) Central Time': 'America/Chicago',
    '(GMT-05:00) Eastern Time': 'America/New_York',
};

export const timezoneAbbreviations = {
    'Pacific/Honolulu': 'Hawaii Time',
    'America/Anchorage': 'Alaska Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Mountain Time - Arizona',
    'America/Chicago': 'Central Time',
    'America/New_York': 'Eastern Time',
};

export const timezones = map(timezoneMapping, (val) => val);

export const defaultError =
    'Uh oh! Something happened while transmitting data. Please check your internet connection ' +
    'and try again or refresh the page.';

const dataTypes = ['boolean', 'function', 'node', 'number', 'string'];
export const dataTypesDict = listToDict(dataTypes);

// Supportboard is intentionally excluded from Unified Login. There is no logic to redirect to
// supportboard after login on Platform.
export const USE_OG_PLATFORM =
    (__PROD__ || __TEST__ || __INT__ || __STAGING__) && process.env.APP_NAME !== 'support';

export const DOCX_TABLE_LANDSCAPE_WIDTH = 858;
export const DOCX_TABLE_PORTRAIT_WIDTH = 615;
