import { mappedProcurementContactFields } from '@og-pro/shared-config/contacts';
import { listToDict } from '@og-pro/shared-config/helpers';
import { timelineDates } from '@og-pro/shared-config/timelines';

import { contactFields } from '../../../constants/contactFields';

export const form = 'projectPostCreate';

export const formConfig = {
    form,
    destroyOnUnmount: false,
};

const baseFields = [
    'allowSubstitutionRequests',
    'attachments',
    'budget',
    'categories',
    'contact_id',
    'department_id',
    'departmentHead',
    'departmentHeadTitle',
    'departmentName',
    'financialId',
    'hasSealedBid',
    'isAutoBidUnsealed',
    'isPrivate',
    'requiresInvitation',
    'isPublicBidPricingResult',
    'isPublicBidResult',
    'isPublicBidTotalOnly',
    'isTimelineConfirmed',
    'procurement_contact_id',
    'proposalDocuments',
    'qaEnabled',
    'questionnaires',
    'rawSummary',
    'requisitionIdentifier',
    'showPlanholders',
    'summary',
    'timelineConfig',
    'timelines',
    'title',
    ...contactFields,
    ...Object.values(mappedProcurementContactFields),
];

export const dateFields = [...timelineDates, 'postScheduledAt'];

export const fields = [...baseFields, ...dateFields];

export const fieldNames = listToDict(fields);

const progressSections = ['overview', 'questionnaire', 'proposals', 'timeline'];
export const progressSectionsDict = listToDict(progressSections);

const projectOverviewSection = {
    icon: 'file-text-o',
    text: 'Project Overview',
    section: progressSectionsDict.OVERVIEW,
};
const questionnaireSection = {
    icon: 'list-ol',
    text: 'Questionnaire',
    section: progressSectionsDict.QUESTIONNAIRE,
};
const proposalDocumentsSection = {
    icon: 'envelope-o',
    text: 'Response Docs',
    section: progressSectionsDict.PROPOSALS,
};
const timelineSection = {
    icon: 'calendar',
    text: 'Timeline',
    section: progressSectionsDict.TIMELINE,
};

export const progressStepsWithQuestionnaire = [
    projectOverviewSection,
    questionnaireSection,
    timelineSection,
];

export const progressStepsWithProposalDocuments = [
    projectOverviewSection,
    proposalDocumentsSection,
    timelineSection,
];
