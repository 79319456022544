import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box, Typography } from '@og-pro/ui';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { QuestionLogicIcon } from '../../../../components';
import { ProjectAttachmentBlock } from '../../../../../../../components';
import { OGThemeContext } from '../../../../../ogThemeProvider';

const { OTHER, INTERNAL } = attachmentTypesDict;

export class TemplateAttachmentsForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        hideSupportingAttachments: PropTypes.bool,
        hideQuestionLogic: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.object.isRequired,
        questionLogicLinkable: PropTypes.string.isRequired,
        usingFakeSection: PropTypes.bool,
    };

    static contextType = OGThemeContext;

    get styles() {
        return require('./index.scss');
    }

    renderQuestionLogicIcon = (attachment) => {
        const { hideQuestionLogic, projectSection, questionLogicLinkable, usingFakeSection } =
            this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <QuestionLogicIcon
                hidden={hideQuestionLogic}
                includeText={isOGThemeEnabledForComponents}
                linkable={questionLogicLinkable}
                linkableItem={attachment}
                projectSection={projectSection}
                usingFakeSection={usingFakeSection}
            />
        );
    };

    render() {
        const { disabled, form, hideSupportingAttachments, projectId } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <>
                {!hideSupportingAttachments && (
                    <div
                        className={classnames({
                            row: !isOGThemeEnabledForComponents,
                            [this.styles.attachments]: isOGThemeEnabledForComponents,
                        })}
                    >
                        <div
                            className={classnames({
                                'col-md-offset-2 col-md-8': !isOGThemeEnabledForComponents,
                            })}
                        >
                            {isOGThemeEnabledForComponents && (
                                <div className={this.styles.heading}>
                                    <Typography variant="h3">Supporting Attachments</Typography>
                                    <Typography>
                                        Add any supporting project attachments that you would like
                                        to include.
                                    </Typography>
                                </div>
                            )}
                            <ProjectAttachmentBlock
                                disabled={disabled}
                                form={form}
                                formKey={OTHER}
                                hideNoAttachments
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                label="standard attachments that should be included with the project"
                                listAttachmentExcludeType={[INTERNAL]}
                                projectId={projectId}
                                renderQuestionLogicIcon={this.renderQuestionLogicIcon}
                                showNotification
                                uploadAttachmentType={OTHER}
                            />
                        </div>
                    </div>
                )}

                {isOGThemeEnabledForComponents && (
                    <Box mt={2}>
                        <div className={this.styles.attachments}>
                            <div className={this.styles.heading}>
                                <Typography variant="h3">Internal Documents</Typography>
                                <Typography>
                                    These documents are for internal use only. The vendor will not
                                    see them.
                                </Typography>
                            </div>
                            <ProjectAttachmentBlock
                                disabled={disabled}
                                form={form}
                                formKey={INTERNAL}
                                hideAppendixLetter
                                hideNoAttachments
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                label="standard attachments that should be included with the project"
                                listAttachmentExcludeType={[OTHER]}
                                projectId={projectId}
                                renderQuestionLogicIcon={this.renderQuestionLogicIcon}
                                showNotification
                                uploadAttachmentType={INTERNAL}
                            />
                        </div>
                    </Box>
                )}
            </>
        );
    }
}
