import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import { Box } from '@og-pro/ui';

import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';

import { fieldNames, HAS_ERRORS } from './constants';
import { CustomVariableMappingButton } from './CustomVariableMappingButton';
import { QuestionLogicItem } from './QuestionLogicItem';
import { CDSButton, CDSButtonGroup, OutlineButton } from '../../../../../components';
import { useOGTheme } from '../../../ogThemeProvider';

const { QUESTION_LOGICS } = fieldNames;

const { LINKED_UPFRONT_QUESTION, LINKED_QUESTIONNAIRE } = questionLogicLinkableModelNames;

const QuestionLogicList = ({
    disabled,
    fields,
    showFormValidation,
    deleteQuestionLogicHandler,
    showQuestionLogicModal,
}) => {
    return (
        <ListGroup>
            {fields.map((member, index) => {
                const questionLogic = fields.get(index);

                // Omit conditional questions (do not want them to be updatable)
                if (
                    [LINKED_QUESTIONNAIRE, LINKED_UPFRONT_QUESTION].includes(questionLogic.linkable)
                ) {
                    return null;
                }

                return (
                    <Field
                        component={QuestionLogicItem}
                        deleteHandler={deleteQuestionLogicHandler}
                        disabled={disabled}
                        index={index}
                        key={index}
                        name={`${member}.${HAS_ERRORS}`}
                        questionLogic={questionLogic}
                        showFormValidation={showFormValidation}
                        showQuestionLogicModal={showQuestionLogicModal}
                    />
                );
            })}
        </ListGroup>
    );
};
QuestionLogicList.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    showFormValidation: PropTypes.bool,
    deleteQuestionLogicHandler: PropTypes.func.isRequired,
    showQuestionLogicModal: PropTypes.func.isRequired,
};

export const TemplateQuestionLogicForm = (props) => {
    const { deleteQuestionLogicHandler, disabled, showFormValidation, showQuestionLogicModal } =
        props;
    const { isOGThemeEnabledForComponents } = useOGTheme();

    return (
        <>
            <FieldArray
                component={QuestionLogicList}
                deleteQuestionLogicHandler={deleteQuestionLogicHandler}
                disabled={disabled}
                name={QUESTION_LOGICS}
                showFormValidation={showFormValidation}
                showQuestionLogicModal={showQuestionLogicModal}
            />
            {!isOGThemeEnabledForComponents && (
                <div className="text-center">
                    <OutlineButton
                        bsStyle="info"
                        disabled={disabled}
                        onClick={() => showQuestionLogicModal()}
                        qaTag="templateEdit-addAutomation"
                    >
                        <i className="fa fa-plus" /> Add Automation
                    </OutlineButton>
                </div>
            )}
            {isOGThemeEnabledForComponents && (
                <CDSButtonGroup>
                    <CDSButton
                        disabled={disabled}
                        onClick={() => showQuestionLogicModal()}
                        qaTag="templateEdit-addAutomation"
                        variant="secondary-alt"
                    >
                        <i className="fa fa-plus" /> Add Automation
                    </CDSButton>
                </CDSButtonGroup>
            )}
            <Box mt={1} textAlign="right">
                <CustomVariableMappingButton />
            </Box>
        </>
    );
};

TemplateQuestionLogicForm.propTypes = {
    deleteQuestionLogicHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showFormValidation: PropTypes.bool,
    showQuestionLogicModal: PropTypes.func.isRequired,
};
