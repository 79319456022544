import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { PROJECT_SECTION_ERRORS } from './constants';
import { fieldNames } from '../ProjectContentForm/constants';
import { projectContentValidate } from '../ProjectContentForm/validate';
import { hasFormErrors } from '../../../../../helpers';

const {
    INTRODUCTION,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const {
    CRITERIA,
    EVALUATION_PHASES,
    QUESTIONNAIRES,
    SECTION_DESCRIPTIONS,
    SIGNATURES,
    TIMELINE_CONFIG,
    TIMELINES,
} = fieldNames;

function validateSection(projectSection, errors) {
    const { id, projectSubsections, section_type: sectionType } = projectSection;

    const hasSectionDescriptionError = projectSubsections.some((projectSubsection) => {
        return hasFormErrors(errors[SECTION_DESCRIPTIONS][`${id}_${projectSubsection.id}`]);
    });

    if (hasSectionDescriptionError) {
        return true;
    }

    switch (sectionType) {
        case INTRODUCTION:
            return hasFormErrors([errors[TIMELINE_CONFIG], errors[TIMELINES]]);
        case SCOPE:
        case TERMS:
        case TEXT_AREA:
            return hasFormErrors(
                projectSubsections.map((projectSubsection) => {
                    return errors[CRITERIA][`${id}_${projectSubsection.id}`];
                })
            );
        case EVALUATION_CRITERIA:
        case EVALUATION_PHASE:
            return hasFormErrors(errors[EVALUATION_PHASES]);
        case QUESTIONNAIRE:
            return hasFormErrors(errors[QUESTIONNAIRES]);
        case SIGNATURE: {
            return hasFormErrors(
                projectSubsections.map((projectSubsection) => {
                    return errors[SIGNATURES][`${id}_${projectSubsection.id}`];
                })
            );
        }
        default:
            return false;
    }
}

export function validate(values) {
    const projectSections = (values && values.projectSections) || [];

    const errors = projectContentValidate(values, projectSections);

    errors[PROJECT_SECTION_ERRORS] = projectSections.reduce((sectionErrorObj, projectSection) => {
        sectionErrorObj[projectSection.id] = validateSection(projectSection, errors);
        return sectionErrorObj;
    }, {});

    return errors;
}
