import PropTypes from 'prop-types';
import React from 'react';

import { fieldTypes } from '@og-pro/shared-config/signatures';

import { DocxHeading } from '../DocxHeading';
import { signaturesBlocksToColumns } from '../../helpers/signatures';

const DataLine = ({ styles = {}, value }) => (
    <td
        style={{
            borderBottom: '1px solid black',
            fontStyle: 'italic',
            paddingTop: '8px',
            ...styles,
        }}
    >
        <p style={{ fontStyle: 'italic', marginBottom: 0 }}>{value}</p>
    </td>
);

DataLine.propTypes = {
    styles: PropTypes.object,
    value: PropTypes.string.isRequired,
};

const SignatureField = ({ field }) => {
    switch (field.type) {
        case fieldTypes.STAMP: {
            return <DataLine styles={{ paddingTop: field.value }} value="" />;
        }
        case fieldTypes.DATE:
            return <DataLine value={new Date(field.value).toLocaleDateString()} />;
        case fieldTypes.SIGNATURE:
            return <DataLine styles={{ paddingTop: '16px' }} value="" />;
        default:
            return <DataLine value={field.value} />;
    }
};

SignatureField.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
};

const SignatureBlock = ({ block }) => {
    if (block.styling === 'header') {
        return (
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    const fields = block.fields.filter(({ type }) => type !== fieldTypes.EMAIL);

    return (
        <table
            style={{
                border: 'none',
                marginBottom: '45px',
                width: '46%',
            }}
        >
            {fields.length > 0 ? (
                <>
                    {fields.map((field) => {
                        return (
                            <React.Fragment key={field.label}>
                                <tr>
                                    <SignatureField field={field} />
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {field.label?.toUpperCase() || ''}
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </>
            ) : null}
        </table>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
};

export const SignatureDocx = ({ projectSection, signatures, useManualNumbering }) => {
    let filteredSignatures = signatures;

    if (projectSection) {
        filteredSignatures = signatures.filter(
            (signature) => signature.project_section_id === projectSection.id
        );
    }

    if (filteredSignatures.length === 0) {
        return null;
    }

    return (
        <>
            {!!projectSection && (
                <DocxHeading
                    domElement="h2"
                    legacy={<h2>{projectSection.title}</h2>}
                    numbering={
                        useManualNumbering
                            ? projectSection.manualNumber
                            : `${projectSection.sectionNumber}.`
                    }
                    title={projectSection.title}
                />
            )}

            {filteredSignatures.map((rawSignature) => {
                const signature = signaturesBlocksToColumns(rawSignature);

                const left = signature.signaturesBlocks[0];
                const right = signature.signaturesBlocks[1];

                return (
                    <>
                        {!!signature.introduction && (
                            <div style={{ marginBottom: '16px' }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: signature.introduction }} />
                            </div>
                        )}

                        <table style={{ border: 'none', width: '100%' }}>
                            <tr>
                                <td>
                                    {(left || []).map((leftBlock, i) => {
                                        return <SignatureBlock block={leftBlock} key={i} />;
                                    })}
                                </td>
                                <td style={{ width: '2%' }}>&nbsp;</td>
                                <td>
                                    {(right || []).map((rightBlock, i) => {
                                        return <SignatureBlock block={rightBlock} key={i} />;
                                    })}
                                </td>
                            </tr>
                        </table>

                        {signature.blankBlocks.map((block, i) => {
                            return (
                                <div key={i} style={{ marginTop: block.value }}>
                                    &nbsp;
                                </div>
                            );
                        })}

                        {!!signature.footer && (
                            <div style={{ marginTop: '32px' }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: signature.footer }} />
                            </div>
                        )}
                    </>
                );
            })}
        </>
    );
};

SignatureDocx.propTypes = {
    projectSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        manualNumber: PropTypes.string,
        sectionNumber: PropTypes.number,
        title: PropTypes.string.isRequired,
    }),
    signatures: PropTypes.array.isRequired,
    useManualNumbering: PropTypes.bool,
};
