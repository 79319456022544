import PropTypes from 'prop-types';
import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@og-pro/ui';

export const CheckboxGroupFilter = ({ options, formData, setFormData, dataKey }) => {
    const getCheckboxLabel = (option) => {
        let label = option.label;

        if (option.description) {
            label += ` ${option.description}`;
        }

        return label;
    };

    return (
        <Box display="flex" flexDirection="column" gap={1.5}>
            {options.map((option) => (
                <FormGroup key={option.value}>
                    <FormControlLabel
                        checked={formData[dataKey].indexOf(option.value) > -1}
                        control={
                            <Checkbox name={option.label} size="small" sx={{ py: 0, my: 0 }} />
                        }
                        label={
                            <Typography variant="bodySmall">{getCheckboxLabel(option)}</Typography>
                        }
                        onChange={() => {
                            const currentIndex = formData[dataKey].indexOf(option.value);
                            const updatedOptions = [...formData[dataKey]];
                            if (currentIndex === -1) {
                                updatedOptions.push(option.value);
                            } else {
                                updatedOptions.splice(currentIndex, 1);
                            }

                            setFormData({
                                ...formData,
                                [dataKey]: updatedOptions,
                            });
                        }}
                        sx={{ marginBottom: 0 }}
                    />
                </FormGroup>
            ))}
        </Box>
    );
};

CheckboxGroupFilter.propTypes = {
    options: PropTypes.array.isRequired,
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
};
