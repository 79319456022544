import React from 'react';
import moment from 'moment';

import { capitalize } from 'lodash';

import { requisitionFiltersNames } from '@og-pro/shared-config/requisitions';

import { dateTypes } from '../../../../../../components/DateFilter/constants';
import { RequisitionDashboardDateFilter } from './DateFilter';

import { CheckboxGroupFilter } from './CheckboxGroupFilter';
import { SourcingStatusFilter } from './SourcingStatusFilter';

const { CREATORS, REVIEW_GROUPS, LAST_ACTION, ON, BEFORE, AFTER, START, END, SOURCING_STATUS } =
    requisitionFiltersNames;

export const getCheckboxOptionsSubtitle = (formData, checkboxOptions, key) => {
    const selectedOptions = formData[key];
    if (selectedOptions.length === 0) {
        return null;
    }

    const currentCheckboxOptions = checkboxOptions[key];

    const selectedCheckboxLabels = selectedOptions
        .map(
            (selectedOption) =>
                currentCheckboxOptions.find((option) => option.value === selectedOption)?.label
        )
        .join(', ');

    return selectedCheckboxLabels;
};

export const parseDate = (str, format) => {
    if (!str) {
        return 0; // This is a hack because `react-widgets` will reset the input to the previous entered date if empty string provided
    }

    const parsedMoment = moment(
        str,
        ['M/D/YYYY', 'M-D-YYYY', 'MM-DD-YYYY', 'M.D.YYYY', 'MM.DD.YYYY'],
        true
    );

    if (parsedMoment.isValid()) {
        return parsedMoment.format(format);
    }
};

export const getDateFilterSubtitle = (dates) => {
    const formatDate = (date) => moment(new Date(date)).format('MM/DD/YYYY');

    const noDateSelected = dates.every((date) => !date.value);

    if (noDateSelected) {
        return null;
    }

    const onDate = dates.find((date) => date.type === ON);
    const afterDate = dates.find((date) => date.type === AFTER);
    const beforeDate = dates.find((date) => date.type === BEFORE);
    const startDate = dates.find((date) => date.type === START);
    const endDate = dates.find((date) => date.type === END);

    if (onDate.value || afterDate.value || beforeDate.value) {
        const dateValue = onDate.value || afterDate.value || beforeDate.value;
        const type = dates.find((date) => date.value === dateValue).type;

        return `${capitalize(type)} ${formatDate(dateValue)}`;
    }

    if (startDate.value && endDate.value) {
        return `Between ${formatDate(startDate.value)} - ${formatDate(endDate.value)}`;
    }

    if (startDate.value) {
        return `After ${formatDate(startDate.value)}`;
    }

    if (endDate.value) {
        return `Before ${formatDate(endDate.value)}`;
    }

    return null;
};

export const getFilterSections = (
    formData,
    setFormData,
    checkboxOptions,
    isGroupAdminOrMultipleGroupsLeader
) => {
    const filterSections = [
        {
            label: 'Sourcing Status',
            Component: () => (
                <SourcingStatusFilter
                    dataKey={SOURCING_STATUS}
                    formData={formData}
                    setFormData={setFormData}
                />
            ),
            subtitle: () => {
                const { [SOURCING_STATUS]: sourcingStatusValue } = formData;

                if (!sourcingStatusValue) {
                    return;
                }

                return 'Only Showing Requests with Active Sourcing';
            },
            chipValue: () => (formData[SOURCING_STATUS] ? 1 : 0),
        },
        {
            label: 'Last Action',
            Component: () => (
                <CheckboxGroupFilter
                    dataKey={LAST_ACTION}
                    formData={formData}
                    options={checkboxOptions[LAST_ACTION]}
                    setFormData={setFormData}
                />
            ),
            dataKey: LAST_ACTION,
            subtitle: () => getCheckboxOptionsSubtitle(formData, checkboxOptions, LAST_ACTION),
        },
        {
            label: 'Creators',
            Component: () => (
                <CheckboxGroupFilter
                    dataKey={CREATORS}
                    formData={formData}
                    options={checkboxOptions[CREATORS]}
                    setFormData={setFormData}
                />
            ),
            dataKey: CREATORS,
            subtitle: () => getCheckboxOptionsSubtitle(formData, checkboxOptions, CREATORS),
        },

        {
            label: 'Review Groups',
            Component: () => (
                <CheckboxGroupFilter
                    dataKey={REVIEW_GROUPS}
                    formData={formData}
                    options={checkboxOptions[REVIEW_GROUPS]}
                    setFormData={setFormData}
                />
            ),
            dataKey: REVIEW_GROUPS,
            subtitle: () => getCheckboxOptionsSubtitle(formData, checkboxOptions, REVIEW_GROUPS),
        },
        {
            label: 'Desired Delivery Date',
            Component: () => {
                return (
                    <RequisitionDashboardDateFilter formData={formData} setFormData={setFormData} />
                );
            },
            dataKey: 'date',
            chipValue: () => {
                const dateFiltersApplied = Object.keys(formData).filter(
                    (key) => dateTypes.includes(key) && formData[key]
                ).length;

                return dateFiltersApplied;
            },
            subtitle: getDateFilterSubtitle([
                { type: ON, value: formData[ON] },
                { type: AFTER, value: formData[AFTER] },
                { type: BEFORE, value: formData[BEFORE] },
                { type: START, value: formData[START] },
                { type: END, value: formData[END] },
            ]),
        },
    ];

    // If the user is not a group admin or multiple groups leader, remove the review groups filter
    if (!isGroupAdminOrMultipleGroupsLeader) {
        const reviewGroupIndex = filterSections.findIndex(
            ({ dataKey }) => dataKey === REVIEW_GROUPS
        );

        filterSections.splice(reviewGroupIndex, 1);
    }

    return filterSections;
};
