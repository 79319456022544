import { AltRoute as AltRouteIcon, MultipleStop as MultipleStopIcon } from '@mui/icons-material';
import { buildQaTag, componentNames } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { createReviewSequence } from '../../../../actions/reviewSequences';
import { LoadingButton, MenuItem } from '../../../../components';

export const ReviewSequenceCreateButton = ({ bsSize, routingHandler }) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);
    const dispatch = useDispatch();

    const enableExceptionSequences = useFlags(FLAGS.ENABLE_EXCEPTION_SEQUENCES);

    const createHandler = (createData) => {
        setCreating(true);
        setCreateError(null);
        dispatch(createReviewSequence(createData))
            .then((reviewSequence) => {
                setCreating(false);
                routingHandler(reviewSequence.id);
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    const defaultSequenceHandler = () => createHandler();

    const exceptionSequenceHandler = () => createHandler({ isException: true });

    if (enableExceptionSequences) {
        return (
            <DropdownButton
                bsSize={bsSize}
                bsStyle="primary"
                data-qa={buildQaTag('reviewSequence-create', componentNames.DROPDOWN)}
                disabled={creating}
                id="review-sequence-create-dropdown"
                pullRight
                title={
                    <>
                        <i className={`fa fa-fw fa-${creating ? 'spinner fa-spin' : 'plus'}`} /> New
                        Sequence
                    </>
                }
            >
                <MenuItem
                    disabled={creating}
                    onClick={defaultSequenceHandler}
                    qaTag="reviewSequence-createRegular"
                >
                    <MultipleStopIcon sx={{ fontSize: 'inherit' }} /> Regular Sequence
                </MenuItem>
                <MenuItem
                    disabled={creating}
                    onClick={() => exceptionSequenceHandler()}
                    qaTag="reviewSequence-createException"
                >
                    <AltRouteIcon sx={{ fontSize: 'inherit' }} /> Exception Sequence
                </MenuItem>
            </DropdownButton>
        );
    }

    return (
        <>
            <LoadingButton
                bsSize={bsSize}
                bsStyle="primary"
                disabled={creating}
                icon="fa-plus"
                loading={creating}
                onClick={defaultSequenceHandler}
                qaTag="reviewSequence-create"
                text="New Sequence"
            />
            {createError && <div className="error-block">{createError}</div>}
        </>
    );
};

ReviewSequenceCreateButton.propTypes = {
    bsSize: PropTypes.string,
    routingHandler: PropTypes.func.isRequired,
};
