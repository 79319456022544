import { CircularProgress } from '@og-pro/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export class LoadingSpinner extends PureComponent {
    static propTypes = {
        centered: PropTypes.bool,
        className: PropTypes.string,
        noPadding: PropTypes.bool,
        text: PropTypes.string,
        useCDS: PropTypes.bool,
        cdsSpinnerProps: PropTypes.object,
    };

    render() {
        const { centered, className, noPadding, text, useCDS, cdsSpinnerProps } = this.props;

        const imageSrc = 'https://assets.procurement.opengov.com/assets/loading-spinner.gif';
        const styles = require('./LoadingSpinner.scss');

        return (
            <div className={classnames(!noPadding && styles.loadingSpinnerContainer, className)}>
                {useCDS ? (
                    <CircularProgress
                        {...cdsSpinnerProps}
                        className={centered ? styles.loadingSpinnerCentered : styles.loadingSpinner}
                    />
                ) : (
                    <img
                        alt="Loading spinner"
                        className={centered ? styles.loadingSpinnerCentered : styles.loadingSpinner}
                        src={imageSrc}
                    />
                )}
                {text && <div className={`${styles.text} text-info`}>{text}</div>}
            </div>
        );
    }
}
