import { flatten, get, omit } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { MULTIPLE_CHOICE, YES_NO } from '@og-pro/shared-config/questionnaires';
import {
    questionLogicActionNames,
    questionLogicFieldNames,
    questionLogicOperatorNames,
    questionLogicLinkableModelNames,
    questionLogicLogicableModelNames,
} from '@og-pro/shared-config/questionLogics';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { getSectionNumberingString, numberItems } from '@og-pro/shared-config/helpers';

import { getQuestionLogicMap, getTemplateFormValues } from '../../TemplateEdit/selectors';
import { deserializeData } from './utils';
import { form, pseudoFieldNames } from './constants';

const { HIDE } = questionLogicActionNames;

const { ACTION, LINKABLE, LINKABLE_ID, OPERATOR, LOGICABLE_ID, LOGICABLE } =
    questionLogicFieldNames;

const { EQUAL, INCLUDE, NOT_INCLUDE } = questionLogicOperatorNames;

const {
    ATTACHMENTS,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
    INTRODUCTION,
} = sectionTypeNames;

const {
    ATTACHMENT: ATTACHMENT_LINKABLE,
    CRITERIA: CRITERIA_LINKABLE,
    PROJECT_SECTION: PROJECT_SECTION_LINKABLE,
    QUESTIONNAIRE: QUESTIONNAIRE_LINKABLE,
    SCORING_CRITERIA: SCORING_CRITERIA_LINKABLE,
    SIGNATURE_BLOCK: SIGNATURE_BLOCK_LINKABLE,
} = questionLogicLinkableModelNames;

const { LINKABLE_ITEM, PROJECT_SECTION, PROJECT_SECTION_ID, UPFRONT_QUESTION } = pseudoFieldNames;

const formSelector = formValueSelector(form);
export const getProjectSectionValue = (state) => formSelector(state, PROJECT_SECTION_ID);
export const getUpfrontQuestionValue = (state) => formSelector(state, LOGICABLE_ID);
const getLinkableId = (state) => formSelector(state, LINKABLE_ID);
export const hasMultiSelectOperator = (state) => {
    return [INCLUDE, NOT_INCLUDE].includes(formSelector(state, OPERATOR));
};

const getModalFormData = (state) => state.templatesAdmin.get('questionLogicModalData');
const getProjectSections = (state, props) => props.projectSections;
const getUpfrontQuestions = (state, props) => props.upfrontQuestions;

export const getInitialFormValues = createSelector([getModalFormData], (rawModalData) => {
    const defaultValues = {
        [OPERATOR]: EQUAL,
        [ACTION]: HIDE,
        [LOGICABLE]: questionLogicLogicableModelNames.UPFRONT_QUESTION,
    };

    if (rawModalData) {
        const modalData = rawModalData.toJS();
        return deserializeData({
            ...defaultValues,
            ...modalData,
            [PROJECT_SECTION_ID]: get(modalData, 'projectSection.id'),
        });
    }
    return defaultValues;
});

export const getUpfrontQuestionSelectOptions = createSelector(
    [getUpfrontQuestions],
    (upfrontQuestions) => {
        const numberedUpfrontQuestions = numberItems(upfrontQuestions);
        return numberedUpfrontQuestions
            .filter((upfrontQuestion) => {
                return upfrontQuestion.type === MULTIPLE_CHOICE || upfrontQuestion.type === YES_NO;
            })
            .map((upfrontQuestion) => {
                const { sectionNumber, subsectionNumber, conditionalSubQuestionNumber } =
                    upfrontQuestion;
                const sectionNumberString = getSectionNumberingString({
                    sectionNumber,
                    subsectionNumber,
                    subSectionItemNumber: conditionalSubQuestionNumber,
                    useLetterForSubsectionItem: false,
                });
                return {
                    label: `${sectionNumberString} ${upfrontQuestion.title}`,
                    [UPFRONT_QUESTION]: upfrontQuestion,
                    value: upfrontQuestion.id,
                };
            });
    }
);

export const getUpfrontQuestionValueSelectOptions = createSelector(
    [getUpfrontQuestions, getUpfrontQuestionValue],
    (upfrontQuestions, selectedUpfrontQuestionId) => {
        if (!selectedUpfrontQuestionId) {
            return [];
        }

        const upfrontQuestion = upfrontQuestions.find((q) => q.id === selectedUpfrontQuestionId);

        if (!upfrontQuestion) {
            return [];
        }

        if (upfrontQuestion.type === YES_NO) {
            return [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
            ];
        }
        if (upfrontQuestion.type === MULTIPLE_CHOICE) {
            return upfrontQuestion.data.options.map((option, index) => {
                return {
                    label: option,
                    value: index,
                };
            });
        }
        return [];
    }
);

export const getProjectSectionSelectionOptions = createSelector(
    [getProjectSections, getModalFormData],
    (projectSections, rawModalData) => {
        if (rawModalData) {
            const modalData = rawModalData.toJS();

            if (modalData.usingFakeSection) {
                return [
                    {
                        label: modalData.projectSection.title,
                        [PROJECT_SECTION]: modalData.projectSection,
                        value: modalData.projectSection.id,
                    },
                ];
            }
        }

        return projectSections
            .filter((projectSection) => {
                return projectSection.section_type !== INTRODUCTION;
            })
            .map((projectSection) => {
                return {
                    label: projectSection.title,
                    [PROJECT_SECTION]: projectSection,
                    value: projectSection.id,
                };
            });
    }
);

const getSelectedProjectSection = createSelector(
    [getProjectSections, getProjectSectionValue, getModalFormData],
    (projectSections, selectedProjectSectionId, rawModalData) => {
        if (!selectedProjectSectionId) {
            return null;
        }

        if (rawModalData) {
            const modalData = rawModalData.toJS();

            if (modalData.usingFakeSection) {
                return modalData.projectSection;
            }
        }

        return projectSections.find((p) => p.id === selectedProjectSectionId);
    }
);

const getRawProjectSectionValueSelectOptions = createSelector(
    [getSelectedProjectSection, getTemplateFormValues],
    (projectSection, templateProjectFormValues) => {
        if (!projectSection) {
            return null;
        }

        const {
            attachments = [],
            criteria,
            evaluationPhases = [],
            questionnaires = [],
            signatures,
        } = templateProjectFormValues;

        const generateOptions = (item, linkable, labelField) => {
            return {
                label: item[labelField] || 'Untitled',
                [LINKABLE]: linkable,
                [LINKABLE_ITEM]: item,
                value: `${linkable}.${item.id || item.sharedId}`, // Used to prevent potential value collisions between the linkable item ID and project section ID
            };
        };

        switch (projectSection.section_type) {
            case ATTACHMENTS:
                return attachments.map((attachment) =>
                    generateOptions(attachment, ATTACHMENT_LINKABLE, 'title')
                );
            case EVALUATION_CRITERIA:
            case EVALUATION_PHASE:
                return flatten(
                    evaluationPhases.map((evaluationPhase) => evaluationPhase.scoringCriteria)
                ).map((scoringCriterium) =>
                    generateOptions(scoringCriterium, SCORING_CRITERIA_LINKABLE, 'title')
                );
            case PRICING:
            case TEXT_AREA:
            case SIGNATURE: {
                const signature =
                    signatures[`${projectSection.id}_${projectSection.projectSubsections[0].id}`];

                return (signature?.signaturesBlocks || []).reduce((acc, cur, columnIndex) => {
                    const options = cur.map((block, i) => {
                        return {
                            ...generateOptions(block, SIGNATURE_BLOCK_LINKABLE, 'title'),
                            label: `${columnIndex === 0 ? 'Left' : 'Right'} Column, Signature Block ${i + 1}`,
                        };
                    });

                    return acc.concat(options);
                }, []);
            }
            case QUESTIONNAIRE:
                return questionnaires.map((question) =>
                    generateOptions(question, QUESTIONNAIRE_LINKABLE, 'title')
                );
            case SCOPE:
            case TERMS: {
                const projectSubsectionId = projectSection.projectSubsections[0].id;
                const selectedCriteria =
                    get(criteria, `${projectSection.id}_${projectSubsectionId}`) || [];
                return selectedCriteria.map((criterium) =>
                    generateOptions(criterium, CRITERIA_LINKABLE, 'title')
                );
            }
            case INTRODUCTION:
            default:
                return null;
        }
    }
);

const getUsedQuestionLogicMap = createSelector(
    [getQuestionLogicMap, getLinkableId],
    (questionLogicMap, linkableId) => {
        // Remove currently selected form item from map if it exists
        return omit(questionLogicMap, linkableId);
    }
);

export const getProjectSectionValueSelectOptions = createSelector(
    [getRawProjectSectionValueSelectOptions, getSelectedProjectSection, getUsedQuestionLogicMap],
    (projectSectionValueSelectOptions, projectSection, usedQuestionLogicMap) => {
        if (!projectSectionValueSelectOptions) {
            return [];
        }

        // Add option for selecting entire section
        const entireSectionOption = {
            label: 'Entire section',
            [LINKABLE]: PROJECT_SECTION_LINKABLE,
            [LINKABLE_ITEM]: projectSection,
            value: `${PROJECT_SECTION_LINKABLE}.${projectSection.id}`, // Used to prevent potential value collisions between the linkable item ID and project section ID
        };

        return [entireSectionOption]
            .concat(projectSectionValueSelectOptions)
            .reduce((acc, option) => {
                // Remove items that have already had logic added to them
                if (get(usedQuestionLogicMap, option.value)) {
                    return acc;
                }

                return acc.concat([option]);
            }, []);
    }
);
