import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form';

import { ProjectCreateV2FunctionsContext } from '../../context';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    CDSButton,
    CDSButtonGroup,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { fieldNames } from '../../../constants';
import { SignatureForm } from '../../../../../../components';
import { showConfirmationSimpleModal } from '../../../../../../actions/confirmation';
import { getTemplateVariableOptions } from '../../../../constants';
import { getProjectSectionTagOptions } from '../../../../selectors';

const { STANDALONE_SIGNATURES } = fieldNames;

export const ProjectCreateSignatures = () => {
    const dispatch = useDispatch();
    const tagOptions = useSelector(getProjectSectionTagOptions);

    const {
        buildRouteFunction,
        change,
        disabled,
        form,
        initiateSneakyUpdate,
        project,
        showFormErrors,
    } = useContext(ProjectCreateV2FunctionsContext);
    const templateVariableOptions = getTemplateVariableOptions({ project });

    const signatures = useSelector((state) =>
        formValueSelector(form)(state, STANDALONE_SIGNATURES)
    );
    const signatureIndex = signatures?.length ? 0 : -1;
    const signature = signatureIndex > -1 ? signatures[signatureIndex] : null;

    const addSignature = () => {
        dispatch(
            arrayPush(form, STANDALONE_SIGNATURES, {
                introduction: '',
                footer: '',
                signaturesBlocks: [],
            })
        );
        setTimeout(() => {
            initiateSneakyUpdate();
        }, 1);
    };

    const removeSignature = (index) => {
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    dispatch(arrayRemove(form, STANDALONE_SIGNATURES, index));
                    setTimeout(() => {
                        initiateSneakyUpdate();
                    }, 1);
                },
                {
                    btnText: 'Delete Signature',
                    text:
                        'This will delete the Signature and all the content defined within. ' +
                        'Are you sure you want to delete this Signature?',
                }
            )
        );
    };

    const styles = require('./index.scss');

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly description="" title="Signatures" />
            </Box>
            <Box className={styles.content}>
                {!signature && (
                    <CDSButtonGroup>
                        <CDSButton
                            aria-label="Insert Button"
                            disabled={disabled}
                            onClick={() => addSignature()}
                            qaTag="signatures-insert"
                            size="small"
                            variant="secondary-alt"
                        >
                            <i className="fa fa-plus" /> Add a Signature
                        </CDSButton>
                    </CDSButtonGroup>
                )}
                {!!signature && (
                    <Box>
                        <Box textAlign="right">
                            <CDSButton
                                className={styles.destroy}
                                onClick={() => removeSignature(signatureIndex)}
                                qaTag="templateEditor-removeSignature"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-trash" /> Delete Signature
                            </CDSButton>
                        </Box>
                        <SignatureForm
                            {...{
                                change,
                                disabled,
                                form,
                                tagOptions,
                                templateVariableOptions,
                            }}
                            formKey={`${STANDALONE_SIGNATURES}.${signatureIndex}`}
                            showValidation={showFormErrors}
                        />
                    </Box>
                )}
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => initiateSneakyUpdate(buildRouteFunction('attachments'))}
                        >
                            Attachments
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() =>
                                initiateSneakyUpdate(buildRouteFunction('final-review-checklist'))
                            }
                        >
                            Final Review Checklist
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
