import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { buildMap } from '@og-pro/shared-config/helpers';

import { form } from './RequisitionGroupForm/constants';
import { getUsersJS } from '../../../selectors';
import { getReviewSequences } from '../../../../selectors/govApp';

const getRawRequisitionGroup = (state) => state.requisitionGroups.get('requisitionGroup');
const requisitionGroupFormValues = (state) => getFormValues(form)(state);

export const getDeserializedRequisitionGroup = createSelector(
    [getRawRequisitionGroup],
    (rawRequisitionGroup) => {
        if (rawRequisitionGroup) {
            const requisitionGroup = rawRequisitionGroup.toJS();
            const reviewGroupPositions = requisitionGroup.reviewGroupPositions.map(
                (reviewGroupPosition) => ({
                    ...reviewGroupPosition,
                    userIds: reviewGroupPosition.reviewGroupPositionUsers.map(
                        (reviewGroupPositionUser) => reviewGroupPositionUser.user_id
                    ),
                })
            );
            return {
                ...requisitionGroup,
                creatorIds: requisitionGroup.creators.map((creatorUser) => creatorUser.id),
                leaderIds: requisitionGroup.leaders.map((leaderUser) => leaderUser.id),
                reviewGroupPositions,
            };
        }
    }
);

export const getRequisitionGroupFormValues = createSelector(
    [requisitionGroupFormValues],
    (formValues) => {
        if (formValues) {
            return formValues;
        }
        return {};
    }
);

export const getReviewSequenceOptions = createSelector([getReviewSequences], (reviewSequences) => {
    return reviewSequences
        .filter((reviewSequence) => !reviewSequence.isException)
        .map((reviewSequence) => {
            return {
                label: reviewSequence.name || 'Unnamed',
                value: reviewSequence.id,
            };
        });
});

export const getUsersById = createSelector([getUsersJS], (users) => {
    return buildMap(users, 'id');
});
