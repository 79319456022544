import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box } from '@og-pro/ui';

import { MAX_NUM_APPROVAL_WORKFLOW_STEPS } from '@og-pro/shared-config/approval';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { AddApproversForm } from './AddApproversForm/AddApproversForm';
import { ApprovalList } from './ApprovalList';
import { dashboardInstructions, dashboardInviteInfo, publishExplanation } from './constants';
import { PublishButton } from './PublishButton';
import { form } from './AddApproversForm/constants';
import { Button, HelpIcon, Main, Well } from '../../..';
import { showConfirmationModal } from '../../../../actions/confirmation';
import { START_REVIEW_PROCESS } from '../../../../constants/menuActions';

const { DRAFT, REVIEW } = projectStatusesDict;

export const ApprovalsDashboard = ({
    addApproversError,
    addApproversHandler,
    addingApprovers,
    approvals,
    builderDisplayName,
    inDraft,
    inReview,
    isApprovalComplete,
    isEditor,
    publishHandler,
    project,
    reminderHandler,
    removeApproverHandler,
    removeReviewStepHandler,
    reviewStepApproversMap,
    showInstructionsModal,
    showModal,
    updateApproversHandler,
    user,
    users,
    writingPath,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);

    // Check if the client has been validated. To be validated the confirmation page of the form
    // must have been visited and have no errors.
    const formCompleteConfirmation = useSelector((state) =>
        state.projectCreate.get('formCompleteConfirmation')
    );
    const lastUpdatedAtTimestamp = new Date(project.lastUpdatedAt).getTime();
    const isFormValidated = formCompleteConfirmation === lastUpdatedAtTimestamp;

    const styles = require('./index.scss');

    const renderPublishExplanation = () => {
        if (isApprovalComplete) return null;

        return (
            <div className={`col-xs-12 text-danger ${styles.publishInfo}`}>
                {publishExplanation(builderDisplayName)}
            </div>
        );
    };

    const showAddApproversForm = () => setShowForm(true);
    const hideAddApproversForm = () => setShowForm(false);

    const handleSubmitClick = () => {
        // If form has not been validated navigate to the confirmation page of the form
        if (!isFormValidated) {
            return navigate(`${writingPath}?section=confirmation`);
        }
        dispatch(showConfirmationModal(START_REVIEW_PROCESS, { project, writingPath }));
    };

    const addReviewStepHandler = (formData) => {
        addApproversHandler(formData).then(() => {
            hideAddApproversForm();
        });
    };

    const updateReviewStepHandler = (formData, reviewStepId, hideEditApproversForm) => {
        updateApproversHandler(formData, reviewStepId).then(() => {
            hideEditApproversForm();
        });
    };

    const renderCreateReviewStepForm = () => {
        return (
            <div className="row">
                <div className={`well ${styles.approverForm}`}>
                    <AddApproversForm
                        addingApprovers={addingApprovers}
                        approvals={approvals}
                        form={form}
                        hasApprovers={approvals.length > 0}
                        hideAddApproversForm={hideAddApproversForm}
                        onSubmit={addReviewStepHandler}
                        submitServerError={addApproversError}
                        users={users}
                    />
                </div>
            </div>
        );
    };

    const renderApprovalsList = () => {
        // Handle case where no approvers were ever added
        if (!inDraft && !inReview && approvals.length === 0) {
            return (
                <h4 className="text-muted text-center">
                    <em>No approvers were added to this {builderDisplayName.toLowerCase()}</em>
                </h4>
            );
        }

        if (approvals.length === 0) {
            return (
                <>
                    {renderCreateReviewStepForm()}
                    <div className={`row text-muted ${styles.noReviewStepsInfo}`}>
                        No review steps have been created yet.
                    </div>
                </>
            );
        }

        return (
            <>
                {isEditor &&
                    (project.status === DRAFT || project.status === REVIEW) &&
                    approvals.length < MAX_NUM_APPROVAL_WORKFLOW_STEPS && (
                        <div>
                            {!showForm ? (
                                <div className={styles.newReviewStepButton}>
                                    <Button
                                        aria-label="Add New Review Step Button"
                                        bsStyle="primary"
                                        onClick={showAddApproversForm}
                                        qaTag="approvalsDashboard-createNewReviewStep"
                                    >
                                        <i className="fa fa-plus" /> New Review Step
                                    </Button>
                                </div>
                            ) : (
                                renderCreateReviewStepForm()
                            )}
                        </div>
                    )}
                <ApprovalList
                    addingApprovers={addingApprovers}
                    approvals={approvals}
                    inDraft={inDraft}
                    inReview={inReview}
                    isEditor={isEditor}
                    reminderHandler={reminderHandler}
                    removeApproverHandler={removeApproverHandler}
                    removeReviewStepHandler={removeReviewStepHandler}
                    reviewStepApproversMap={reviewStepApproversMap}
                    showModal={showModal}
                    updateReviewStepHandler={updateReviewStepHandler}
                    user={user}
                />
            </>
        );
    };

    const renderViewOnly = () => {
        const { closed, draft, nonAdmin } = dashboardInstructions(builderDisplayName);

        let instructions = closed;
        if (inDraft) {
            instructions = draft;
        } else if (inReview) {
            instructions = nonAdmin;
        }

        return (
            <Main className="row">
                <div className="col-xs-12 col-md-10 col-md-offset-1">
                    <div className="row">
                        <h3 className={styles.viewOnlyTitle}>Approvals Dashboard</h3>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">{renderApprovalsList()}</div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                            <p className={`text-muted ${styles.instructions}`}>{instructions}</p>
                        </div>
                    </div>
                </div>
            </Main>
        );
    };

    if ((!inDraft && !inReview) || !isEditor) {
        return renderViewOnly();
    }

    return (
        <Main className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1">
                <div className="row">
                    <h3 className={styles.title}>
                        Approvals Dashboard
                        <HelpIcon
                            className={`fa fa-question-circle ${styles.helpIcon}`}
                            onClick={showInstructionsModal}
                        />
                    </h3>
                    <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                        <p className={`text-muted ${styles.approvalInfo}`}>
                            {dashboardInviteInfo(builderDisplayName)}
                        </p>
                    </div>
                </div>
                {inDraft && (
                    <div className="row">
                        <div className="col-xs-12">
                            <Well className={styles.reviewWell}>
                                <h4 className={styles.reviewWellHeader}>
                                    Ready to Start The Review Process?
                                </h4>
                                <p className={styles.reviewWellInfo}>
                                    Please review the steps below and make any changes that you
                                    need. Clicking {`"Start Review Process"`} will start the clock
                                    and notify the first round of approvers.
                                </p>
                                <Button
                                    bsSize="lg"
                                    bsStyle={isFormValidated ? 'success' : 'info'}
                                    onClick={handleSubmitClick}
                                >
                                    {isFormValidated ? (
                                        <>
                                            <i className="fa fa-check-square-o" />
                                            {` Start Review Process`}
                                        </>
                                    ) : (
                                        'Check for Errors'
                                    )}
                                </Button>
                                {!isFormValidated && (
                                    <Box className="text-warning" marginTop={1}>
                                        <i className="fa fa-exclamation-triangle" /> Your project
                                        data has not been verified
                                    </Box>
                                )}
                            </Well>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12">{renderApprovalsList()}</div>
                </div>
                {inReview && (
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <PublishButton
                                builderDisplayName={builderDisplayName}
                                className={styles.publishBtn}
                                disabled={!isApprovalComplete}
                                onClick={publishHandler}
                            />
                            {renderPublishExplanation()}
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                        <p className={`text-muted ${styles.instructions}`}>
                            {dashboardInstructions(builderDisplayName).admin}
                        </p>
                    </div>
                </div>
            </div>
        </Main>
    );
};

ApprovalsDashboard.propTypes = {
    addApproversError: PropTypes.string,
    addApproversHandler: PropTypes.func.isRequired,
    addingApprovers: PropTypes.bool.isRequired,
    approvals: PropTypes.array.isRequired,
    builderDisplayName: PropTypes.string.isRequired,
    inDraft: PropTypes.bool.isRequired,
    inReview: PropTypes.bool.isRequired,
    isApprovalComplete: PropTypes.bool.isRequired,
    isEditor: PropTypes.bool.isRequired,
    project: PropTypes.shape({
        lastUpdatedAt: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    publishHandler: PropTypes.func.isRequired,
    reminderHandler: PropTypes.func.isRequired,
    removeApproverHandler: PropTypes.func.isRequired,
    removeReviewStepHandler: PropTypes.func.isRequired,
    reviewStepApproversMap: PropTypes.object.isRequired,
    showInstructionsModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    updateApproversHandler: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    writingPath: PropTypes.string.isRequired,
};
