import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';

import { listViewQueries } from '@og-pro/shared-config/reviewSequences';

import { ReviewSequenceCreateButton } from './ReviewSequenceCreateButton';
import { ReviewSequencesList as ReviewSequencesListComponent } from './ReviewSequencesList';
import {
    getNavItems,
    getReviewSequences,
    getSelectedNavItem,
    getSelectedReviewSequences,
} from './selectors';
import { getReviewSequencesPath } from '../selectors';
import connectData from '../../../ConnectData';
import { loadReviewSequences, resetReviewSequences } from '../../../../actions/reviewSequences';
import {
    LoadingError,
    LoadingSpinner,
    Main,
    PageTitle,
    TemplateListNav,
} from '../../../../components';

function fetchData(getState, dispatch) {
    if (getState().reviewSequences.get('loadedSequences')) {
        return Promise.resolve();
    }
    return dispatch(loadReviewSequences({ view: listViewQueries.LIST_DISPLAY }));
}

// @connectData
const ConnectedReviewSequencesList = () => {
    const baseLocation = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = { ...baseLocation, query: Object.fromEntries(searchParams) };

    const loading = useSelector((state) => state.reviewSequences.get('loadingSequences'));
    const loadError = useSelector((state) => state.reviewSequences.get('loadSequencesError'));
    const reviewSequencesPath = useSelector(() => getReviewSequencesPath({ params, location }));
    const allSequences = useSelector(getReviewSequences);
    const navItems = useSelector((state) => getNavItems(state, { params, location }));
    const selectedNavItem = useSelector((state) => getSelectedNavItem(state, { params, location }));
    const selectedReviewSequences = useSelector((state) =>
        getSelectedReviewSequences(state, { params, location })
    );

    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(resetReviewSequences());
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    const routeToReviewSequence = (reviewSequenceId) => {
        navigate(`${reviewSequencesPath}/${reviewSequenceId}`);
    };

    const ReviewSequenceCreateButtonFn = (
        { bsSize } // eslint-disable-line react/prop-types
    ) => {
        return (
            <ReviewSequenceCreateButton bsSize={bsSize} routingHandler={routeToReviewSequence} />
        );
    };

    const hasNoCreatedSequences = allSequences.length === 0;

    return (
        <Main>
            <PageTitle title="Review Sequences" />
            <TemplateListNav
                TemplateCreateButton={ReviewSequenceCreateButtonFn}
                location={location}
                navItems={navItems}
                selectedNavItem={selectedNavItem}
                title="Review Sequences"
            />
            <ReviewSequencesListComponent
                ReviewSequenceCreateButton={ReviewSequenceCreateButtonFn}
                hasNoCreatedSequences={hasNoCreatedSequences}
                reviewSequences={selectedReviewSequences}
                routingHandler={routeToReviewSequence}
            />
        </Main>
    );
};

export const ReviewSequencesList = connectData(fetchData)(ConnectedReviewSequencesList);
