import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { ProjectCreateV2FunctionsContext } from '../../context';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { Attachments } from '../../..';

export const ProjectCreateV2Attachments = () => {
    const signaturesTab = useFlags(FLAGS.ENABLE_SIGNATURES_TAB);
    const {
        buildRouteFunction,
        disabled,
        form,
        initiateSneakyUpdate,
        project,
        showFormErrors,
        showHelpModal,
    } = useContext(ProjectCreateV2FunctionsContext);
    const styles = require('./index.scss');

    const showingSignaturesTab = project.type === projectTypesDict.CONTRACT && signaturesTab;

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description="Manage all documents that should be included with this project."
                    title="Attachments"
                />
            </Box>
            <Box className={styles.content}>
                <Attachments
                    allowTypeReassignment
                    disabled={disabled}
                    form={form}
                    isOGThemeEnabledForComponents
                    markBuilderSectionVisited={() => {}}
                    project={project}
                    showFormErrors={showFormErrors}
                    showHelpModal={showHelpModal}
                    showInternalAttachments
                    showInternalAttachmentsOnly={project.isIntake}
                    triggerUpdate={initiateSneakyUpdate}
                />
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() =>
                                initiateSneakyUpdate(buildRouteFunction('document-editor'))
                            }
                        >
                            Document Editor
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        showingSignaturesTab ? (
                            <BaseSectionsNavNextButton
                                onClick={() =>
                                    initiateSneakyUpdate(buildRouteFunction('signatures'))
                                }
                            >
                                Signatures
                            </BaseSectionsNavNextButton>
                        ) : (
                            <BaseSectionsNavNextButton
                                onClick={() =>
                                    initiateSneakyUpdate(
                                        buildRouteFunction('final-review-checklist')
                                    )
                                }
                            >
                                Final Review Checklist
                            </BaseSectionsNavNextButton>
                        )
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
