import { v4 as UUIDv4 } from 'uuid';

import { purchaseOrderTypesDict } from '@og-pro/shared-config/requisitions';

import { fmsAccountComponentClass } from '../../../lib/ogFinancials/AccountNumberInput';
import { fieldNames } from './RequisitionsCreateForms/constants';

const {
    CONTRACT_ID,
    PRO_VENDOR_ID,
    VENDOR_ASSIGNED_NO,
    VENDOR_EMAIL,
    VENDOR_NAME,
    VENDOR_ID,
    VENDOR_ADDRESS,
} = fieldNames;

export const scrollToSection = (section, options = {}) => {
    const element = document.getElementById(section);
    if (element) {
        const { offset = 0, behavior = 'smooth' } = options;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior,
        });
    }
};

export const scrollToFirstError = () => {
    const element = document.getElementsByClassName('has-error')[0];

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        const input = element.querySelector('input, select, textarea, button');
        input?.focus({ preventScroll: !element.classList.contains(fmsAccountComponentClass) });
    }
};

export const getEmptyVendorItem = () => ({
    associatedLineItems: [],
    attachments: [],
    vendorAssignmentUuid: UUIDv4(),
    purchaseOrderType: purchaseOrderTypesDict.REGULAR,
});

const getVendorAddressString = (organization) => {
    const { address1, city, state, zipCode } = organization;
    const suffix = [city, state, zipCode].filter(Boolean).join(' ');
    return [address1, suffix].join(address1 && suffix ? ', ' : '');
};

// Populates vendor data from a contract for a PRO vendor (not FMS connected usage only)
export const getVendorDataFromContract = (contract) => {
    const vendor = contract?.contractParty?.vendor;
    if (!vendor) {
        return;
    }

    const { contractParty, id, vendorAssignedNo } = contract;
    const { organization } = vendor;

    return {
        [CONTRACT_ID]: id,
        [PRO_VENDOR_ID]: vendor.id,
        [VENDOR_ID]: vendor.id,
        [VENDOR_ASSIGNED_NO]: vendorAssignedNo,
        [VENDOR_NAME]: organization.name || contractParty.companyName,
        [VENDOR_EMAIL]: contractParty.companyEmail,
        [VENDOR_ADDRESS]: getVendorAddressString(organization),
    };
};
