import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';
import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';

import { SDv2QuestionLogicIcon } from '../../../../containers/GovApp/TemplateAdmin/components';
import { CDSButton } from '../../CDSButtons';
import { useElementDimensions } from '../../../../hooks';
import { WIDTH_BREAKPOINT } from '../constants';

const { PROJECT_SECTION } = questionLogicLinkableModelNames;

// expects section is already numbered
export const SectionHeaderView = ({
    disabled,
    hasError = false,
    section,
    showAutomationButton = true,
    commentsComponent,
    onEditSectionClick,
    onInstructionsClick,
    showShortName = false,
    useManualNumbering,
}) => {
    const ref = useRef();
    const { matches } = useElementDimensions(ref, ({ width }) => width && width < WIDTH_BREAKPOINT);
    const styles = require('../shared.scss');

    const isDivider = section.section_type === sectionTypeNames.DIVIDER;
    const isIntroduction = section.section_type === sectionTypeNames.INTRODUCTION;
    const automation = !isDivider && !isIntroduction;

    return (
        <div
            className={styles.sectionHeader}
            ref={(r) => {
                ref.current = r;
            }}
        >
            <Box display="flex" flexDirection={matches ? 'column' : 'row'}>
                <Box className={styles.titleContainer}>
                    {section.manualNumber && useManualNumbering && (
                        <h3 className={styles.manualNumber}>{section.manualNumber}</h3>
                    )}
                    <h2 className={styles.title}>
                        {section.numbering} {section.title}
                        {!!onInstructionsClick && (
                            <CDSButton
                                className={styles.titleInstructionsIcon}
                                disabled={disabled}
                                noPadding
                                onClick={() => onInstructionsClick()}
                                qaTag="sectionHeader-instructions"
                                variant="text"
                            >
                                <i className="fa fa-question-circle fa-fw" />
                            </CDSButton>
                        )}
                    </h2>
                    {!isDivider && !hasError && (
                        <div className={styles.subtitle}>
                            {showShortName && !!section.shortName && (
                                <Box mr={5}>{section.shortName}</Box>
                            )}
                            <div>Type: {defaultSectionConfigsMap[section.section_type]?.title}</div>
                            <div className={styles.editors}>
                                Editors:{' '}
                                {section.isWritingForm ? 'Project Editors & Admins' : 'Admins'}
                            </div>
                        </div>
                    )}
                    {(section.disableTitle || section.disableNumbering) && (
                        <Box className={styles.warning} display="flex" mt={1}>
                            <Box mr={1}>
                                <i
                                    className="fa fa-exclamation-triangle fa-fw"
                                    style={{ color: tokens.colors.colorGray700 }}
                                />
                            </Box>
                            <Box sx={{ color: tokens.colors.colorGray700 }}>
                                {section.disableTitle ? 'Section Title ' : 'Section Numbering '}
                                is set to hide in Preview mode and Document Export
                            </Box>
                        </Box>
                    )}
                    {hasError && (
                        <Box className={styles.error} mt={0.5}>
                            <span className={styles.errorText}>
                                <i className="fa fa-exclamation-triangle fa-fw" /> Required fields
                                missing.
                            </span>
                            &nbsp;
                            <CDSButton
                                className={styles.errorButton}
                                noPadding
                                onClick={() => (!disabled ? onEditSectionClick(section) : null)}
                                qaTag="sectionHeader-editError"
                                size="small"
                                variant="text"
                            >
                                <span className={styles.errorButtonUnderline}>
                                    Edit {isDivider ? 'Divider' : 'Section'}
                                </span>{' '}
                                to fix the error(s).
                            </CDSButton>
                        </Box>
                    )}
                </Box>
                {(!!commentsComponent || automation || onEditSectionClick) && (
                    <Box
                        alignSelf={matches ? 'flex-end' : 'flex-start'}
                        className={classnames(styles.actionsContainer, {
                            [styles.actionsContainerSmall]: matches,
                        })}
                        display="flex"
                        flexDirection={matches ? 'row' : 'column'}
                        justifyContent="flex-end"
                    >
                        {!!commentsComponent && <Box>{commentsComponent}</Box>}
                        {automation && showAutomationButton && (
                            <Box ml={1}>
                                <SDv2QuestionLogicIcon
                                    linkable={PROJECT_SECTION}
                                    linkableItem={section}
                                    projectSection={section}
                                />
                            </Box>
                        )}
                        {onEditSectionClick && (
                            <Box ml={1}>
                                <CDSButton
                                    noPadding
                                    onClick={() => (!disabled ? onEditSectionClick(section) : null)}
                                    qaTag="templateSectionHeader-edit"
                                    size="small"
                                    variant="text"
                                >
                                    <i className="fa fa-pencil-square-o fa-fw" /> Edit{' '}
                                    {isDivider ? 'Divider' : 'Section'}
                                </CDSButton>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </div>
    );
};

SectionHeaderView.propTypes = {
    commentsComponent: PropTypes.node,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    onEditSectionClick: PropTypes.func,
    onInstructionsClick: PropTypes.func,
    section: PropTypes.shape({
        builderSection: PropTypes.shape({
            instructions: PropTypes.string,
        }),
        disableNumbering: PropTypes.bool,
        disableTitle: PropTypes.bool,
        isWritingForm: PropTypes.bool,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        section_type: PropTypes.oneOf(Object.keys(defaultSectionConfigsMap)),
        shortName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    showAutomationButton: PropTypes.bool,
    showShortName: PropTypes.bool,
    useManualNumbering: PropTypes.bool,
};
