import { Box, Button, Dialog, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { statusTypes } from '@og-pro/shared-config/requisitions';

import { RequisitionsSearchInput } from '..';

const ConnectedRequisitionsSearchDialog = ({ addHandler, disabled, onClose }) => {
    const onSelect = (requisitionId) => {
        if (requisitionId) {
            addHandler([requisitionId]);
            onClose();
        }
    };

    return (
        <Dialog dialogTitle="Add Requisition" fullWidth maxWidth="sm" onClose={onClose} open>
            <Typography color="secondary" marginBottom={0.5} variant="bodySmall">
                Search by Requisition Name or ID
            </Typography>
            <Field
                autoFocus
                component={RequisitionsSearchInput}
                disabled={disabled}
                isClearable={false}
                maxMenuHeight={120}
                name="requisitionId"
                onChange={onSelect}
                placeholder="Type to search"
                searchData={{
                    status: [
                        statusTypes.DRAFT,
                        statusTypes.DRAFT_REJECTED,
                        statusTypes.REVIEW,
                        statusTypes.REVIEW_REJECTED,
                    ],
                }}
                useOpenGovStyle
            />
            <Box columnGap={2} display="flex" justifyContent="flex-end" marginTop={10}>
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClose}
                    qaTag="confirmationSimpleDialog-cancel"
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
};

ConnectedRequisitionsSearchDialog.propTypes = {
    addHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export const RequisitionsSearchDialog = reduxForm({ form: 'requisitionsDisplaySearch' })(
    ConnectedRequisitionsSearchDialog
);
