import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@og-pro/ui';
import classnames from 'classnames';
import { arrayMove, Field } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';

import {
    fieldTypes,
    fieldTypesValues,
    singleUseFieldTypes,
} from '@og-pro/shared-config/signatures';

import { getDndStyle } from '../../../constants/styles';
import { DragIcon } from '../../DragIcon';
import { HelpIcon, InputText } from '../../InputText';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { DateTimePicker } from '../../DateTime/DateTimePicker';
import { CDSButton, CDSDropdownButton } from '../../SDv2';
import { MenuItem } from '../../MenuItem/MenuItem';
import { blockFieldNames, fieldsEditableFieldNames, stampOptions, tooltips } from './constants';

const { VALUE, LABEL } = fieldsEditableFieldNames;
const { FIELDS } = blockFieldNames;

const RenderFieldValue = ({ field, fieldValue, disabled, showValidation }) => {
    switch (fieldValue.type) {
        case fieldTypes.DATE: {
            return (
                <Field
                    component={DateTimePicker}
                    disabled={disabled}
                    hasFeedback={false}
                    horizontal
                    name={`${field}.${VALUE}`}
                    placeholder="M/D/YYYY"
                    showValidation={showValidation}
                    time={false}
                    timezone={undefined}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.DATE_SIGNED: {
            return (
                <Field
                    component={InputText}
                    disabled // Always disabled because docusign must fill this, not the user.
                    hasFeedback={false}
                    label={null}
                    name={`${field}.${VALUE}`}
                    placeholder="MM/DD/YYYY"
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.EMAIL:
        case fieldTypes.ROLE:
        case fieldTypes.NAME:
        case fieldTypes.STRING: {
            return (
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={null}
                    name={`${field}.${VALUE}`}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.STAMP: {
            return (
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    hasFeedback={false}
                    label={null}
                    name={`${field}.${VALUE}`}
                    options={stampOptions}
                    showValidation={showValidation}
                    useOpenGovStyle
                />
            );
        }
        case fieldTypes.SIGNATURE:
        default: {
            return <Box height="30px">&nbsp;</Box>;
        }
    }
};
RenderFieldValue.propTypes = {
    disabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    fieldValue: PropTypes.object.isRequired,
    showValidation: PropTypes.bool,
};

const FieldsMap = ({ disabled, fields, editLabels, showValidation }) => {
    const styles = require('./index.scss');
    return (
        <>
            {fields.map((field, index) => {
                const fieldValue = fields.get(index);

                return (
                    <Draggable draggableId={field} index={index} key={field}>
                        {(draggableProvided) => (
                            <Box
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                            >
                                <Box className={styles.fieldSet}>
                                    <Box alignItems="center" display="flex">
                                        <Box pr={2}>
                                            <DragIcon
                                                dragHandleProps={draggableProvided.dragHandleProps}
                                            />
                                        </Box>
                                        <Box flex={1}>
                                            {editLabels && (
                                                <Box>
                                                    <Box className={styles.label}>
                                                        {fieldTypesValues[fieldValue.type].label}{' '}
                                                        Label
                                                        {tooltips[fieldValue.type] && (
                                                            <HelpIcon
                                                                className={styles.helpIcon}
                                                                placement="right"
                                                                tooltip={tooltips[fieldValue.type]}
                                                                useOpenGovStyle
                                                            />
                                                        )}
                                                    </Box>
                                                    <Field
                                                        component={InputText}
                                                        disabled={
                                                            disabled ||
                                                            fieldValue.type === fieldTypes.EMAIL
                                                        }
                                                        hasFeedback={false}
                                                        label={null}
                                                        name={`${field}.${LABEL}`}
                                                        showValidation
                                                        useOpenGovStyle
                                                    />
                                                </Box>
                                            )}
                                            {!editLabels && (
                                                <Box>
                                                    <Box className={styles.label}>
                                                        {fieldValue.label}
                                                        {tooltips[fieldValue.type] && (
                                                            <HelpIcon
                                                                className={styles.helpIcon}
                                                                placement="right"
                                                                tooltip={tooltips[fieldValue.type]}
                                                                useOpenGovStyle
                                                            />
                                                        )}
                                                    </Box>
                                                    <RenderFieldValue
                                                        field={field}
                                                        fieldValue={fieldValue}
                                                        {...{
                                                            disabled,
                                                            showValidation,
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box alignSelf="flex-start" ml={2} textAlign="center">
                                            <CDSButton
                                                onClick={() => fields.remove(index)}
                                                qaTag="signatureForm-removeField"
                                                size="small"
                                                variant="text"
                                            >
                                                <CloseIcon sx={{ fontSize: 'inherit' }} />
                                            </CDSButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Draggable>
                );
            })}
        </>
    );
};
FieldsMap.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    editLabels: PropTypes.bool,
    showValidation: PropTypes.bool,
};

export const SignatureField = ({ disabled, fields, form, index, showValidation }) => {
    const [editLabels, setEditLabels] = useState(false);
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const availableOptions = useMemo(() => {
        const fieldsData = fields.getAll();
        const usedTypes = fieldsData.map((field) => field.type);

        return Object.values(fieldTypesValues).filter((fieldType) => {
            return (
                fieldType.type !== fieldTypes.HEADER &&
                (!usedTypes.includes(fieldType.type) ||
                    !singleUseFieldTypes.includes(fieldType.type))
            );
        });
    }, [fields.length]);

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            dispatch(arrayMove(form, fields.name, originLocation, newLocation));
        }
    };
    const emptyLabel = fields.map((field, i) => fields.get(i).label).some((label) => !label);

    return (
        <Box>
            <Box alignItems="center" display="flex" mb={2}>
                <Box flex={1}>
                    <Typography fontWeight={500}>Signature Block {index}</Typography>
                </Box>
                <Box>
                    <CDSButton
                        className={classnames(styles.action, styles.textSmall)}
                        disabled={emptyLabel}
                        noPadding
                        onClick={() => setEditLabels(!editLabels)}
                        qaTag="signatureForm-editLabels"
                        size="small"
                        variant="text"
                    >
                        {editLabels ? (
                            <>Done</>
                        ) : (
                            <>
                                <i className="fa fa-pencil" /> Edit Labels
                            </>
                        )}
                    </CDSButton>
                </Box>
            </Box>
            <Typography
                className={classnames(
                    styles.description,
                    styles.signatureDescriptionMargin,
                    styles.descriptionMarginBottom
                )}
            >
                This signature block corresponds to one person&apos;s signature.
            </Typography>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="signatureBlockFieldsList"
                    isDropDisabled={disabled}
                    type={FIELDS}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getDndStyle(snapshot)}
                            {...provided.droppableProps}
                        >
                            <FieldsMap {...{ disabled, editLabels, showValidation, fields }} />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Box>
                <CDSDropdownButton
                    disabled={disabled}
                    qaTag="signatureForm-addField"
                    size="small"
                    title={
                        <>
                            <i className="fa fa-plus" /> Add Signature Field
                        </>
                    }
                    variant="text"
                >
                    {availableOptions.map((fieldType) => (
                        <MenuItem
                            key={fieldType.type}
                            onClick={() => {
                                fields.push(fieldType);
                                setEditLabels(true);
                            }}
                            qaTag={`signatureForm-addField${fieldType.type}`}
                        >
                            {fieldType.label}
                        </MenuItem>
                    ))}
                </CDSDropdownButton>
            </Box>
        </Box>
    );
};

SignatureField.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    index: PropTypes.number,
    showValidation: PropTypes.bool,
};
