import { fieldTypes, requiredValueFieldTypes } from '@og-pro/shared-config/signatures';
import { isEmail } from 'validator';

import {
    fieldsEditableFieldNames as signatureFieldsFieldNames,
    fieldNames as signatureFieldNames,
    blockFieldNames as signaturesBlockFieldNames,
} from '../../components/Forms/SignatureForm/constants';
import { REQUIRED_TEXT } from './constants';
import { hasFormErrors } from '../../helpers/formErrors';

export const signatureValidate = (signature, validateValues = false) => {
    const validateBlockArray = (blocks) => {
        return blocks.reduce((blockErrors, block, blockIndex) => {
            const innerErrors = block[signaturesBlockFieldNames.FIELDS].reduce(
                (fieldErrors, field, index) => {
                    const indexErrors = {};
                    if (!field[signatureFieldsFieldNames.LABEL]) {
                        indexErrors[signatureFieldsFieldNames.LABEL] = REQUIRED_TEXT;
                    }

                    // if we want to validate for filled values only do so for required types
                    if (validateValues && requiredValueFieldTypes.includes(field.type)) {
                        if (!field[signatureFieldsFieldNames.VALUE]) {
                            indexErrors[signatureFieldsFieldNames.VALUE] = REQUIRED_TEXT;
                        }
                    }

                    if (field.type === fieldTypes.EMAIL && field[signatureFieldsFieldNames.VALUE]) {
                        if (!isEmail(field[signatureFieldsFieldNames.VALUE])) {
                            indexErrors[signatureFieldsFieldNames.VALUE] = 'Invalid email address';
                        }
                    }

                    return {
                        ...fieldErrors,
                        [index]: indexErrors,
                    };
                },
                {}
            );

            return {
                ...blockErrors,
                [blockIndex]: {
                    _error:
                        block[signaturesBlockFieldNames.FIELDS].length === 0
                            ? 'At least one field is required'
                            : null,
                    hasError:
                        hasFormErrors(innerErrors) ||
                        block[signaturesBlockFieldNames.FIELDS].length === 0,
                    [signaturesBlockFieldNames.FIELDS]: innerErrors,
                },
            };
        }, {});
    };

    if (!signature) {
        return null;
    }

    return {
        [signatureFieldNames.SIGNATURES_BLOCKS]: signature[
            signatureFieldNames.SIGNATURES_BLOCKS
        ].reduce((errors, blocks, index) => {
            const blocksErrors = validateBlockArray(blocks);

            return {
                ...errors,
                [index]: blocksErrors,
            };
        }, {}),
    };
};
