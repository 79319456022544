import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'redux-form';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import {
    ALL_SECTIONS,
    fieldNames,
    FORM_SECTIONS_KEY,
    formSectionNames,
    formSections,
    incompleteSectionInstructions,
    SECTION_STEP_NUMBER_1,
    SECTION_STEP_NUMBER_2,
    SECTION_STEP_NUMBER_3,
    SECTION_STEP_NUMBER_4,
} from './constants';
import { generateReviewSequenceStep } from './helper';
import { ReviewSequenceExceptionSequences } from './ReviewSequenceExceptionSequences';
import { ReviewSequenceExceptionSequencesSection } from './ReviewSequenceExceptionSequencesSection';
import { ReviewSequenceInfo } from './ReviewSequenceInfo';
import { ReviewSequenceInfoSection } from './ReviewSequenceInfoSection';
import { ReviewSequenceSteps } from './ReviewSequenceSteps';
import { ReviewSequenceStepsSection } from './ReviewSequenceStepsSection';
import { SectionContainer, TemplateReviewSection } from '../../../../../components';

const { EXCEPTION_SEQUENCES, REVIEW_SEQUENCE_STEPS } = fieldNames;
const { EXCEPTIONS, INFO, STEPS } = formSectionNames;

export const ReviewSequenceForm = ({
    array,
    change,
    disabled,
    isException,
    isPublished,
    reviewSequenceFormValues,
    setShowFormValidation,
    showFormValidation,
    toggleLive,
    updateReviewSequence,
}) => {
    const [openSectionNumber, setOpenSectionNumber] = useState(null);

    const enableExceptionSequences = useFlags(FLAGS.ENABLE_EXCEPTION_SEQUENCES);
    const useExceptionSection = enableExceptionSequences && !isException;

    const openSectionHandler = (sectionNumber, shouldShowFormValidation = false) => {
        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSectionNumber ? null : sectionNumber;
        setOpenSectionNumber(newSectionNumber);
        setShowFormValidation(shouldShowFormValidation);
    };

    const addReviewSequenceStep = (reviewSequenceStepData) => {
        array.push(REVIEW_SEQUENCE_STEPS, generateReviewSequenceStep(reviewSequenceStepData));
    };

    const formSectionProps = {
        change,
        disabled,
        showFormValidation,
    };

    // Form will either have 3 or 4 steps depending on if it includes an exception section
    const finalStepNumber = useExceptionSection ? SECTION_STEP_NUMBER_4 : SECTION_STEP_NUMBER_3;

    return (
        <>
            <Field
                component={SectionContainer}
                info={<ReviewSequenceInfo reviewSequence={reviewSequenceFormValues} />}
                isOpen={openSectionNumber === SECTION_STEP_NUMBER_1}
                name={`${FORM_SECTIONS_KEY}.${INFO}`}
                number={SECTION_STEP_NUMBER_1}
                onClick={openSectionHandler}
                title="Design your sequence"
            >
                <ReviewSequenceInfoSection {...formSectionProps} />
            </Field>
            <Field
                component={SectionContainer}
                info={
                    <ReviewSequenceSteps
                        reviewSequenceSteps={get(
                            reviewSequenceFormValues,
                            REVIEW_SEQUENCE_STEPS,
                            []
                        )}
                    />
                }
                isOpen={openSectionNumber === SECTION_STEP_NUMBER_2}
                name={`${FORM_SECTIONS_KEY}.${STEPS}`}
                number={SECTION_STEP_NUMBER_2}
                onClick={openSectionHandler}
                title="Configure your review sequence"
            >
                <ReviewSequenceStepsSection
                    addReviewSequenceStep={addReviewSequenceStep}
                    {...formSectionProps}
                />
            </Field>
            {useExceptionSection && (
                <Field
                    component={SectionContainer}
                    info={
                        <ReviewSequenceExceptionSequences
                            exceptionSequences={get(
                                reviewSequenceFormValues,
                                EXCEPTION_SEQUENCES,
                                []
                            )}
                        />
                    }
                    isOpen={openSectionNumber === SECTION_STEP_NUMBER_3}
                    name={`${FORM_SECTIONS_KEY}.${EXCEPTIONS}`}
                    number={SECTION_STEP_NUMBER_3}
                    onClick={openSectionHandler}
                    title="Select exception workflows to allow"
                >
                    <ReviewSequenceExceptionSequencesSection {...formSectionProps} />
                </Field>
            )}
            {!isPublished && (
                <Field
                    component={SectionContainer}
                    isOpen={openSectionNumber === finalStepNumber}
                    name={ALL_SECTIONS}
                    number={finalStepNumber}
                    onClick={openSectionHandler}
                    title="Review your sequence and set it live"
                >
                    <Field
                        component={TemplateReviewSection}
                        disabled={disabled}
                        formSectionKey={FORM_SECTIONS_KEY}
                        formSections={formSections}
                        incompleteSectionInstructions={incompleteSectionInstructions}
                        name={ALL_SECTIONS}
                        openSectionHandler={openSectionHandler}
                        readyText="This sequence will be available for use by your team as soon as it's live"
                        saveHandler={updateReviewSequence}
                        setLiveHandler={toggleLive}
                    />
                </Field>
            )}
        </>
    );
};

ReviewSequenceForm.propTypes = {
    array: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isException: PropTypes.bool.isRequired,
    isPublished: PropTypes.bool.isRequired,
    reviewSequenceFormValues: PropTypes.object.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    toggleLive: PropTypes.func.isRequired,
    updateReviewSequence: PropTypes.func.isRequired,
};
