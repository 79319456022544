import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { SignatureForm } from '../../../../../../../components';
import { fieldNames } from '../../constants';

const { SIGNATURES } = fieldNames;

export const TemplateSignaturesForm = (props) => {
    const {
        projectSection: { id, projectSubsections },
    } = props;
    const [projectSubsection] = projectSubsections;
    const formKey = `${SIGNATURES}.${id}_${projectSubsection.id}`;

    return (
        <Box>
            <SignatureForm
                {...props}
                enableQuestionLogics
                formKey={formKey}
                showValidation={props.showFormValidation}
            />
        </Box>
    );
};

TemplateSignaturesForm.propTypes = {
    form: PropTypes.string.isRequired,
    projectSection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isTemplate: PropTypes.bool,
        projectSubsections: PropTypes.array.isRequired,
        section_type: PropTypes.string.isRequired,
    }).isRequired,
    showFormValidation: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};
