import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ARCHIVED, PUBLISHED } from '@og-pro/shared-config/reviewGroups';

import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import {
    copyRequisitionGroup,
    deleteRequisitionGroup,
} from '../../../../actions/requisitionGroups';
import { Button, MenuItem } from '../../../../components';

export const RequisitionGroupTitleMenuButtons = ({
    deleting,
    disabled,
    setShowFormValidation,
    requisitionGroup: { id, status },
    requisitionGroupsPath,
    toggleArchive,
    toggleLive,
    updateRequisitionGroup,
    updating,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isPublished = status === PUBLISHED;
    const isArchived = status === ARCHIVED;

    const copyHandler = () => {
        const copyHandlerFn = () => {
            // Copy requisition group and then route to copied requisition group page
            dispatch(
                copyRequisitionGroup(id, {
                    onSuccess: (result) => navigate(`${requisitionGroupsPath}/${result.id}`),
                })
            );
        };

        dispatch(
            showConfirmationSimpleModal(copyHandlerFn, {
                btnText: 'Copy Requisition Group',
                bsStyle: 'primary',
                icon: 'copy',
                text: 'Create a copy of this requisition group?',
            })
        );
    };

    const deleteHandler = () => {
        const deleteHandlerFn = () => {
            // Delete requisition group and then route back to requisition group list page
            dispatch(
                deleteRequisitionGroup(id, {
                    onSuccess: () => navigate(requisitionGroupsPath, { replace: true }),
                })
            );
        };

        dispatch(
            showConfirmationSimpleModal(deleteHandlerFn, {
                btnText: 'Delete Requisition Group',
                text: 'Are you sure you want to delete this requisition group?\nThis action cannot be undone.',
            })
        );
    };

    const publishIcon = isPublished ? 'ban' : 'play-circle';
    const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';
    const saveIcon = updating ? 'spinner fa-spin' : 'check';
    const styles = require('./RequisitionGroupTitleMenu.scss');

    if (isArchived) {
        return (
            <>
                <Button
                    className={styles.buttonPaddingRight}
                    disabled={disabled}
                    onClick={deleteHandler}
                    qaTag="requisitionGroupEdit-delete"
                >
                    <i className={`fa fa-${deleteIcon}`} /> Delete
                </Button>
                <Button
                    bsStyle="primary"
                    disabled={disabled}
                    onClick={toggleArchive}
                    qaTag="requisitionGroupEdit-reEnable"
                >
                    <i className={`fa fa-${saveIcon}`} /> Re-Enable
                </Button>
            </>
        );
    }

    return (
        <>
            <DropdownButton
                className={styles.buttonPaddingRight}
                disabled={disabled}
                id="requisition-group-more-button"
                pullRight
                title="More"
            >
                <MenuItem
                    onClick={() => setShowFormValidation(true)}
                    qaTag="requisitionGroupEdit-showFormErrors"
                >
                    <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                </MenuItem>
                <MenuItem onClick={copyHandler} qaTag="requisitionGroupEdit-copy">
                    <i className="fa fa-fw fa-copy" /> Copy Group
                </MenuItem>
                <MenuItem onClick={toggleArchive} qaTag="requisitionGroupEdit-archive">
                    <i className="fa fa-fw fa-archive" /> Archive
                </MenuItem>
                <MenuItem onClick={deleteHandler} qaTag="requisitionGroupEdit-delete">
                    <i className={`fa fa-fw fa-${deleteIcon}`} /> Delete
                </MenuItem>
            </DropdownButton>
            <Button
                bsStyle={isPublished ? 'default' : 'success'}
                className={styles.buttonPaddingRight}
                disabled={disabled}
                onClick={toggleLive}
                qaTag="requisitionGroupEdit-liveToggle"
            >
                <i className={`fa fa-${publishIcon}`} /> {isPublished ? 'Disable' : 'Set Live'}
            </Button>
            <Button
                bsStyle="primary"
                disabled={disabled}
                onClick={() => updateRequisitionGroup()}
                qaTag="requisitionGroupEdit-save"
            >
                <i className={`fa fa-fw fa-${saveIcon}`} /> Save
            </Button>
        </>
    );
};

RequisitionGroupTitleMenuButtons.propTypes = {
    deleting: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    setShowFormValidation: PropTypes.func.isRequired,
    requisitionGroup: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired,
    }).isRequired,
    requisitionGroupsPath: PropTypes.string.isRequired,
    toggleArchive: PropTypes.func.isRequired,
    toggleLive: PropTypes.func.isRequired,
    updateRequisitionGroup: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
};
