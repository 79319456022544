import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';

import { Box } from '@mui/material';

import { useQueryParam } from '../../../../../../hooks';
import { RequisitionListDetailedFilters } from './DetailedFilters';
import { RequisitionListQuickFilters } from './QuickFilters';
import { RequisitionsListSearch } from './Search';
import { ChartSelection } from './ChartSelection';
import { formConfig } from '../../form';
import { buildSortingOptionValue } from './constants';
import { fieldNames } from '../../constants';
import { dateFilterTypesDict } from '../../../../../../components/DateFilter/constants';
import { RequisitionsListExport } from '../RequisitionsListExport';
import { useChartInteraction } from '../../Visualizations/hooks/useChartInteraction';

const {
    SORTING,
    ONE_DATE_TYPE,
    ON,
    DATE_TYPE,
    START,
    END,
    BEFORE,
    AFTER,
    CREATORS,
    LAST_ACTION,
    SOURCING_STATUS,
} = fieldNames;
const { ONE_DATE, DATE_RANGE } = dateFilterTypesDict;

const ConnectedRequisitionListFilters = () => {
    const [params] = useQueryParam();

    const dispatch = useDispatch();

    const getOneDateType = (filters) => {
        let defaultOneDateType = ON;

        if (filters[BEFORE]) {
            defaultOneDateType = BEFORE;
        }

        if (filters[AFTER]) {
            defaultOneDateType = AFTER;
        }

        return defaultOneDateType;
    };

    useEffect(() => {
        const initialValues = {
            [ONE_DATE_TYPE]: getOneDateType(params),
            [DATE_TYPE]: params[START] || params[END] ? DATE_RANGE : ONE_DATE,
            [CREATORS]: [],
            [LAST_ACTION]: [],
            [ON]: null,
            [AFTER]: null,
            [BEFORE]: null,
            [START]: null,
            [END]: null,
            [SOURCING_STATUS]: false,
        };

        const sorting = buildSortingOptionValue(params.sort, params.sortDirection);

        dispatch(
            initialize(formConfig.form, {
                ...initialValues,
                ...params,
                [SORTING]: sorting,
                ...params,
            })
        );
    }, [JSON.stringify(params)]);

    const { hasChartInteraction } = useChartInteraction();

    return (
        <Box
            alignItems="center"
            display="flex"
            pb={2}
            sx={{
                flexDirection: { sm: 'column', md: 'row' },
                gap: { sm: 1, md: 4 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '25%' },
                }}
            >
                {hasChartInteraction ? <ChartSelection /> : <RequisitionListQuickFilters />}
            </Box>

            <Box
                alignItems="center"
                display="flex"
                sx={{
                    width: { sm: '100%', md: '75%' },
                    justifyContent: { sm: 'flex-start', md: 'flex-end' },
                    gap: { sm: 1, lg: 2 },
                }}
            >
                <RequisitionListDetailedFilters />
                <RequisitionsListSearch />
                <RequisitionsListExport />
            </Box>
        </Box>
    );
};

export const RequisitionListFilters = reduxForm(formConfig)(ConnectedRequisitionListFilters);
