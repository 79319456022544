import { redirect } from 'react-router-dom';

import { userRolePermissions } from '@og-pro/shared-config/userRoles';

const {
    IS_CONTRACT_ADMIN,
    IS_DEPARTMENT_CONTRACT_ADMIN,
    IS_DEPARTMENT_EDITOR,
    IS_GLOBAL_EDITOR,
    IS_RETENTION_ADMIN,
    IS_SYSTEM_ADMIN,
    IS_TEMPLATE_ADMIN,
    IS_VENDOR_ADMIN,
} = userRolePermissions;

/*
 * Yes, this function returns a function that returns a function.
 * The reason for this controversial piece of code is to avoid repetition in the exported functions below.
 * permissionTypes is an array of permissions. The returned function is what we export. If you check the usages in the
 * route definitions, we pass getState as a param and then that returns the actual loader.
 */
const requireUserPermissions =
    (permissionTypes) =>
    (getState) =>
    async ({ params }) => {
        const state = getState();
        const permissions = state.auth.getIn(['user', 'userPermissions'])?.toJS();

        if (!permissionTypes.some((permissionType) => permissions[permissionType])) {
            return redirect(`/governments/${params.governmentId}/projects`);
        }

        return null;
    };

export const requireAnalyticsPermissions = requireUserPermissions([
    IS_CONTRACT_ADMIN,
    IS_DEPARTMENT_CONTRACT_ADMIN,
    IS_DEPARTMENT_EDITOR,
    IS_GLOBAL_EDITOR,
    IS_SYSTEM_ADMIN,
]);

export const requireSystemAdmin = requireUserPermissions([IS_SYSTEM_ADMIN]);

export const requireTemplateAdminPermissions = requireUserPermissions([IS_TEMPLATE_ADMIN]);

export const requireRetentionAdminPermissions = requireUserPermissions([IS_RETENTION_ADMIN]);

export const requireGlobalOrDepartmentEditor = requireUserPermissions([
    IS_GLOBAL_EDITOR,
    IS_DEPARTMENT_EDITOR,
]);

export const requireContractOrDepartmentAdmin = requireUserPermissions([
    IS_CONTRACT_ADMIN,
    IS_DEPARTMENT_CONTRACT_ADMIN,
]);

export const requireVendorListPermissions = requireUserPermissions([IS_VENDOR_ADMIN]);
