import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { ActionToolbarNavbarButton, ActionToolbarNavbarItem, Nav } from '../../../../components';
import {
    COPY_LINK,
    EVALUATE,
    INVITE_VENDORS_TO_PROJECT,
    PUBLISH,
} from '../../../../constants/menuActions';
import { isSourcingEditor, isPostDraftPage, getPostingPath } from '../../selectors';
import { isPostFormValid, isPostSaveValid } from './selectors';
import { trackEvent } from '../../../../helpers';
import {
    InviteItem,
    ExportButton,
    PublicDisplayButton,
    SaveButton,
    UnsealBidsButton,
} from '../ToolbarItems';
import { initiateUpdate, showFormValidation } from '../../../../actions/projectPost';

const { AUCTION_PENDING, EVALUATION, OPEN, PENDING, POST_PENDING, REVERSE_AUCTION } =
    projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        disabled: !!state.projectCreate.get('updating'),
        isDraftPage: isPostDraftPage(state, props),
        isEditor: isSourcingEditor(state),
        isFormValid: isPostFormValid(state),
        isSaveValid: isPostSaveValid(state),
        postingPath: getPostingPath(state, props),
        saving: !!state.projectCreate.get('updating'),
    };
};

const mapDispatchToProps = {
    initiateUpdate,
    showFormValidation,
};

// @connect
class ConnectedSourcingToolbar extends Component {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        isBuilderUsed: PropTypes.bool.isRequired,
        isDraftPage: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isFormValid: PropTypes.bool.isRequired,
        isSaveValid: PropTypes.bool.isRequired,
        postingPath: PropTypes.string.isRequired,
        project: PropTypes.object.isRequired,
        saving: PropTypes.bool.isRequired,
        initiateUpdate: PropTypes.func.isRequired,
        menuActionHandler: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showFormValidation: PropTypes.func.isRequired,
    };

    get styles() {
        return require('../ProjectActionToolbar.scss');
    }

    handleEditClick = () => {
        trackEvent('Edit Post Project Toolbar Button');
    };

    handleInviteVendorClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Invite Vendors to Project Toolbar Button');
        menuActionHandler(INVITE_VENDORS_TO_PROJECT);
    };

    handleReviewClick = () => {
        trackEvent('Review Post Project Toolbar Button');
    };

    handleScheduleClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Schedule Post Toolbar Button');
        menuActionHandler(PUBLISH);
    };

    handleShareClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Share Post Toolbar Button');
        menuActionHandler(COPY_LINK);
    };

    handleStartEvaluationClick = () => {
        const { menuActionHandler } = this.props;

        trackEvent('Start Evaluation Toolbar Button');
        menuActionHandler(EVALUATE);
    };

    saveHandler = () => {
        const {
            hasAutoAddenda,
            isSaveValid,
            project: { wasPosted },
            showConfirmationSimpleModal,
        } = this.props;

        if (!isSaveValid) {
            return this.props.showFormValidation();
        }

        if (wasPosted) {
            const nextStepText = hasAutoAddenda
                ? 'If you decide to proceed, we will prompt you to create an Addendum ' +
                  'after saving to formally document the change.'
                : 'If you decide to proceed, you should issue an addenda that summarizes ' +
                  'the changes made.';
            return showConfirmationSimpleModal(() => this.props.initiateUpdate(), {
                bsStyle: 'primary',
                btnText: 'Save Changes',
                icon: 'pencil',
                text:
                    'Warning: Project has been publicly released!\n\n' +
                    'Are you sure you want to proceed with updating the document? ' +
                    'Changes to the published document could effect vendor responses ' +
                    `that have already been started.\n\n${nextStepText}`,
            });
        }

        return this.props.initiateUpdate();
    };

    renderInvite() {
        return (
            <InviteItem
                disabled={this.props.disabled}
                isEditor={this.props.isEditor}
                projectId={this.props.project.id}
            />
        );
    }

    renderExport() {
        if (!this.props.isBuilderUsed) return null;
        const { project } = this.props;
        return (
            <ExportButton
                context="sourcing"
                disabled={this.props.disabled}
                projectDocumentUrl={get(project, 'documentAttachment.url')}
                shouldSave={false}
            />
        );
    }

    renderSavePost() {
        const { disabled, isDraftPage, isEditor, isSaveValid, saving } = this.props;

        if (!isDraftPage) {
            return null;
        }

        return (
            <SaveButton
                clickHandler={this.saveHandler}
                disabled={disabled}
                isEditor={isEditor}
                isSaveValid={isSaveValid}
                saving={saving}
            />
        );
    }

    renderEditPostItem() {
        if (this.props.isDraftPage) return null;
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-edit-post-btn"
                disabled={this.props.disabled}
                editOnly
                href={`${this.props.postingPath}/create`}
                isEditor={this.props.isEditor}
                onClick={this.handleEditClick}
            >
                <i className={`fa fa-pencil ${this.styles.editIcon}`} /> Edit Post
            </ActionToolbarNavbarItem>
        );
    }

    renderReviewPostItem() {
        if (!this.props.isDraftPage) return null;
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-review-post-btn"
                disabled={this.props.disabled}
                href={this.props.postingPath}
                onClick={this.handleReviewClick}
            >
                <i className="fa fa-file-text" /> Review
            </ActionToolbarNavbarItem>
        );
    }

    renderShareButton(isButton) {
        if (this.props.isDraftPage) return null;
        if (!this.props.project.wasPosted) return null;

        const NavbarComponent = isButton ? ActionToolbarNavbarButton : ActionToolbarNavbarItem;
        const props = isButton ? { bsStyle: 'success' } : {};
        const text = isButton ? 'Share Project' : 'Share';

        return (
            <NavbarComponent
                className="action-toolbar-share-post-btn"
                disabled={this.props.disabled}
                onClick={this.handleShareClick}
                {...props}
            >
                <i className="fa fa-share-square-o" /> {text}
            </NavbarComponent>
        );
    }

    renderInviteVendors() {
        return (
            <ActionToolbarNavbarItem
                className="action-toolbar-invite-vendors-btn"
                disabled={this.props.disabled}
                onClick={this.handleInviteVendorClick}
            >
                <i className="fa fa-envelope" /> Invite Vendors
            </ActionToolbarNavbarItem>
        );
    }

    renderCompletePostButton() {
        if (!this.props.isDraftPage || !this.props.isFormValid) return null;
        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-complete-post-btn"
                disabled={this.props.disabled}
                editOnly
                isEditor={this.props.isEditor}
                onClick={this.handleScheduleClick}
            >
                <i className="fa fa-lg fa-check-square-o" /> Schedule Post
            </ActionToolbarNavbarButton>
        );
    }

    renderEvaluateButton() {
        if (this.props.isDraftPage) return null;
        if (!this.props.hasEvaluation) return null;

        return (
            <ActionToolbarNavbarButton
                bsStyle="success"
                className="action-toolbar-evaluate-btn"
                disabled={this.props.disabled}
                editOnly
                isEditor={this.props.isEditor}
                onClick={this.handleStartEvaluationClick}
            >
                <i className="fa fa-star" /> Start Evaluation
            </ActionToolbarNavbarButton>
        );
    }

    renderPublicDisplayButton() {
        const { disabled, isEditor, menuActionHandler } = this.props;

        return (
            <PublicDisplayButton
                disabled={disabled}
                isEditor={isEditor}
                menuActionHandler={menuActionHandler}
            />
        );
    }

    renderUnsealBidButton() {
        const { disabled, isDraftPage, isEditor, menuActionHandler, project } = this.props;

        return (
            <UnsealBidsButton
                disabled={disabled}
                isDraftPage={isDraftPage}
                isEditor={isEditor}
                menuActionHandler={menuActionHandler}
                project={project}
            />
        );
    }

    renderPostPending() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderEditPostItem()}
                    {this.renderReviewPostItem()}
                    {this.renderSavePost()}
                    {this.renderCompletePostButton()}
                </Nav>
            </div>
        );
    }

    renderOpen() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderEditPostItem()}
                    {this.renderReviewPostItem()}
                    {this.renderSavePost()}
                    {this.renderInviteVendors()}
                    {this.renderShareButton(true)}
                </Nav>
            </div>
        );
    }

    renderPending() {
        const {
            project: { status },
        } = this.props;
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderPublicDisplayButton()}
                    {this.renderShareButton()}
                    {this.renderUnsealBidButton()}
                    {status !== AUCTION_PENDING &&
                        status !== REVERSE_AUCTION &&
                        this.renderEvaluateButton()}
                </Nav>
            </div>
        );
    }

    renderEvaluation() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderPublicDisplayButton()}
                    {this.renderShareButton()}
                    {this.renderUnsealBidButton()}
                </Nav>
            </div>
        );
    }

    renderClosed() {
        return (
            <div>
                <Nav>
                    {this.renderInvite()}
                    {this.renderExport()}
                    {this.renderPublicDisplayButton()}
                    {this.renderShareButton()}
                </Nav>
            </div>
        );
    }

    render() {
        switch (this.props.project.status) {
            case POST_PENDING:
                return this.renderPostPending();
            case OPEN:
                return this.renderOpen();
            case AUCTION_PENDING:
            case REVERSE_AUCTION:
            case PENDING:
                return this.renderPending();
            case EVALUATION:
                return this.renderEvaluation();
            default:
                return this.renderClosed();
        }
    }
}

export const SourcingToolbar = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedSourcingToolbar);
