const { buildMap, listToDict } = require('../helpers');
const { defaultSubsectionConfigsMap, subsectionTypeNames } = require('../subsections');

exports.sectionTypes = [
    'attachments',
    'divider',
    'evaluationCriteria',
    'evaluationPhase',
    'introduction',
    'pricing',
    'questionnaire',
    'scope',
    'terms',
    'textArea',
    'signature',
];

exports.sectionTypeNames = listToDict(exports.sectionTypes);

const {
    ATTACHMENTS,
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
} = exports.sectionTypeNames;

exports.defaultSectionConfigs = [
    {
        section_type: INTRODUCTION,
        title: 'Introduction',
        shortName: 'Intro',
        isWritingForm: true,
        icon: 'bookmark',
        isGeneral: false,
        isRemovable: false,
    },
    {
        section_type: SCOPE,
        title: 'Scope of Work',
        shortName: 'Scope of Work',
        isWritingForm: true,
        icon: 'check-square-o',
        isGeneral: false,
        isRemovable: true,
    },
    {
        section_type: SIGNATURE,
        title: 'Signature',
        shortName: 'Signature',
        isWritingForm: true,
        icon: 'pencil-square-o',
        isGeneral: true,
        isRemovable: true,
        isDocBuilderAllowed: true,
        // making it so it can't be added to templates. We have signatures tabs now.
        // I have my doubts this will be permanent so for the time being will just hide it
        // and not remove all of the other functionality that makes Signatures be a type of section
        isHidden: true,
    },
    {
        section_type: QUESTIONNAIRE,
        title: 'Vendor Questionnaire',
        shortName: 'Questionnaire',
        isWritingForm: true,
        icon: 'question-circle',
        isGeneral: false,
        isRemovable: true,
    },
    {
        section_type: PRICING,
        title: 'Pricing Proposal',
        shortName: 'Pricing',
        isWritingForm: true,
        icon: 'table',
        isGeneral: false,
        isRemovable: true,
    },
    {
        section_type: EVALUATION_CRITERIA,
        title: 'Evaluation Criteria',
        shortName: 'Evaluation',
        isWritingForm: true,
        icon: 'star',
        isGeneral: false,
        isRemovable: true,
        isHidden: true, // Evaluation Criteria has been replaced by Evaluation Phases
    },
    {
        section_type: EVALUATION_PHASE,
        title: 'Evaluation Phases',
        shortName: 'Evaluation',
        isWritingForm: true,
        icon: 'star',
        isGeneral: false,
        isRemovable: true,
    },
    {
        section_type: TERMS,
        title: 'List Items',
        shortName: 'List',
        isWritingForm: false,
        icon: 'list-ol',
        isGeneral: true,
        isRemovable: true,
    },
    {
        section_type: TEXT_AREA,
        title: 'Text Area',
        shortName: 'Text Area',
        isWritingForm: false,
        icon: 'file-text',
        isGeneral: true,
        isRemovable: true,
    },
    {
        section_type: DIVIDER,
        title: 'Divider',
        shortName: 'Divider',
        isWritingForm: false,
        icon: 'indent',
        isGeneral: true,
        isRemovable: true,
    },
    {
        section_type: ATTACHMENTS,
        title: 'Attachments',
        shortName: 'Attachments',
        isWritingForm: false,
        isWritingFormEditable: true, // Used to allow `isWritingForm` to be toggled for special sections
        icon: 'paperclip',
        isGeneral: false,
        isRemovable: true,
        isDocBuilderAllowed: true,
    },
];

exports.defaultSectionConfigsMap = buildMap(exports.defaultSectionConfigs, 'section_type');

exports.defaultSectionSubsectionConfigsMap = exports.sectionTypes.reduce((map, sectionType) => {
    const subsections = [defaultSubsectionConfigsMap[subsectionTypeNames.BODY]];

    if (sectionType === INTRODUCTION) {
        subsections.push(
            defaultSubsectionConfigsMap[subsectionTypeNames.SUMMARY],
            defaultSubsectionConfigsMap[subsectionTypeNames.BACKGROUND],
            defaultSubsectionConfigsMap[subsectionTypeNames.CONTACT],
            defaultSubsectionConfigsMap[subsectionTypeNames.TIMELINE]
        );
    }

    map[sectionType] = subsections;
    return map;
}, {});

exports.allowedIntakeSections = [PRICING, EVALUATION_CRITERIA, EVALUATION_PHASE];

// Virtual sections that appear on the writing form, but do not get stored with the project since
// they only are used to collect meta-data
exports.pseudoSectionTypes = [
    'documentSetup',
    'globalData',
    'overview',
    'summaryBackgroundTimeline',
];

exports.pseudoSectionTypeNames = listToDict(exports.pseudoSectionTypes);

// If new models are added also update `polymorphicRelations.js` for `TemplateSectionContent`
exports.templateSectionContentableModels = ['criteria', 'evaluationPhase', 'questionnaire'];

exports.templateSectionContentableTypes = listToDict(exports.templateSectionContentableModels);
