import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ProjectSectionMapper } from '..';

import { SignatureDocx } from '../Signature/SignatureDocx';

export class ProjectDocumentReadOnly extends Component {
    static propTypes = {
        isDocx: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        project: PropTypes.shape({
            government_id: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            isIntake: PropTypes.bool.isRequired,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    isHidden: PropTypes.bool.isRequired,
                    isHiddenByLogic: PropTypes.bool,
                })
            ).isRequired,
            signatures: PropTypes.arrayOf(
                PropTypes.shape({
                    project_section_id: PropTypes.number,
                })
            ),
        }).isRequired,
        sectionId: PropTypes.number,
    };

    render() {
        const { isDocx, isRevisionsDiff, project, sectionId } = this.props;

        const path = project.isIntake ? 'intake' : 'builder';
        const projectPath = `/governments/${project.government_id}/projects/${project.id}/${path}`;

        return (
            <>
                {project.projectSections
                    .filter((projectSection) => {
                        if (sectionId) {
                            return projectSection.id === sectionId;
                        }
                        return !projectSection.isHidden && !projectSection.isHiddenByLogic;
                    })
                    .map((projectSection) => (
                        <ProjectSectionMapper
                            isDocx={isDocx}
                            isReadOnly
                            isRevisionsDiff={isRevisionsDiff}
                            key={projectSection.id}
                            project={project}
                            projectPath={projectPath}
                            projectSectionId={projectSection.id}
                        />
                    ))}
                <SignatureDocx
                    signatures={(project.signatures || []).filter((s) => !s.project_section_id)}
                />
            </>
        );
    }
}
