import { listToDict } from '@og-pro/shared-config/helpers';
import { questionLogicActionNames } from '@og-pro/shared-config/questionLogics';

const { HIDE, SHOW } = questionLogicActionNames;

export const form = 'questionLogicForm';

export const actionSelectOptions = [
    {
        icon: 'ban',
        label: HIDE.toUpperCase(),
        value: HIDE,
    },
    {
        icon: 'check',
        label: SHOW.toUpperCase(),
        value: SHOW,
    },
];

// Pseudo form fields used to include display data with the form
const pseudoFields = ['linkableItem', 'projectSection', 'projectSectionId', 'upfrontQuestion'];
export const pseudoFieldNames = listToDict(pseudoFields);
