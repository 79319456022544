import { v4 as UUIDv4 } from 'uuid';

import {
    questionLogicActionNames,
    questionLogicLinkableModelNames,
} from '@og-pro/shared-config/questionLogics';
import { rejectionPolicyTypes, reviewTypes } from '@og-pro/shared-config/reviewSequences';

import { reviewSequenceStepQuestionLogicFieldNames } from './constants';

const { SHOW } = questionLogicActionNames;
const { REVIEW_SEQUENCE_STEP } = questionLogicLinkableModelNames;
const { ACTION, LINKABLE, LINKABLE_ID } = reviewSequenceStepQuestionLogicFieldNames;

export const generateReviewSequenceStep = (reviewSequenceStepData) => {
    return {
        allowGeneralEdit: false,
        allowOverBudget: false,
        allowPriceEdit: false,
        allowVendorEdit: false,
        minPositionReviews: 1,
        rejectionPolicies: [rejectionPolicyTypes.BACK_TO_BEGINNING],
        reviewSequenceStepPositions: [],
        requireOverBudgetNote: false,
        requireRejectionNote: false,
        requireVendor: false,
        reviewType: reviewTypes.APPROVE,
        uuid: UUIDv4(),
        ...reviewSequenceStepData,
    };
};

export const generateReviewSequenceStepQuestionLogic = (reviewSequenceStepId) => {
    return {
        [ACTION]: SHOW,
        [LINKABLE]: REVIEW_SEQUENCE_STEP,
        [LINKABLE_ID]: reviewSequenceStepId || 0, // When step ID does not exist it will be automatically added by the API on save
    };
};

export const isReviewSequenceStepDeletable = (reviewSequenceStepToDelete, reviewSequenceSteps) => {
    const rejectionPolicies = reviewSequenceSteps.flatMap((reviewSequenceStep) => {
        return reviewSequenceStep.rejectionPolicies
            .filter((rejectionPolicy) => typeof rejectionPolicy === 'string')
            .map((rejectionPolicy) => rejectionPolicy.split('.')[1]);
    });

    const reviewSequenceStepReferencedIdSet = new Set(rejectionPolicies);
    const reviewSequenceStepToDeleteIdentifier = String(
        reviewSequenceStepToDelete.id || reviewSequenceStepToDelete.uuid
    );

    return !reviewSequenceStepReferencedIdSet.has(reviewSequenceStepToDeleteIdentifier);
};
