import React from 'react';
import { Outlet, redirect } from 'react-router-dom';

import * as Gov from '../../containers/GovApp';
import { legacyFetchData, requireTemplateAdminPermissions } from '../loaders';

export default (getState, dispatch) => {
    return [
        {
            element: <Gov.TemplateAdminNav />,
            loader: requireTemplateAdminPermissions(getState),
            path: 'templates-admin',
            children: [
                {
                    element: <Gov.TemplateList />,
                    loader: legacyFetchData(getState, dispatch, Gov.TemplateList),
                    index: true,
                },
                {
                    element: <Gov.WordTemplates />,
                    loader: legacyFetchData(getState, dispatch, Gov.WordTemplates),
                    path: 'export-documents',
                },
                {
                    element: <Gov.TemplateSectionsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.TemplateSectionsList),
                    path: 'shared-sections',
                },
                {
                    element: <Gov.TemplateSectionEdit />,
                    loader: legacyFetchData(getState, dispatch, Gov.TemplateSectionEdit),
                    path: 'shared-sections/:templateSectionId',
                },
                {
                    element: <Gov.TemplateQuestionsList />,
                    loader: legacyFetchData(getState, dispatch, Gov.TemplateQuestionsList),
                    path: 'shared-questions',
                },
            ],
        },
        {
            element: <Gov.TemplateEditV2 />,
            loader: legacyFetchData(getState, dispatch, Gov.TemplateEditV2),
            path: 'templates-admin/:templateId',
            children: [
                {
                    index: true,
                    element: <Outlet />,
                    loader: () => redirect('process-information'),
                },
                {
                    element: <Gov.TemplateEditV2ProcessInformation />,
                    path: 'process-information',
                },
                {
                    element: <Gov.TemplateEditV2DocumentBuilder />,
                    path: 'document-builder',
                },
                { element: <Gov.TemplateEditV2Attachments />, path: 'attachments' },
                {
                    element: <Gov.TemplateEditSignatures />,
                    path: 'signatures',
                },
                {
                    element: <Gov.TemplateEditV2QuestionsAutomation />,
                    path: 'questions-and-automations',
                },
                {
                    element: <Gov.TemplateEditV2Checklist />,
                    path: 'final-review-checklist',
                },
            ],
        },
    ];
};
