import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { collapseCriteriaMap } from '.';
import { signaturesBlocksColumnsToArray } from './signatures';

const { TEXT_AREA } = sectionTypeNames;

export const serializeCriteriaSignaturesAndSectionDescriptions = (data) => {
    const {
        criteria: criteriaData,
        sectionDescriptions: sectionDescriptionsData,
        signatures: signaturesData,
        standaloneSignatures,
    } = data;

    let criteria;
    let sectionDescriptions;
    let signatures;

    if (criteriaData) {
        criteria = collapseCriteriaMap(criteriaData).filter(
            (criterion) =>
                criterion.section_type === TEXT_AREA ||
                !!criterion.title ||
                !!criterion.description ||
                !!criterion.manualNumber
        );
    }

    if (sectionDescriptionsData) {
        sectionDescriptions = collapseCriteriaMap(sectionDescriptionsData).filter(
            (sectionDesc) => !!sectionDesc && !!sectionDesc.description
        );
    }

    if (signaturesData || standaloneSignatures) {
        signatures = signaturesBlocksColumnsToArray(collapseCriteriaMap(signaturesData));
        signatures = (signatures || []).concat(
            signaturesBlocksColumnsToArray(standaloneSignatures || [])
        );
    }

    return {
        criteria,
        sectionDescriptions,
        signatures,
    };
};
