import { listToDict } from '@og-pro/shared-config/helpers';

const formName = 'requisitionAccountDetailsFilters';

const fields = ['accountId', 'fiscalYear'];

export const fieldsDict = listToDict(fields);

const { ACCOUNT_ID, FISCAL_YEAR } = fieldsDict;

export const validate = (values, props) => {
    const errors = {};
    if (!values[ACCOUNT_ID]) {
        errors[ACCOUNT_ID] = 'Account is required';
    }
    if (props.hasFMS) {
        if (typeof values[ACCOUNT_ID] !== 'number') {
            errors[ACCOUNT_ID] = 'Account is not valid';
        }
    }

    if (!values[FISCAL_YEAR]) {
        errors[FISCAL_YEAR] = 'Fiscal year is required';
    }

    return errors;
};

export const formConfig = {
    form: formName,
    validate,
};
