import React from 'react';
import { useSelector } from 'react-redux';

import { TablePagination } from '@og-pro/ui';
import { REQUISITIONS_LIMIT } from '@og-pro/shared-config/requisitions';

import { useQueryParam } from '../../../../../hooks';
import { getRequisitionsSearchCount } from '../selectors';

export const RequisitionsListPagination = () => {
    const [page, setPage] = useQueryParam('page', 1);
    const [limit, , setMultipleQueryParams] = useQueryParam('limit', REQUISITIONS_LIMIT);

    const requisitionsCount = useSelector(getRequisitionsSearchCount);

    const handleChangePage = (event, zeroIndexedNewPage) => {
        setPage(zeroIndexedNewPage + 1); // TablePagination Page index starts at 0
    };

    const handleChangeRowsPerPage = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setMultipleQueryParams({ page: 1, limit: newLimit });
    };

    return (
        <TablePagination
            component="div"
            count={requisitionsCount}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1} // TablePagination Page index starts at 0
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            sx={{
                '& p': {
                    margin: '0',
                },
                '& .MuiSelect-icon': {
                    marginRight: '-4px',
                },
            }}
        />
    );
};
