import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@og-pro/ui';

import {
    requisitionSortingOptionsNames,
    REQUISITIONS_LIMIT,
    requisitionTabsNames,
} from '@og-pro/shared-config/requisitions';

import { RequisitionsDashboardBody } from './RequisitionsDashboardBody';
import { LoadingSpinner, Main, SectionTitle } from '../../../../components';
import { ProjectCreateButton } from '../../../../components/GovApp';
import { RequisitionsDashboardSummary } from './RequisitionsDashboardSummary';
import { loadRequisitionGroups } from '../../../../actions/requisitionGroups';
import { getIsGroupAdminOrLeader } from './selectors';
import { useQueryParam, useRouteDefaultQueryParams } from '../../../../hooks';
import { getQuickFilters } from './helpers';

const { MY_APPROVALS, ALL_REQUESTS } = requisitionTabsNames;

export const RequisitionsDashboard = () => {
    const dispatch = useDispatch();
    const loadedGroups = useSelector((state) => state.requisitionGroups.get('loadedGroups'));
    const styles = require('./index.scss');

    const isLoadingRequisitionGroups = useSelector((state) =>
        state.requisitionGroups.get('loadingGroups')
    );

    const isAdminOrLeader = useSelector((state) => getIsGroupAdminOrLeader(state));

    const defaultTab = isAdminOrLeader ? ALL_REQUESTS : MY_APPROVALS;
    const [queryTab] = useQueryParam('tab', defaultTab);

    const quickFilters = useMemo(() => getQuickFilters(queryTab), [queryTab]);

    useEffect(() => {
        if (!loadedGroups) {
            dispatch(loadRequisitionGroups());
        }
    }, []);

    useRouteDefaultQueryParams([
        { paramName: 'tab', defaultValue: defaultTab },
        { paramName: 'quickFilter', defaultValue: quickFilters[0].value },
        { paramName: 'sort', defaultValue: requisitionSortingOptionsNames.ACTIVE_DATE },
        { paramName: 'sortDirection', defaultValue: 'DESC' },
        { paramName: 'page', defaultValue: 1 },
        { paramName: 'limit', defaultValue: REQUISITIONS_LIMIT },
    ]);

    if (!loadedGroups) {
        return <LoadingSpinner useCDS />;
    }

    return (
        <Main className={styles.page}>
            <div className={styles.header}>
                <SectionTitle title="Requisitions" titlePlacement="left" />
                <div className={styles.headerButtons}>
                    <ProjectCreateButton
                        className={styles.newRequestBtn}
                        color="primary"
                        component={Button}
                        hideIcon
                        isRequisition
                        qaTag="requisitionDashboard-create"
                        text="New Request"
                        variant="contained"
                    />
                </div>
            </div>
            {isLoadingRequisitionGroups ? (
                <LoadingSpinner useCDS />
            ) : (
                <>
                    <RequisitionsDashboardSummary />
                    <RequisitionsDashboardBody />
                </>
            )}
        </Main>
    );
};
