import { compact, flatten, get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildMap } from '@og-pro/shared-config/helpers';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { SectionNotFound } from './SectionNotFound';
import { AttachmentTable } from '..';
import {
    ProjectIntroduction,
    ProjectCriteriaItems,
    ProjectPricingDetail,
    EvaluationPhases,
    QuestionnaireList,
} from '../GovApp';

import { ProjectDetailTitle } from '../GovApp/ProjectDetail';
import { Signature } from '../Signature';

const {
    ATTACHMENTS,
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

export class ProjectSectionMapper extends Component {
    static propTypes = {
        attachmentActions: PropTypes.object,
        criteriaActions: PropTypes.object,
        downloadPublicAttachment: PropTypes.func,
        isAttachmentsEditable: PropTypes.bool,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        project: PropTypes.shape({
            attachments: PropTypes.array,
            auctionMaxFractionDigits: PropTypes.number,
            criteriaMap: PropTypes.object,
            evaluationPhases: PropTypes.array,
            government: PropTypes.shape({
                organization: PropTypes.object,
                salesTax: PropTypes.number,
            }).isRequired,
            id: PropTypes.number.isRequired,
            isPostOnly: PropTypes.bool.isRequired,
            priceTables: PropTypes.array,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    isHidden: PropTypes.bool.isRequired,
                    isHiddenByLogic: PropTypes.bool,
                    projectSubsections: PropTypes.array.isRequired,
                    section_type: PropTypes.string.isRequired,
                    title: PropTypes.string.isRequired,
                })
            ).isRequired,
            questionnaires: PropTypes.array,
            sectionDescriptions: PropTypes.object,
            signatures: PropTypes.array,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
                omitDocx: PropTypes.bool.isRequired,
            }).isRequired,
            useManualNumbering: PropTypes.bool.isRequired,
            useSectionDividers: PropTypes.bool.isRequired,
        }).isRequired,
        projectPath: PropTypes.string.isRequired,
        projectSectionId: PropTypes.number,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        attachmentActions: {},
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    renderSection() {
        const {
            attachmentActions,
            criteriaActions,
            downloadPublicAttachment,
            isAttachmentsEditable,
            isDocx,
            isEditable,
            isReadOnly,
            isRevisionsDiff,
            project,
            projectPath,
            projectSectionId,
            sectionDescActions,
            showComments,
            showConfirmationSimpleModal,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const {
            attachments = [],
            auctionMaxFractionDigits,
            criteriaMap,
            evaluationPhases,
            government: { organization: gov, salesTax },
            id: projectId,
            isPostOnly,
            priceTables,
            projectSections,
            questionnaires,
            sectionDescriptions,
            signatures,
            template,
            useManualNumbering,
            useSectionDividers,
        } = project;

        const displaySections = projectSections.filter((projectSection) => {
            return !projectSection.isHidden && !projectSection.isHiddenByLogic;
        });
        const foundProjectSection = displaySections.find((sect) => sect.id === projectSectionId);
        const selectedProjectSection = foundProjectSection || displaySections[0] || {};

        const {
            id: selectedSectionId,
            projectSubsections = [],
            section_type: sectionType,
            title,
        } = selectedProjectSection;

        const criteria = compact(
            flatten(
                projectSubsections.map((projectSubsection) => {
                    return criteriaMap[`${selectedSectionId}.${projectSubsection.id}`];
                })
            )
        );
        const projectSubsectionsMap = buildMap(projectSubsections, 'subsection_type');

        const standardProps = {
            criteria,
            criteriaActions,
            description: sectionDescriptions[selectedSectionId] || {},
            isEditable,
            isDocx,
            isReadOnly,
            projectId,
            projectSection: selectedProjectSection,
            projectSubsectionsMap,
            reviewRoute: projectPath,
            section: sectionType,
            sectionDescActions,
            showComments,
            showConfirmationSimpleModal,
            title,
            writingFormRoute: `${projectPath}/create`,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
            useSectionDividers,
        };

        switch (sectionType) {
            case INTRODUCTION:
                return (
                    <ProjectIntroduction {...standardProps} government={gov} project={project} />
                );
            case QUESTIONNAIRE:
                return (
                    <QuestionnaireList
                        {...standardProps}
                        isRevisionsDiff={isRevisionsDiff}
                        questionnaires={questionnaires}
                    />
                );
            case PRICING:
                return (
                    <ProjectPricingDetail
                        {...standardProps}
                        auctionMaxFractionDigits={auctionMaxFractionDigits}
                        isReverseAuction={template.isReverseAuction}
                        isRevisionsDiff={isRevisionsDiff}
                        priceTables={priceTables || []}
                        salesTax={salesTax}
                    />
                );
            case DIVIDER:
                return (
                    <div className="col-xs-12 col-md-offset-1 col-md-10 list-item-section">
                        <ProjectDetailTitle
                            projectSubsectionId={projectSubsections[0].id}
                            {...standardProps}
                        />
                    </div>
                );
            case SCOPE:
            case TERMS:
                return <ProjectCriteriaItems {...standardProps} />;
            case TEXT_AREA:
                return <ProjectCriteriaItems {...standardProps} isTextArea />;
            case EVALUATION_PHASE:
            case EVALUATION_CRITERIA:
                return <EvaluationPhases {...standardProps} evaluationPhases={evaluationPhases} />;
            case ATTACHMENTS:
                return (
                    <AttachmentTable
                        {...standardProps}
                        {...attachmentActions}
                        attachments={attachments}
                        downloadPublicAttachment={downloadPublicAttachment}
                        isEditable={isAttachmentsEditable}
                        projectId={projectId}
                        showProjectDocuments={get(template, 'omitDocx') || isPostOnly}
                    />
                );
            case SIGNATURE:
                return <Signature {...standardProps} signatures={signatures} />;
            default:
                return <SectionNotFound />;
        }
    }

    render() {
        return <div className="row project-section">{this.renderSection()}</div>;
    }
}
