import { find } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { mapContactFields } from '@og-pro/shared-config/contacts';

import {
    Button,
    ContactModal,
    SearchSelect,
    SearchSelectUserOption,
} from '../../../../../../../../components';
import { fieldNames } from '../constants';
import { getActiveUsersJS, getActiveUsersSelectOptions } from '../../../../../../../selectors';
import { changeContactForm, showContactModal } from '../../../../../../../../actions/contacts';

const { CONTACT_ID, PROCUREMENT_CONTACT_ID } = fieldNames;

export const Contacts = ({ disabled, form, optional, showValidation }) => {
    const dispatch = useDispatch();

    const users = useSelector(getActiveUsersJS);
    const usersSelectOptions = useSelector(getActiveUsersSelectOptions);

    const handleEditContactDisplayInfoClick = (fieldPrefix) => {
        if (!disabled) {
            dispatch(
                showContactModal({
                    disabled,
                    fieldPrefix,
                    optional,
                    showValidation: false,
                })
            );
        }
    };

    const updateContactInfo = (contactId, fieldPrefix) => {
        const contactValue = find(users, (user) => user.id === contactId);
        if (contactValue) {
            dispatch(changeContactForm(contactValue, form, mapContactFields(fieldPrefix)));
        }
    };

    const contactPrefix = 'contact';
    const procurementPrefix = 'procurement';
    const sharedProps = {
        backspaceRemovesValue: optional,
        component: SearchSelect,
        components: { Option: SearchSelectUserOption },
        disabled,
        isClearable: optional,
        options: usersSelectOptions,
        showValidation,
        styles: { menu: { zIndex: 5 } },
        useOpenGovStyle: true,
    };
    const styles = require('../../../shared.scss');
    const { row } = styles;

    const inlineButton = (prefix) => (
        <Button
            bsStyle="link"
            disabled={disabled}
            onClick={() => handleEditContactDisplayInfoClick(prefix)}
            qaTag="projectInformationForm-editProjectContactDisplayInformation"
            zeroPadding
        >
            <i className="fa fa-pencil-square-o" />
            &nbsp;Edit Display Information
        </Button>
    );

    return (
        <>
            <ContactModal formName={form} />
            <div className={classnames(row, styles.searchSelectMenuIndex)}>
                <Field
                    {...sharedProps}
                    inlineButton={inlineButton(contactPrefix)}
                    label="Project Contact"
                    name={CONTACT_ID}
                    onChange={(contactId) => updateContactInfo(contactId, contactPrefix)}
                    placeholder="Select Contact..."
                />
            </div>
            <div className={classnames(row, styles.searchSelectMenuIndex)}>
                <Field
                    {...sharedProps}
                    inlineButton={inlineButton(procurementPrefix)}
                    label="Procurement Contact"
                    name={PROCUREMENT_CONTACT_ID}
                    onChange={(contactId) => updateContactInfo(contactId, procurementPrefix)}
                    placeholder="Select Contact..."
                />
            </div>
        </>
    );
};

Contacts.propTypes = {
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    optional: PropTypes.bool,
    showValidation: PropTypes.bool,
};
