import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { pseudoSectionTypeNames, sectionTypeNames } from '@og-pro/shared-config/sections';

import {
    Attachments,
    Dividers,
    EvaluationCriteriaBuilder,
    EvaluationPhase,
    GlobalData,
    Intro,
    Overview,
    Pricing,
    QuestionnaireBuilder,
    Scope,
    Signature,
    SubmitConfirmation,
    Terms,
    UpfrontQuestions,
    SummaryBackgroundTimeline,
} from '..';
import { formConfig } from '../constants';
import { mapStateToProps } from '../mapProps';
import extendProjectForm from '../ProjectCreateHOC';
import * as exportActionCreators from '../../../../actions/exportProject';
import * as actionCreators from '../../../../actions/project/create/projectCreate';
import { numberStringToInteger } from '../../../../utils';
import { NoItems } from '../../../../components';

const {
    ATTACHMENTS,
    DIVIDER,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    INTRODUCTION,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    SIGNATURE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const { DOCUMENT_SETUP, GLOBAL_DATA, OVERVIEW, SUMMARY_BACKGROUND_TIMELINE } =
    pseudoSectionTypeNames;

const mapDispatchToProps = {
    ...actionCreators,
    ...exportActionCreators,
};

// @connect
// @reduxForm
// @extendProjectForm
class ConnectedProjectCreateSectionMapper extends Component {
    static propTypes = {
        initiateSneakyUpdate: PropTypes.func.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        project: PropTypes.object,
        overrideSelectedProjectSectionId: PropTypes.number,
        // sdv2 passed prop that does the sneaky update but only if
        // there's changes unsaved
        sneakyUpdate: PropTypes.func,
        templateVariableOptions: PropTypes.array,
        writingSections: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                section_type: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    render() {
        const {
            isOGThemeEnabledForComponents,
            location: {
                query: { section },
            },
            project,
            overrideSelectedProjectSectionId,
            writingSections,
        } = this.props;

        /**
         * The project will always be loaded on normal navigation, but on back
         * button nav the components get rendered before data is re-rendered
         * Possibly related:
         * https://github.com/ReactTraining/react-router/issues/5072
         */
        if (!project) {
            return null;
        }

        // Handle the special confirmation section case
        if ((section || '').toLowerCase() === 'confirmation') {
            return <SubmitConfirmation {...this.props} />;
        }

        const projectSectionId = !Number.isNaN(parseInt(overrideSelectedProjectSectionId, 10))
            ? overrideSelectedProjectSectionId
            : numberStringToInteger(section);

        const selectedProjectSection = writingSections.find((sect) => sect.id === projectSectionId);
        const projectSection = selectedProjectSection || writingSections[0] || {};

        switch (projectSection.section_type) {
            case ATTACHMENTS:
                return <Attachments {...this.props} />;
            case DIVIDER: {
                if (isOGThemeEnabledForComponents) {
                    return <Dividers {...this.props} />;
                }

                return <NoItems header="" marginTop={0} subheader="Dividers Have No Content" />;
            }
            case DOCUMENT_SETUP:
                return <UpfrontQuestions {...this.props} />;
            case SUMMARY_BACKGROUND_TIMELINE:
                return <SummaryBackgroundTimeline {...this.props} />;
            case EVALUATION_CRITERIA:
                return <EvaluationCriteriaBuilder {...this.props} />;
            case EVALUATION_PHASE:
                return <EvaluationPhase {...this.props} />;
            case GLOBAL_DATA:
                return <GlobalData {...this.props} />;
            case INTRODUCTION:
                return <Intro {...this.props} />;
            case OVERVIEW:
                return <Overview {...this.props} />;
            case PRICING:
                return <Pricing {...this.props} />;
            case QUESTIONNAIRE:
                return <QuestionnaireBuilder {...this.props} />;
            case SCOPE:
                return (
                    <Scope
                        {...this.props}
                        initiateSneakyUpdate={
                            this.props.sneakyUpdate || this.props.initiateSneakyUpdate
                        }
                    />
                );
            case SIGNATURE:
                return <Signature {...this.props} />;
            case TERMS:
                return <Terms {...this.props} />;
            case TEXT_AREA:
                return <Terms {...this.props} isTextArea />;
            default:
                return null;
        }
    }
}

export const ProjectCreateSectionMapper = compose(
    connect(mapStateToProps, mapDispatchToProps),
    /**
     * `withRouter` is not directly used here but the router props are required in the `extendProjectForm` HOC.
     * When `withRouter` is used in both places, we were getting odd rendering conflicts with the HOC.
     * Removing `withRouter` from the HOC and adding here seems to resolve the issue.
     */
    withRouter,
    reduxForm(formConfig),
    extendProjectForm()
)(ConnectedProjectCreateSectionMapper);
