import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, change as changeFieldValue } from 'redux-form';
import { get } from 'lodash';
import { Box, Grid } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { tokens } from '@opengov/capital-style';

import {
    fieldNames,
    builderSectionFieldNames,
    PARENT_DIVIDER,
} from '../../../../containers/GovApp/TemplateAdmin/forms/TemplateProjectSectionsForm/constants';
import {
    CDSButton,
    RadioGroup,
    RichTextInput,
    SearchSelect,
    InputText,
    Checkbox,
    HelpIcon,
} from '../../..';
import { Footer, Header, TitleNumberInputs } from './components';
import { normalizeBooleans } from '../../../../helpers';
import { limitTextLength } from '../../../../Forms/normalizers';
import { getTitle } from '../../helpers';
import { usesDocxHeadingFormatters } from '../../../../containers/GovApp/selectors';

const { IS_WRITING_FORM, BUILDER_SECTION, SHORT_NAME, DISABLE_NUMBERING, DISABLE_TITLE } =
    fieldNames;
const { INSTRUCTIONS } = builderSectionFieldNames;

export const SectionHeaderCompleteForm = ({
    canHaveInstructions,
    defaultSection,
    disabled,
    formName,
    invalid = false,
    isAdding = false,
    member,
    onCancel,
    onDelete,
    onDeleteInstructions,
    onSave,
    parentDividerOptions,
    showActionsOnFooter,
    showAutomationsButton,
    showShortnameInput,
    showValidation,
    templateProject,
    useManualNumbering,
    values,
}) => {
    const [instructionsOpened, setInstructionsOpened] = useState(false);
    const isEnabledHideSectionNumbering = useFlags(FLAGS.ENABLE_HIDE_SECTION_NUMBERING);
    const usesDocxFormatters = useSelector(usesDocxHeadingFormatters);
    const styles = require('../shared.scss');
    const dispatch = useDispatch();

    const handleShowSectionTitleChanged = (event, newValue) => {
        if (newValue) {
            // If "Show Section Title" is unchecked, we uncheck "Show Section Numbering" (boolean is inverted)
            dispatch(changeFieldValue(formName, `${member}.${DISABLE_NUMBERING}`, true));
        }
    };

    const deleteInstructions = () => {
        onDeleteInstructions(`${member}.${BUILDER_SECTION}.${INSTRUCTIONS}`);
        setInstructionsOpened(false);
    };

    useEffect(() => {
        if (instructionsOpened) {
            return;
        }

        if (get(values, `${BUILDER_SECTION}.${INSTRUCTIONS}`)) {
            setInstructionsOpened(true);
        }
    }, [values]);

    const isWritingFormDisabled =
        !defaultSection?.isGeneral && !defaultSection?.isWritingFormEditable;
    const editorsText = 'Project Editors must be able to edit special use sections.';
    const isDivider = defaultSection.section_type === sectionTypeNames.DIVIDER;
    const isIntroduction = defaultSection.section_type === sectionTypeNames.INTRODUCTION;
    const canHaveAutomation = !isDivider && !isIntroduction;

    const showDisableInputs =
        usesDocxFormatters &&
        values?.section_type === sectionTypeNames.TEXT_AREA &&
        templateProject.type === projectTypesDict.CONTRACT &&
        isEnabledHideSectionNumbering;

    return (
        <div className={styles.sectionHeader}>
            <Header
                invalid={invalid}
                onCancel={onCancel}
                onDelete={onDelete}
                onSave={onSave}
                showButtons={!showActionsOnFooter}
                title={getTitle({ isAdding, sectionType: defaultSection.section_type })}
            />
            <div>
                <TitleNumberInputs
                    {...{
                        disabled,
                        member,
                        showValidation,
                        useManualNumbering,
                    }}
                    type="Section"
                />
            </div>

            {showDisableInputs && (
                <Box mb={2}>
                    <Box className={styles.pseudoLabel}>
                        Display Settings for Preview and Document Export
                        <HelpIcon
                            placement="right"
                            style={{
                                color: tokens.colors.colorGray700,
                            }}
                            tooltip={
                                'Section Title is needed for navigational purposes in ' +
                                'the Document Builder and Preview mode. If unchecked, ' +
                                'these settings will hide the title and/or numbering in the document export. '
                            }
                        />
                    </Box>
                    <Box className={styles.condensendCheckboxes}>
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            invertValue
                            name={`${member}.${DISABLE_TITLE}`}
                            onChange={handleShowSectionTitleChanged}
                            qaTag="projectSectionForm-disableTitle"
                            text="Show Section Title"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box>
                        <Field
                            component={Checkbox}
                            disabled={disabled}
                            invertValue
                            name={`${member}.${DISABLE_NUMBERING}`}
                            qaTag="projectSectionForm-disableNumbering"
                            text="Show Section Numbering"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
            )}
            <Grid container>
                {showShortnameInput && (
                    <Grid item md={6} sm={12} xs={12}>
                        <Box mr={2}>
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                helpIcon
                                helpIconNode={
                                    <>
                                        Use this to tell the difference between similarly named
                                        sections. This is for internal use only, vendors will{' '}
                                        <b>not</b> see this description.
                                    </>
                                }
                                label="Internal Description (optional)"
                                name={`${member}.${SHORT_NAME}`}
                                normalize={limitTextLength(32)}
                                qaTag="projectSectionForm-shortName"
                                showValidation={showValidation}
                                type="text"
                                useOpenGovStyle
                            />
                        </Box>
                    </Grid>
                )}
                {parentDividerOptions?.length > 0 && useManualNumbering && (
                    <Grid item md={6} sm={12} xs={12}>
                        <Box mr={2}>
                            <Field
                                component={SearchSelect}
                                disabled={disabled}
                                isSearchable
                                label="Part Of"
                                name={`${member}.${PARENT_DIVIDER}`}
                                options={parentDividerOptions}
                                qaTag={`projectSectionForm-${PARENT_DIVIDER}`}
                                styles={{ menu: { zIndex: 1001 } }}
                                useOpenGovStyle
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item md={6} sm={12} xs={12}>
                    {!isWritingFormDisabled && (
                        <div className={styles.marginBottomTriple}>
                            <Field
                                component={RadioGroup}
                                defaultChecked
                                disabled={disabled}
                                groupLabel=""
                                label="Who Can Edit?"
                                name={`${member}.${IS_WRITING_FORM}`}
                                normalize={normalizeBooleans}
                                options={[
                                    {
                                        name: 'Project Editors & Admins',
                                        value: true,
                                        qaTag: 'templateEdit-whoCanEdit-editors',
                                    },
                                    {
                                        name: 'Admins Only',
                                        value: false,
                                        qaTag: 'templateEdit-whoCanEdit-admins',
                                    },
                                ]}
                                qaTag="templateEdit-whoCanEdit"
                                useOpenGovStyle
                            />
                        </div>
                    )}
                    {isWritingFormDisabled && (
                        <div className={styles.readonlyEditorsContainer}>
                            <div>
                                <label>Who Can Edit?</label>
                            </div>
                            <div className={styles.readonlyEditorsText}>
                                {values && values[IS_WRITING_FORM]
                                    ? 'Project Editors & Admins'
                                    : 'Admins Only'}
                            </div>
                            <div className={styles.readonlyEditorsHelp}>{editorsText}</div>
                        </div>
                    )}
                </Grid>
            </Grid>
            {canHaveInstructions && !instructionsOpened && (
                <Box mb={1}>
                    <CDSButton
                        noPadding
                        onClick={() => setInstructionsOpened(true)}
                        qaTag="projectSectionForm-addInstructions"
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-plus fa-fw" /> Add Instructions
                    </CDSButton>
                </Box>
            )}
            {canHaveInstructions && instructionsOpened && (
                <div>
                    <div className={styles.rowWrap}>
                        <div className={styles.col}>
                            <label>Instructions for Project Editor</label>
                        </div>
                        <div className={classnames(styles.col, styles.removeInstructionsContainer)}>
                            <CDSButton
                                noPadding
                                onClick={() => deleteInstructions()}
                                qaTag="projectSectionForm-removeInstructions"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-times fa-fw" /> Remove Instructions
                            </CDSButton>
                        </div>
                        <div className={classnames(styles.col, styles.fullwidthCol)}>
                            <Field
                                component={RichTextInput}
                                disabled={disabled}
                                minRows={3}
                                name={`${member}.${BUILDER_SECTION}.${INSTRUCTIONS}`}
                                overrideFeedback
                                qaTag="projectSectionForm-instructionsText"
                                showValidation={showValidation}
                                toolbarPlacement="bottom"
                            />
                        </div>
                    </div>
                </div>
            )}
            {showActionsOnFooter && (
                <div className={styles.footerActions}>
                    <Footer
                        buttonLabel={isAdding ? 'Add' : 'Save'}
                        invalid={invalid}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onSave={onSave}
                        section={values}
                        showAutomationsButton={showAutomationsButton && canHaveAutomation}
                    />
                </div>
            )}
        </div>
    );
};

SectionHeaderCompleteForm.propTypes = {
    invalid: PropTypes.bool,
    isAdding: PropTypes.bool,
    canHaveInstructions: PropTypes.bool,
    defaultSection: PropTypes.shape({
        isGeneral: PropTypes.bool.isRequired,
        isWritingFormEditable: PropTypes.bool,
        section_type: PropTypes.oneOf(Object.values(sectionTypeNames)),
    }),
    disabled: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    member: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onDeleteInstructions: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    parentDividerOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    showActionsOnFooter: PropTypes.bool,
    showAutomationsButton: PropTypes.bool,
    showShortnameInput: PropTypes.bool,
    showValidation: PropTypes.bool,
    templateProject: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    useManualNumbering: PropTypes.bool,
    values: PropTypes.shape({
        [IS_WRITING_FORM]: PropTypes.bool,
        section_type: PropTypes.oneOf(Object.values(sectionTypeNames)),
    }),
};
