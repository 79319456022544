import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { showSnackbar } from './notification';
import { LOAD_REQUISITION_FAILURE, LOAD_REQUISITION_STEPS_SUCCESS } from './requisitions';
import { emitUpdateAction } from './requisitionSocket';
import { emitRequisitionSocket } from './utils';
import { resourceManager } from '../helpers';

export const BACKGROUND_REFRESH_START = 'requisitionsApprovals/BACKGROUND_REFRESH_START';
export const BACKGROUND_REFRESH_END = 'requisitionsApprovals/BACKGROUND_REFRESH_END';

export const HIDE_REJECTION_NOTE_MODAL = 'requisitionsApprovals/HIDE_REJECTION_NOTE_MODAL';
export const SHOW_REJECTION_NOTE_MODAL = 'requisitionsApprovals/SHOW_REJECTION_NOTE_MODAL';

export const CREATE_LOG_SUCCESS = 'requisitionsApprovals/CREATE_LOG_SUCCESS';

export const LOAD_LOGS = 'requisitionsApprovals/LOAD_LOGS';
export const LOAD_LOGS_SUCCESS = 'requisitionsApprovals/LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAILURE = 'requisitionsApprovals/LOAD_LOGS_FAILURE';

export const CREATE_RELATION_SUCCESS = 'requisitionsApprovals/CREATE_RELATION_SUCCESS';
export const DELETE_RELATION_SUCCESS = 'requisitionsApprovals/DELETE_RELATION_SUCCESS';

export const LOAD_RELATIONS = 'requisitionsApprovals/LOAD_RELATIONS';
export const LOAD_RELATIONS_SUCCESS = 'requisitionsApprovals/LOAD_RELATIONS_SUCCESS';
export const LOAD_RELATIONS_FAILURE = 'requisitionsApprovals/LOAD_RELATIONS_FAILURE';

export const UPDATE_APPROVER = 'requisitionsApprovals/UPDATE_APPROVER';
export const UPDATE_APPROVER_SUCCESS = 'requisitionsApprovals/UPDATE_APPROVER_SUCCESS';
export const UPDATE_APPROVER_FAILURE = 'requisitionsApprovals/UPDATE_APPROVER_FAILURE';

export const SHOW_MISSING_VENDOR_BANNER = 'requisitionsApprovals/SHOW_MISSING_VENDOR_BANNER';
export const HIDE_MISSING_VENDOR_BANNER = 'requisitionsApprovals/HIDE_MISSING_VENDOR_BANNER';

export const SHOW_MISSING_VENDOR_ADDRESS_BANNER =
    'requisitionsApprovals/SHOW_MISSING_VENDOR_ADDRESS_BANNER';
export const HIDE_MISSING_VENDOR_ADDRESS_BANNER =
    'requisitionsApprovals/HIDE_MISSING_VENDOR_ADDRESS_BANNER';

export const loadRequisitionLogs = (requisitionId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${requisitionId}/logs`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_LOGS }),
        onSuccess: ({ dispatch, result }) => dispatch({ type: LOAD_LOGS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_LOGS_FAILURE, error }),
    });
};

export const createCommentRequisitionLog = (requisitionId, comment) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${requisitionId}/logs`,
        requestOptions: { data: { comment } },
        onSuccess: ({ dispatch, result }) => {
            dispatch(showSnackbar('Comment submitted'));
            const action = { type: CREATE_LOG_SUCCESS, result };
            dispatch(emitRequisitionSocket(requisitionId, action, 'Comment received'));
        },
    });
};

export const loadRequisitionRelations = (requisitionId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${requisitionId}/relations`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_RELATIONS }),
        onSuccess: ({ dispatch, result }) => dispatch({ type: LOAD_RELATIONS_SUCCESS, result }),
        onFailure: ({ dispatch, error }) => dispatch({ type: LOAD_RELATIONS_FAILURE, error }),
    });
};

export const createRequisitionRelation = (requisitionId, data) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${requisitionId}/relations`,
        requestOptions: { data },
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: CREATE_RELATION_SUCCESS, result });
            dispatch(showSnackbar('Relation Added!'));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Relation Failed: ${error.message}`, { isError: true }));
            throw error;
        },
    });
};

export const deleteRequisitionRelation = (requisitionId, reqRelationId) => {
    return resourceManager({
        method: 'del',
        url: `/requisitions/requisitions/${requisitionId}/relations/${reqRelationId}`,
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: DELETE_RELATION_SUCCESS, result });
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Relation Deletion Failed: ${error.message}`, { isError: true }));
        },
    });
};

export const reloadRequisitionAndApprovals =
    (requisitionId, message) => (dispatch, getState, client) => {
        dispatch({ type: BACKGROUND_REFRESH_START });
        return Promise.all([
            client.get(`/requisitions/requisitions/${requisitionId}/steps`),
            client.get(`/requisitions/requisitions/${requisitionId}/logs`),
            client.get(`/requisitions/requisitions/${requisitionId}`),
        ])
            .then(([reviewSequenceSteps, requisitionLogs, requisition]) => {
                const reviewSequenceStepsAction = {
                    type: LOAD_REQUISITION_STEPS_SUCCESS,
                    result: reviewSequenceSteps,
                };
                dispatch(emitRequisitionSocket(requisitionId, reviewSequenceStepsAction, message));

                const requisitionLogsAction = { type: LOAD_LOGS_SUCCESS, result: requisitionLogs };
                dispatch(emitRequisitionSocket(requisitionId, requisitionLogsAction));

                emitUpdateAction(dispatch, requisition);

                dispatch({ type: BACKGROUND_REFRESH_END });
            })
            .catch((error) => dispatch({ type: LOAD_REQUISITION_FAILURE, error }));
    };

export const submitApproval = (stepPositionApproverId, data, opts = {}) => {
    const { onSuccess } = opts;
    const approvalType = data.status === approvalStatusTypes.REJECTED ? 'Rejection' : 'Approval';
    return resourceManager({
        method: 'post',
        requestOptions: { data },
        url: `/requisitions/step-position-approvers/${stepPositionApproverId}`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_APPROVER }),
        onSuccess: ({ dispatch, result }) => {
            dispatch({ type: UPDATE_APPROVER_SUCCESS, result });
            dispatch(showSnackbar(`${approvalType} Submitted!`));
            dispatch(
                reloadRequisitionAndApprovals(result.requisition_id, `${approvalType} Received`)
            );
            if (onSuccess) {
                onSuccess(result);
            }
        },
        onFailure: ({ dispatch, error }) => {
            dispatch({ type: UPDATE_APPROVER_FAILURE, error });
            dispatch(showSnackbar(`${approvalType} Failed: ${error.message}`, { isError: true }));
            return error;
        },
    });
};

export const remindApprovers = (requisitionId) => {
    return resourceManager({
        method: 'post',
        url: `/requisitions/requisitions/${requisitionId}/actions/remind`,
        onSuccess: ({ dispatch, result }) => {
            dispatch(showSnackbar(`${result.reminders} Reminders Sent`));
        },
        onFailure: ({ dispatch, error }) => {
            dispatch(showSnackbar(`Reminder Failed: ${error.message}`, { isError: true }));
        },
    });
};

export const loadVendorData = (requisitionId) => {
    return resourceManager({
        method: 'get',
        url: `/requisitions/requisitions/${requisitionId}/vendors`,
    });
};

export const showRejectionNoteModal = (data) => ({ type: SHOW_REJECTION_NOTE_MODAL, data });

export const hideRejectionNoteModal = () => ({ type: HIDE_REJECTION_NOTE_MODAL });

export const showMissingVendorBanner = () => ({ type: SHOW_MISSING_VENDOR_BANNER });
export const hideMissingVendorBanner = () => ({ type: HIDE_MISSING_VENDOR_BANNER });

export const showMissingVendorAddressBanner = () => ({ type: SHOW_MISSING_VENDOR_ADDRESS_BANNER });
export const hideMissingVendorAddressBanner = () => ({ type: HIDE_MISSING_VENDOR_ADDRESS_BANNER });
