import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { Outlet } from 'react-router-dom';
import { Box, TabsNavigation } from '@og-pro/ui';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import {
    getDeserializedTemplateProject,
    getDocxTemplateSelectOptions,
    getTemplateFormValues,
    getUpfrontQuestionsUsedInQuestionLogic,
} from '../../TemplateEdit/selectors';
import { QuestionLogicModal } from '../../components';
import { ConditionalLogicModal } from '../../../ConditionalLogicModal';
import { getShowConditionalLogicModal } from '../../../ConditionalLogicModal/selectors';
import { fieldNames, form, ALL_SECTIONS } from '../../forms/constants';
import { validate } from '../../forms/validate';
import { getProjectSectionTagOptions, getTemplatesAdminPath } from '../../selectors';
import { getTemplateVariableOptions } from '../../../constants';
import connectData from '../../../../ConnectData';
import {
    getProcurementClassificationSelectOptions,
    isInitialClientLoaded,
    getShowConnectionAlert,
} from '../../../../selectors';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import { joinProject, leaveProject } from '../../../../../actions/projectSocket';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../../../../actions/notification';
import { loadTemplateQuestionsList } from '../../../../../actions/templateQuestions';
import {
    copyTemplate,
    createProjectSection,
    createTemplateQuestion,
    createTemplateSection,
    createUpfrontQuestion,
    deleteTemplate,
    loadTemplate,
    loadTemplateSections,
    reloadTemplate,
    resetTemplateEdit,
    showQuestionLogicModal,
    updateTemplate,
    loadTemplatesList,
} from '../../../../../actions/templatesAdmin';
import {
    CDSButton,
    CDSDropdownButton,
    LoadingError,
    LoadingSpinner,
    MenuItem,
    RouteLeaveWarning,
    TemplateProjectHUD,
    TemplateStatusLabel,
} from '../../../../../components';
import { shouldError } from '../../../../../Forms/validation';
import { sortSections, buildTabRoute } from '../../../../../helpers';
import { getTemplateAdminUserList } from '../../../../../selectors/govApp';
import { TemplateEditV2FunctionsContext, TemplateEditV2NavContext } from '../context';
import { TemplateEditV2ErrorBanner } from './components/ErrorBanner';
import { projectSocket } from '../../../../../lib/sockets';

const { DIVIDER } = sectionTypeNames;

const { PROJECT_SECTIONS, USE_SECTION_DIVIDERS } = fieldNames;

function fetchData(getState, dispatch, location, params) {
    const templateId = Number.parseInt(params.templateId, 10);
    let promises = [];
    const template = getState().templatesAdmin.get('template');

    if (!template || template?.toJS()?.id !== templateId || params.reload) {
        promises = [
            dispatch(loadTemplate(templateId)),
            dispatch(loadTemplateSections(templateId)),
            dispatch(loadTemplateQuestionsList()),
        ];
    }

    if (!getState().templatesAdmin.get('loadedList')) {
        promises.push(dispatch(loadTemplatesList()));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const templateProject = getDeserializedTemplateProject(state);
    const formValues = getTemplateFormValues(state);
    const isClientLoaded = isInitialClientLoaded(state);

    return {
        copyingTemplate: state.templatesAdmin.get('copyingTemplate'),
        copyTemplateError: state.templatesAdmin.get('copyTemplateError'),
        createProjectSectionError: state.templatesAdmin.get('createProjectSectionError'),
        createUpfrontQuestionError: state.templatesAdmin.get('createUpfrontQuestionError'),
        creatingProjectSection: state.templatesAdmin.get('creatingProjectSection'),
        creatingUpfrontQuestion: state.templatesAdmin.get('creatingUpfrontQuestion'),
        deleteError: state.templatesAdmin.get('deleteError'),
        deleting: state.templatesAdmin.get('deleting'),
        docxTemplateSelectOptions: getDocxTemplateSelectOptions(state),
        formValues,
        initialValues: isClientLoaded ? templateProject : undefined,
        isClientLoaded,
        loadError: state.templatesAdmin.get('loadError'),
        loading: state.templatesAdmin.get('loading'),
        procurementClassificationSelectOptions: getProcurementClassificationSelectOptions(state),
        shouldShowConditionalLogicModal: getShowConditionalLogicModal(state),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        shouldShowQuestionLogicModal: state.templatesAdmin.get('showQuestionLogicModal'),
        tagOptions: getProjectSectionTagOptions(state),
        templateAdmins: getTemplateAdminUserList(state),
        templateProject,
        templatesAdminPath: getTemplatesAdminPath(state, props),
        templateVariableOptions: getTemplateVariableOptions({
            project: formValues,
        }),
        updateError: state.templatesAdmin.get('updateError'),
        updating: state.templatesAdmin.get('updating'),
        upfrontQuestionsUsedInQuestionLogicSet: getUpfrontQuestionsUsedInQuestionLogic(state),
    };
};

const mapDispatchToProps = {
    copyTemplate,
    createProjectSection,
    createTemplateQuestion,
    createTemplateSection,
    createUpfrontQuestion,
    deleteTemplate,
    hideConnectionAlert,
    joinProject,
    leaveProject,
    loadTemplate,
    reconnectionAlert,
    reloadTemplate,
    resetTemplateEdit,
    showConfirmationSimpleModal,
    showConnectionAlert,
    showQuestionLogicModal,
    updateTemplate,
};

const formConfig = {
    enableReinitialize: true,
    form,
    shouldError,
    validate,
};

// @withRouter
// @connectData
// @connect
// @reduxForm
class ConnectedTemplateEdit extends Component {
    static propTypes = {
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        copyingTemplate: PropTypes.bool.isRequired,
        copyTemplate: PropTypes.func.isRequired,
        createProjectSection: PropTypes.func.isRequired,
        createProjectSectionError: PropTypes.string,
        createTemplateQuestion: PropTypes.func.isRequired,
        createTemplateSection: PropTypes.func.isRequired,
        createUpfrontQuestion: PropTypes.func.isRequired,
        createUpfrontQuestionError: PropTypes.string,
        creatingProjectSection: PropTypes.bool.isRequired,
        creatingUpfrontQuestion: PropTypes.bool.isRequired,
        deleteTemplate: PropTypes.func.isRequired,
        deleting: PropTypes.bool.isRequired,
        dirty: PropTypes.bool.isRequired,
        formValues: PropTypes.object.isRequired,
        getFlag: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideConnectionAlert: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        isClientLoaded: PropTypes.bool.isRequired,
        joinProject: PropTypes.func.isRequired,
        leaveProject: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadTemplate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                section: PropTypes.string,
            }),
            search: PropTypes.string,
        }).isRequired,
        params: PropTypes.shape({
            governmentId: PropTypes.string.isRequired,
            templateId: PropTypes.string.isRequired,
        }).isRequired,
        reconnectionAlert: PropTypes.func.isRequired,
        reloadTemplate: PropTypes.func.isRequired,
        resetTemplateEdit: PropTypes.func.isRequired,
        router: PropTypes.object.isRequired,
        shouldShowConditionalLogicModal: PropTypes.bool.isRequired,
        shouldShowConnectionAlert: PropTypes.bool.isRequired,
        shouldShowQuestionLogicModal: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showConnectionAlert: PropTypes.func.isRequired,
        tagOptions: PropTypes.array.isRequired,
        templateProject: PropTypes.shape({
            attachments: PropTypes.array.isRequired,
            criteria: PropTypes.object.isRequired,
            evaluationPhases: PropTypes.array.isRequired,
            government: PropTypes.shape({
                code: PropTypes.string,
            }),
            id: PropTypes.number.isRequired,
            isDocBuilder: PropTypes.bool.isRequired,
            isIntake: PropTypes.bool.isRequired,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    shortName: PropTypes.string.isRequired,
                })
            ).isRequired,
            questionnaires: PropTypes.array.isRequired,
            sectionDescriptions: PropTypes.object.isRequired,
            template: PropTypes.shape({
                archived: PropTypes.bool,
                docx_attachment_id: PropTypes.number,
                forceSealedBidStatus: PropTypes.bool.isRequired,
                icon: PropTypes.string,
                isPublished: PropTypes.bool.isRequired,
                isReverseAuction: PropTypes.bool.isRequired,
                isSpecial: PropTypes.bool.isRequired,
                omitDocx: PropTypes.bool.isRequired,
                processAcronym: PropTypes.string,
                substitutionRequestFormUrl: PropTypes.string,
                title: PropTypes.string,
            }).isRequired,
            type: PropTypes.string.isRequired,
            upfrontQuestions: PropTypes.arrayOf(PropTypes.object),
        }),
        templatesAdminPath: PropTypes.string.isRequired,
        templateVariableOptions: PropTypes.array,
        updateTemplate: PropTypes.func.isRequired,
        updating: PropTypes.bool.isRequired,
        upfrontQuestionsUsedInQuestionLogicSet: PropTypes.instanceOf(Set).isRequired,
    };

    constructor(props) {
        super(props);
        const { query } = props.location;
        let activeSectionId = 0;

        if (query.section && !Number.isNaN(parseInt(query.section, 10))) {
            activeSectionId = parseInt(query.section, 10);
        }

        this.state = {
            activeSectionId,
            addingSection: { active: false, index: null, isTemplate: null, sectionType: null },
            manageSectionsDrawerOpened: false,
            openSection: null,
            showFormValidation: false,
            showGlobalFormValidation: false,
            attemptedSetLive: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get templateIdFromParams() {
        // Use the param template ID in case template has not been loaded yet
        return Number.parseInt(this.props.params.templateId, 10);
    }

    reconnectHandler = () => {
        this.props.reconnectionAlert();
        this.props.joinProject(this.templateIdFromParams);
        this.props.reloadTemplate(this.templateIdFromParams);
    };

    connectErrorHandler = () => {
        this.props.showConnectionAlert();
    };

    componentDidMount() {
        projectSocket.connect();
        projectSocket.io.on('reconnect', this.reconnectHandler);
        projectSocket.on('connect_error', this.connectErrorHandler);
        this.props.joinProject(this.templateIdFromParams);

        const {
            templatesAdminPath,
            location: { search },
        } = this.props;

        if (search) {
            this.templateListLink = `${templatesAdminPath}${search}`;
        } else {
            this.templateListLink = templatesAdminPath;
        }
    }

    componentDidUpdate(prevProps) {
        const { initialize, isClientLoaded, templateProject } = this.props;

        /**
         * (DGW 2/6/20):
         * IMPORTANT: This form is not properly initializing on the server. As a workaround, we
         * avoid initializing on the server and instead do so here once the client has loaded.
         *
         * When the page is navigated to client side, we can initialize the form using the connected
         * `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered.
         */
        if (!prevProps.isClientLoaded && isClientLoaded) {
            initialize(templateProject); // Initializes redux-form
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            let activeSectionId = 0;

            if (
                this.props.location.query?.section &&
                !Number.isNaN(parseInt(this.props.location.query?.section, 10))
            ) {
                activeSectionId = parseInt(this.props.location.query?.section, 10);
            }

            this.setState((state) => ({ ...state, activeSectionId }));
        }
    }

    componentWillUnmount() {
        if (this.props.shouldShowConnectionAlert) {
            this.props.hideConnectionAlert();
        }

        this.props.resetTemplateEdit();
        projectSocket.io.off('reconnect', this.reconnectHandler);
        projectSocket.off('connect_error', this.connectErrorHandler);
        this.props.leaveProject(this.templateIdFromParams);
        projectSocket.disconnect();
    }

    toggleManageSectionsDrawerOpened = () => {
        this.setState((state) => ({
            ...state,
            manageSectionsDrawerOpened: !state.manageSectionsDrawerOpened,
        }));
    };

    setActiveSectionId = (index, deactivateAddingSection = true) => {
        if (this.state.addingSection.active && deactivateAddingSection) {
            // if we are adding a new section and the user navigates away
            // clicking some of the other menu items
            // we need to quit the adding section mode so that navigation properly works
            return this.unsetAddingSection(index);
        }

        this.setState((state) => ({ ...state, activeSectionId: index }));

        this.props.router.push(`${this.props.location.pathname}?section=${index}`);
    };

    setAddingSection = (sectionType, isTemplate = false) => {
        const {
            formValues: { projectSections = [] },
        } = this.props;
        const addingSection = {
            active: true,
            index: projectSections.length,
            isTemplate,
            sectionType,
        };

        this.setState(
            (state) => ({
                ...state,
                addingSection,
            }),
            () => {
                if (typeof window !== 'undefined') {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }

                // show the temporary fake section as active
                this.setActiveSectionId(projectSections.length, false);
            }
        );
    };

    unsetAddingSection = (index = null) => {
        const {
            formValues: { projectSections = [] },
        } = this.props;

        this.setState(
            (state) => ({
                ...state,
                addingSection: { active: false, index: null, isTemplate: null, sectionType: null },
            }),
            () => this.setActiveSectionId(index !== null ? index : projectSections.length - 1)
        );
    };

    getParentDividerOptions = () => {
        const {
            formValues: { projectSections, useManualNumbering, useSectionDividers },
        } = this.props;

        if (!useManualNumbering || !useSectionDividers) {
            return null;
        }

        return projectSections.reduce((acc, section, index) => {
            if (section.section_type !== sectionTypeNames.DIVIDER) {
                return acc;
            }

            return acc.concat([
                {
                    icon: 'indent',
                    label: section.title,
                    value: index,
                },
            ]);
        }, []);
    };

    sortSections = () => {
        const {
            change,
            formValues: { projectSections, useManualNumbering, useSectionDividers },
        } = this.props;

        const orderedSections = sortSections({
            sections: projectSections,
            useManualNumbering,
            useSectionDividers,
        });

        change(PROJECT_SECTIONS, orderedSections);

        return orderedSections;
    };

    addConditionalLogicHandler = (questionLogic) => {
        this.addQuestionLogicHandler(questionLogic);

        // Take off event loop before saving so form can finish updating
        setTimeout(this.updateTemplate);
    };

    addQuestionLogicHandler = (questionLogic) => {
        const { array } = this.props;

        array.push('questionLogics', questionLogic);
    };

    copyTemplate = () => {
        const {
            templateProject: { id },
            templatesAdminPath,
        } = this.props;

        const copyHandler = () => {
            this.props.copyTemplate(id, (result) => {
                this.props.router.push(`${templatesAdminPath}/${result.id}/process-information`);
            });
        };

        this.props.showConfirmationSimpleModal(copyHandler, {
            btnText: 'Copy Template',
            bsStyle: 'primary',
            icon: 'copy',
            text: 'Create a copy of this template?',
        });
    };

    createProjectSection = (projectSectionType, sectionData) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createProjectSection(id, projectSectionType, sectionData);
    };

    createTemplateSection = (templateSectionId, sectionData) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createTemplateSection(id, templateSectionId, sectionData, true);
    };

    createTemplateQuestion = async (templateQuestionId, opts, nextOrderById = null) => {
        const {
            change,
            formValues: { upfrontQuestions },
            templateProject: { id },
        } = this.props;

        const res = await this.props.createTemplateQuestion(id, templateQuestionId, opts);

        if (nextOrderById) {
            const reNumberedQuestions = upfrontQuestions
                .concat([res])
                .map((question) => {
                    if (question.id === res.id) {
                        return {
                            ...question,
                            orderById: nextOrderById + 1,
                        };
                    }

                    if (question.orderById > nextOrderById) {
                        return {
                            ...question,
                            orderById: question.orderById + 1,
                        };
                    }

                    return question;
                })
                .sort((a, b) => a.orderById - b.orderById)
                .map((question, i) => ({ ...question, orderById: i + 1 }));

            change('upfrontQuestions', reNumberedQuestions);
        }

        return res;
    };

    createUpfrontQuestion = (upfrontQuestionData) => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.createUpfrontQuestion(id, upfrontQuestionData);
    };

    deleteTemplate = () => {
        const {
            templateProject: { id },
            templatesAdminPath,
        } = this.props;

        const deleteHandler = () => {
            this.props.deleteTemplate(id, () => this.props.router.replace(templatesAdminPath));
        };

        this.props.showConfirmationSimpleModal(deleteHandler, {
            btnText: 'Delete Template',
            text: 'Are you sure you want to delete this template?\nThis action cannot be undone.',
        });
    };

    editQuestionLogicHandler = (questionLogic, index) => {
        const { array } = this.props;

        array.splice('questionLogics', index, 1, questionLogic);
    };

    openSectionHandler = (sectionNumber, showFormValidation = false) => {
        const { openSection } = this.state;

        // No section should be selected if the numbers match (open section is being closed)
        const newSectionNumber = sectionNumber === openSection ? null : sectionNumber;
        this.setState({
            openSection: newSectionNumber,
            showFormValidation,
        });
    };

    removeQuestionLogicHandler = (index, onConfirm) => {
        const { array } = this.props;

        const removeHandler = () => {
            array.remove('questionLogics', index);
            if (onConfirm) {
                onConfirm();
            }
        };

        this.props.showConfirmationSimpleModal(removeHandler, {
            text: 'Are you sure you want to remove this item?',
        });
    };

    showGlobalFormValidation = () => {
        this.setState({ showGlobalFormValidation: true });
    };

    toggleLive = () => {
        const {
            formValues,
            handleSubmit,
            templateProject: {
                id,
                template: { isPublished },
            },
        } = this.props;

        const closeOpenForms = (templateOrError) => {
            if (!(templateOrError instanceof Error)) {
                this.openSectionHandler(null);
            }
        };

        if (isPublished) {
            const submitData = {
                ...formValues,
                template: {
                    ...formValues.template,
                    isPublished: false,
                },
            };
            return this.props.updateTemplate(id, submitData).then(closeOpenForms);
        }

        this.setState({ showGlobalFormValidation: true, attemptedSetLive: true });
        return handleSubmit((data) => {
            // https://github.com/OpenGov/pro-app/blob/master/src/app/containers/GovApp/ProjectCreate/ProjectCreateHOC.js#L95
            // same problem as that
            const errors = validate(data, this.props);

            if (errors[ALL_SECTIONS]) {
                return Promise.reject(new SubmissionError(errors));
            }

            const submitData = {
                ...data,
                template: {
                    ...data.template,
                    isPublished: true,
                },
            };
            this.setState({ showGlobalFormValidation: false, attemptedSetLive: false });
            return this.props.updateTemplate(id, submitData).then(closeOpenForms);
        })();
    };

    toggleUseSectionDividers = (checked) => {
        const {
            change,
            formValues: { projectSections = [] },
        } = this.props;

        if (checked) {
            this.createProjectSection(DIVIDER).then((projectSectionOrError) => {
                if (!(projectSectionOrError instanceof Error)) {
                    const updatedSections = [projectSectionOrError, ...projectSections].map(
                        (section, idx) => {
                            return { ...section, orderById: idx + 1 };
                        }
                    );
                    change(PROJECT_SECTIONS, updatedSections);
                }
            });
            change(USE_SECTION_DIVIDERS, checked);
        } else {
            const removeDividers = () => {
                const updatedSections = projectSections
                    .filter((section) => section.section_type !== DIVIDER)
                    .map((section, idx) => {
                        return { ...section, orderById: idx + 1 };
                    });
                change(PROJECT_SECTIONS, updatedSections);
                change(USE_SECTION_DIVIDERS, checked);
            };
            this.props.showConfirmationSimpleModal(removeDividers, {
                btnText: 'Remove Dividers',
                text: 'Turning off section dividers will remove all existing dividers.\nThis action cannot be undone.',
            });
        }
    };

    updateTemplate = () => {
        const {
            formValues,
            handleSubmit,
            templateProject: {
                id,
                template: { isPublished },
            },
        } = this.props;

        // If the template has been published we want to validate the form before submitting
        if (isPublished) {
            this.setState({ showGlobalFormValidation: true });
            return handleSubmit((data) => {
                // https://github.com/OpenGov/pro-app/blob/master/src/app/containers/GovApp/ProjectCreate/ProjectCreateHOC.js#L95
                // same problem as that
                const errors = validate(data, this.props);

                if (errors[ALL_SECTIONS]) {
                    return Promise.reject(new SubmissionError(errors));
                }

                this.setState({ showGlobalFormValidation: false });
                return this.props.updateTemplate(id, data);
            })();
        }

        return this.props.updateTemplate(id, formValues);
    };

    updateTemplateIfDirty = () => {
        const { dirty } = this.props;

        if (dirty) {
            return this.updateTemplate();
        }
    };

    reloadTemplate = () => {
        const {
            templateProject: { id },
        } = this.props;

        return this.props.loadTemplate(id);
    };

    toggleArchived = () => {
        const {
            formValues,
            templateProject: {
                id,
                template: { archived },
            },
        } = this.props;

        const handler = () => {
            const submitData = {
                ...formValues,
                template: {
                    ...formValues.template,
                    archived: !archived,
                },
            };

            return this.props
                .updateTemplate(id, submitData)
                .then(() => this.openSectionHandler(null));
        };

        if (!archived) {
            this.props.showConfirmationSimpleModal(handler, {
                bsStyle: 'primary',
                btnText: 'Archive Template',
                icon: 'archive',
                text: 'Are you sure you want to archive this template?\nYou may restore it later.',
            });
        } else {
            handler();
        }
    };

    render() {
        if (this.props.loading) {
            return <LoadingSpinner />;
        }

        if (this.props.loadError) {
            return <LoadingError error={this.props.loadError} />;
        }

        // If navigating to the page using back/forward page can render before loading function
        // starts, so need this check
        if (!this.props.templateProject || !this.props.isClientLoaded) {
            return null;
        }

        const {
            array,
            change,
            copyingTemplate,
            createProjectSectionError,
            createUpfrontQuestionError,
            creatingProjectSection,
            creatingUpfrontQuestion,
            deleting,
            dirty,
            formValues: { projectSections = [], upfrontQuestions = [] },
            getFlag,
            location: { pathname },
            router,
            shouldShowConditionalLogicModal,
            shouldShowQuestionLogicModal,
            tagOptions,
            templateProject,
            templateProject: {
                government: { code: govCode },
                id: projectId,
                isDocBuilder,
                isIntake,
                template: { archived, isPublished, isSpecial, title },
                type,
            },
            templateVariableOptions,
            updating,
            upfrontQuestionsUsedInQuestionLogicSet,
        } = this.props;

        const { showFormValidation, showGlobalFormValidation } = this.state;

        const disabled =
            updating ||
            deleting ||
            creatingProjectSection ||
            creatingUpfrontQuestion ||
            copyingTemplate ||
            archived;

        const publishIcon = isPublished ? 'ban' : 'play-circle';
        const deleteIcon = deleting ? 'spinner fa-spin' : 'trash';

        const showValidation = showFormValidation || showGlobalFormValidation;

        const buildRouteFunction = buildTabRoute(this.props.location);

        const tabs = [
            {
                label: 'Process Information',
                qaTag: 'templateAdmin-processInformation',
                route: buildRouteFunction('process-information'),
            },
            {
                label: 'Document Builder',
                qaTag: 'templateAdmin-documentBuilder',
                route: buildRouteFunction('document-builder'),
            },
            {
                label: 'Attachments',
                qaTag: 'templateAdmin-attachments',
                route: buildRouteFunction('attachments'),
            },
            ...(type === projectTypesDict.CONTRACT && getFlag(FLAGS.ENABLE_SIGNATURES_TAB)
                ? [
                      {
                          label: 'Signatures',
                          qaTag: 'templateAdmin-signatures',
                          route: buildRouteFunction('signatures'),
                      },
                  ]
                : []),
            {
                label: 'Questions & Automations',
                qaTag: 'templateAdmin-questionsAndAutomations',
                route: buildRouteFunction('questions-and-automations'),
            },
        ];

        const currentTabIndex = pathname.lastIndexOf('/');
        const activeTabFromRoute = pathname.substring(currentTabIndex + 1);
        const activeTab = tabs.find((tab) => tab.route.includes(activeTabFromRoute)) || tabs[0];

        return (
            <>
                <RouteLeaveWarning
                    evaluationFunction={({ nextLocation }) => {
                        if (!dirty) {
                            return false;
                        }

                        return ![
                            'process-information',
                            'document-builder',
                            'attachments',
                            'questions-and-automations',
                        ].some((path) => nextLocation.pathname.includes(path));
                    }}
                    warningMessage="You have unsaved information, are you sure you want to leave this page?"
                />
                <TemplateEditV2FunctionsContext.Provider
                    value={{
                        addConditionalLogicHandler: this.addConditionalLogicHandler,
                        addingSectionData: this.state.addingSection,
                        array,
                        buildRouteFunction,
                        change,
                        createProjectSection: this.createProjectSection,
                        createProjectSectionError,
                        createTemplateSection: this.createTemplateSection,
                        createTemplateQuestion: this.createTemplateQuestion,
                        createUpfrontQuestion: this.createUpfrontQuestion,
                        createUpfrontQuestionError,
                        disabled,
                        isDirty: dirty,
                        getParentDividerOptions: this.getParentDividerOptions,
                        govCode,
                        isDocBuilder,
                        isIntake,
                        isSpecial,
                        manageSectionsDrawerOpened: this.state.manageSectionsDrawerOpened,
                        toggleManageSectionsDrawerOpened: this.toggleManageSectionsDrawerOpened,
                        isSolicitation: !isDocBuilder && !isSpecial,
                        showConfirmationSimpleModal: this.props.showConfirmationSimpleModal,
                        projectId,
                        removeQuestionLogicHandler: this.removeQuestionLogicHandler,
                        reloadTemplate: this.reloadTemplate,
                        router,
                        setAddingSection: this.setAddingSection,
                        showFormValidation: showValidation || isPublished,
                        sortSections: this.sortSections,
                        unsetAddingSection: this.unsetAddingSection,
                        updateTemplate: this.updateTemplate,
                        updateTemplateIfDirty: this.updateTemplateIfDirty,
                        upfrontQuestionsUsedInQuestionLogicSet,
                        tagOptions,
                        templateVariableOptions,
                        templateProject,
                        toggleUseSectionDividers: this.toggleUseSectionDividers,
                        type,
                    }}
                >
                    <TemplateEditV2NavContext.Provider
                        value={{
                            activeSectionId: this.state.activeSectionId,
                            setActiveSectionId: this.setActiveSectionId,
                        }}
                    >
                        <Box className="container-fluid" margin={0}>
                            <Box className={this.styles.container}>
                                <Box className={this.styles.hudContainer}>
                                    <TemplateProjectHUD
                                        actions={[
                                            <CDSDropdownButton
                                                aria-label="More Actions"
                                                disabled={disabled && !archived}
                                                id="template-project-more-button"
                                                pullRight
                                                qaTag="templateEdit-moreActions"
                                                title="More"
                                                variant="secondary-alt"
                                            >
                                                <MenuItem
                                                    disabled={archived}
                                                    onClick={this.showGlobalFormValidation}
                                                    qaTag="templateAdmin-showFormErrors"
                                                >
                                                    <i className="fa fa-fw fa-exclamation-triangle" />{' '}
                                                    Show Form Errors
                                                </MenuItem>
                                                {!isSpecial && (
                                                    <>
                                                        <MenuItem
                                                            disabled={archived}
                                                            onClick={this.copyTemplate}
                                                            qaTag="templateAdmin-copyTemplate"
                                                        >
                                                            <i className="fa fa-fw fa-copy" /> Copy
                                                            Template
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={this.toggleArchived}
                                                            qaTag="templateAdmin-archiveTemplate"
                                                        >
                                                            {archived ? (
                                                                <>
                                                                    <i className="fa fa-fw fa-undo" />{' '}
                                                                    Restore Template
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="fa fa-fw fa-archive" />{' '}
                                                                    Archive Template
                                                                </>
                                                            )}
                                                        </MenuItem>
                                                    </>
                                                )}
                                                {!isSpecial && (
                                                    <MenuItem
                                                        disabled={archived}
                                                        onClick={this.deleteTemplate}
                                                        qaTag="templateAdmin-delete"
                                                    >
                                                        <i
                                                            className={`fa fa-fw fa-${deleteIcon}`}
                                                        />{' '}
                                                        Delete
                                                    </MenuItem>
                                                )}
                                            </CDSDropdownButton>,
                                            <CDSButton
                                                aria-label="Save"
                                                disabled={disabled}
                                                onClick={this.updateTemplate}
                                                qaTag="templateEdit-save"
                                                size="default"
                                                variant="primary"
                                            >
                                                Save
                                            </CDSButton>,
                                        ]}
                                        breadcrumbs={{
                                            links: [
                                                {
                                                    label: 'Templates',
                                                    path:
                                                        this.templateListLink ||
                                                        this.props.templatesAdminPath,
                                                },
                                            ],
                                            activePageLabel: 'Template Editor',
                                        }}
                                        status={{
                                            label: (
                                                <TemplateStatusLabel
                                                    className={this.styles.statusLabel}
                                                    isArchived={archived}
                                                    isArchivedLabelStyle="default"
                                                    isPublished={isPublished}
                                                    isSpecial={isSpecial}
                                                />
                                            ),
                                            action: (
                                                <CDSButton
                                                    aria-label="Toggle Live"
                                                    disabled={disabled || isSpecial}
                                                    onClick={this.toggleLive}
                                                    qaTag="templateEdit-toggleLive"
                                                    size="small"
                                                    variant="tertiary"
                                                >
                                                    <i className={`fa fa-${publishIcon}`} />
                                                    &nbsp;
                                                    {isPublished ? 'Disable' : 'Set Live'}
                                                </CDSButton>
                                            ),
                                        }}
                                        title={title || 'Untitled'}
                                        type={startCase(type)}
                                    />
                                    <TemplateEditV2ErrorBanner
                                        attemptedSetLive={this.state.attemptedSetLive}
                                        buildRouteFunction={buildRouteFunction}
                                        showValidation={showValidation}
                                    />
                                </Box>
                                <TabsNavigation
                                    defaultTab={activeTab}
                                    onClick={this.updateTemplateIfDirty}
                                    redirectFn={browserHistory.push}
                                    tabs={tabs}
                                />
                            </Box>
                        </Box>
                        <Outlet />
                    </TemplateEditV2NavContext.Provider>
                </TemplateEditV2FunctionsContext.Provider>
                {shouldShowQuestionLogicModal && (
                    <QuestionLogicModal
                        onAdd={this.addQuestionLogicHandler}
                        onEdit={this.editQuestionLogicHandler}
                        onRemove={this.removeQuestionLogicHandler}
                        projectSections={projectSections}
                        upfrontQuestions={upfrontQuestions}
                    />
                )}
                {shouldShowConditionalLogicModal && (
                    <ConditionalLogicModal
                        isDirty={dirty}
                        updateTemplate={this.updateTemplate}
                        upfrontQuestions={upfrontQuestions}
                    />
                )}
            </>
        );
    }
}

export const TemplateEditV2 = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    withFlags()
)(ConnectedTemplateEdit);
