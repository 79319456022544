import { listToDict } from '@og-pro/shared-config/helpers';
import { fieldTypes, fieldTypesValues } from '@og-pro/shared-config/signatures';

export const fieldNames = listToDict(['footer', 'introduction', 'signaturesBlocks', 'blankBlocks']);
export const blockFieldNames = listToDict(['styling', 'pageBreakAfter', 'fields']);
export const fieldsEditableFieldNames = listToDict(['label', 'value']);

export const defaultBlock = {
    styling: 'default',
    pageBreakAfter: false,
    fields: [
        fieldTypesValues[fieldTypes.SIGNATURE],
        fieldTypesValues[fieldTypes.NAME],
        fieldTypesValues[fieldTypes.DATE_SIGNED],
        fieldTypesValues[fieldTypes.EMAIL],
        fieldTypesValues[fieldTypes.ROLE],
    ],
};

export const defaultSignatureHeaderBlock = {
    styling: 'header',
    pageBreakAfter: false,
    fields: [fieldTypesValues[fieldTypes.HEADER]],
};

export const stampOptions = [
    {
        label: 'Small',
        value: '1in',
    },
    {
        label: 'Medium',
        value: '3in',
    },
    {
        label: 'Large',
        value: '5in',
    },
];

export const tooltips = {
    [fieldTypes.DATE_SIGNED]:
        'This is required in order to implement e-signatures and will be used on the date of signing.',
    [fieldTypes.EMAIL]:
        'This is required in order to implement e-signatures. It will not display in the document, but will be used to route the final contract packet for signature.',
};
