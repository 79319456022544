import { cloneDeep } from 'lodash';

import { projectDateSerializer } from '../../utils';
import { collapseCriteriaMap, replaceValueWithRawValue } from '../../../helpers';
import { signaturesBlocksColumnsToArray } from '../../../helpers/signatures';

/**
 * @typedef Criterion
 * @property {string} rawDescription The raw version of the criterion's description
 */

/**
 * @typedef Questionnaire
 * @property {string} rawPrompt The raw version of the questionnaire's prompt
 */

/**
 * @typedef BuilderRequestData
 * @property {Criterion[]} [criteria] The project criteria
 * @property {string} rawBackground The raw versions of the builder project's background
 * @property {string} rawSummary The raw version of the builder project's summary
 * @property {Questionnaire[]} questionnaires The project questionnaires
 */

/**
 * Serialize a builder project for saving to the server.
 * @param {BuilderRequestData} data The builder project data to serialize
 * @param {string|null} status The project status. If `null`, will not be updated
 * @param {string} timezone The timezone of the dates
 * @return {object} The serialized builder project
 */
export function saveSerializer(data, status, timezone) {
    const requestData = cloneDeep(data);

    if (status !== null) {
        requestData.status = status;
    }

    // NOTE: this list should correspond with the list in the template_utils#templatizeProject
    // This is needed because the server controller does not process "raw" fields

    requestData.background = requestData.rawBackground;
    delete requestData.rawBackground;
    if (requestData.criteria) {
        requestData.criteria = Object.keys(requestData.criteria).reduce(
            (criteriaMap, criterionKey) => {
                criteriaMap[criterionKey] = requestData.criteria[criterionKey].map((criterion) => {
                    return replaceValueWithRawValue(criterion, 'description', 'rawDescription');
                });
                return criteriaMap;
            },
            {}
        );
    }

    if (requestData.questionnaires) {
        requestData.questionnaires = requestData.questionnaires.map((questionnaire) => {
            return replaceValueWithRawValue(questionnaire, 'prompt', 'rawPrompt');
        });
    }

    if (requestData.evaluationPhases) {
        requestData.evaluationPhases.map((phase) => {
            if (phase.scoringCriteria) {
                return phase.scoringCriteria.map((criterion) => {
                    return replaceValueWithRawValue(criterion, 'description', 'rawDescription');
                });
            }
            return phase;
        });
    }

    if (requestData.evaluation && requestData.evaluation.scoringCriteria) {
        requestData.evaluation.scoringCriteria.map((criterion) => {
            return replaceValueWithRawValue(criterion, 'description', 'rawDescription');
        });
    }

    // NOTE: this field is not currently user editable and so does not appear in the form
    // requestData.sectionDescriptions = requestData.sectionDescriptions.map((sectionDescription) => {
    //     sectionDescription.description = sectionDescription.rawDescription;
    //     delete sectionDescription.rawDescription;
    //     return sectionDescription;
    // });

    requestData.summary = requestData.rawSummary;
    delete requestData.rawSummary;

    // Ensure there are no values in `quantity`/`unitPrice` fields if fields are turned off
    if (requestData.priceTables) {
        requestData.priceTables = requestData.priceTables.map((priceTable) => {
            if (priceTable.hasTotalRow && !priceTable.hasQuantity) {
                priceTable.hasTotalRow = false;
            }
            if (priceTable.specifyQuantity && !priceTable.hasQuantity) {
                priceTable.specifyQuantity = false;
            }
            if (priceTable.priceItems) {
                priceTable.priceItems = priceTable.priceItems.map((priceItem) => {
                    if (!priceTable.hasQuantity) {
                        priceItem.quantity = null;
                    }
                    if (!priceTable.specifyUnitPrice) {
                        priceItem.unitPrice = null;
                    }
                    if (!priceTable.hasComment) {
                        priceItem.comment = null;
                    }
                    if (!priceTable.hasCustom1) {
                        priceItem.custom1 = null;
                    }
                    if (!priceTable.hasCustom2) {
                        priceItem.custom2 = null;
                    }
                    if (!priceTable.hasCustom3) {
                        priceItem.custom3 = null;
                    }
                    if (!priceTable.hasCustom4) {
                        priceItem.custom4 = null;
                    }
                    if (!priceTable.hasCustom5) {
                        priceItem.custom5 = null;
                    }
                    return priceItem;
                });
            }
            return priceTable;
        });
    }

    // Convert project dates to UTC strings
    const requestWithDates = projectDateSerializer(requestData, timezone);

    const criteria = collapseCriteriaMap(requestData.criteria);

    let signatures;

    if (requestData.signatures || requestData.standaloneSignatures) {
        signatures = signaturesBlocksColumnsToArray(collapseCriteriaMap(requestData.signatures));
        signatures = (signatures || []).concat(
            signaturesBlocksColumnsToArray(requestData.standaloneSignatures || [])
        );
    }

    return {
        ...requestWithDates,
        criteria,
        signatures,
    };
}
